import { Button, Card, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import MultiSelectDropdown from '../other/MultiSelectDropdown';
import './styles.css';
import { fetchUsers, selectAllUsers } from '../../store/slices/userSlice';
import { addNewStudy, addOutcomesToStudy, addUsersToStudy, removeOutcomesFromStudy, removeUsersFromStudy, selectStudyById, updateStudy } from '../../store/slices/studySlice';
import { fetchOutcomes, selectAllOutcomes } from '../../store/slices/outcomeSlice';

export default function StudyForm({
  shown,
  close,
  isEditMode = false,
  id
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const study = useSelector((state) => selectStudyById(state, id));
  const users = useSelector(selectAllUsers);
  const prolificUsers = users.filter(user => user.is_prolific === "True");
  const outcomes = useSelector(selectAllOutcomes);
  const usersStatus = useSelector(
    (state) => state.users.status
  );
  const outcomesStatus = useSelector(
    (state) => state.outcomes.outcomes.status
  );
  const [studyName, setStudyName] = useState('');
  const [studyCode, setStudyCode] = useState('');
  const [mounted, setMounted] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(
    study?.users?.map((user) => user.username)
  );
  const [selectedOutcomes, setSelectedOutcomes] = useState(
    study?.outcomes.map((outcome) => outcome.title)
  );
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (usersStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchUsers(token));
    }
  }, [usersStatus, dispatch]);

  useEffect(() => {
    if (outcomesStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchOutcomes({auth_token: token}));
    }
  }, [outcomesStatus, dispatch]);

  useEffect(() => {
    if (isEditMode && study) {
      setStudyName(study.name);
      setStudyCode(study.completion_code);
      setSelectedUsers(
        study.users.map((user) => user.username)
      );
      setSelectedOutcomes(
        study.outcomes.map((outcome) => outcome.title)
      );
    } else {
      setStudyName('');
      setStudyCode('');
      setSelectedUsers([]);
      setSelectedOutcomes([]);
    }
  }, [isEditMode, study, id]);

  const submit = async () => {
    if (mounted) return;
    if (!studyName) {
      setErrorMessage('Please enter a name for the study');
      return;
    }

    if (!(await checkTokenStatus())) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    }

    const token = localStorage.getItem('auth_token');
    const payload = {
      name: studyName,
      completion_code: studyCode,
      auth_token: token
    };

    const addedUserIds = selectedUsers
      .filter(
        (selectedUser) =>
          !study?.users.some(
            (user) => user.username === selectedUser
          )
      )
      .map((selectedUser) => {
        const matchingUser = prolificUsers.find(
          (user) => user.username === selectedUser
        );
        return matchingUser ? matchingUser.id : null;
      })
      .filter((id) => id !== null);

      const addedOutcomeIds = selectedOutcomes
      .filter(
        (selectedOutcome) =>
          !study?.outcomes.some(
            (outcome) => outcome.title === selectedOutcome
          )
      )
      .map((selectedOutcome) => {
        const matchingOutcome = outcomes.find(
          (outcome) => outcome.title === selectedOutcome
        );
        return matchingOutcome ? matchingOutcome.id : null;
      })
      .filter((id) => id !== null);

    const removedUserIds = study?.users
      .filter(
        (user) =>
          !selectedUsers.some(
            (selectedUser) => selectedUser === user.username
          )
      )
      .map((user) => {
        const matchingUser = prolificUsers.find(
          (c) => c.username === user.username
        );
        return matchingUser ? matchingUser.id : null;
      })
      .filter((id) => id !== null);

      const removedOutcomeIds = study?.outcomes
      .filter(
        (outcome) =>
          !selectedOutcomes.some(
            (selectedOutcome) => selectedOutcome === outcome.title
          )
      )
      .map((outcome) => {
        const matchingOutcome = outcomes.find(
          (c) => c.title === outcome.title
        );
        return matchingOutcome ? matchingOutcome.id : null;
      })
      .filter((id) => id !== null);

    try {
      if (isEditMode) {
        payload.id = id;
        setMounted(true);
        await dispatch(updateStudy(payload))
          .unwrap()
          .then((data) => {
            if (data.status === 'success') {
              if (addedUserIds.length > 0) {
                addUsers({
                  id: data.data.id,
                  user_ids: addedUserIds,
                  auth_token: token
                });
              } 

              if (addedOutcomeIds.length > 0) {
                addOutcomes({
                  id: data.data.id,
                  outcome_ids: addedOutcomeIds,
                  auth_token: token
                });
              } 

              if (removedUserIds.length > 0) {
                removeUsers({
                  id: data.data.id,
                  user_ids: removedUserIds,
                  auth_token: token
                });
              } 

              if (removedOutcomeIds.length > 0) {
                removeOutcomes({
                  id: data.data.id,
                  outcome_ids: removedOutcomeIds,
                  auth_token: token
                });
              } 

              setErrorMessage('');
              close();
              setMounted(false);
            } else {
              setErrorMessage(
                data.message || 'An error occurred while updating'
              );
              setMounted(false);
            }
          });
      } else {
        setMounted(true);
        await dispatch(addNewStudy(payload))
          .unwrap()
          .then((data) => {
            if (data.status === 'success') {
              if (addedUserIds.length > 0) {
                addUsers({
                  id: data.data.id,
                  user_ids: addedUserIds,
                  auth_token: token
                });
              } 
              if (addedOutcomeIds.length > 0) {
                addOutcomes({
                  id: data.data.id,
                  outcome_ids: addedOutcomeIds,
                  auth_token: token
                });
              } 
              setErrorMessage('');
              setStudyName('');
              close();
              setMounted(false);
            } else {
              setErrorMessage(
                data.message || 'An error occurred while creating'
              );
              setMounted(false);
            }
          });
      }
    } catch (err) {
      setErrorMessage(`Operation failed: ${err.message}`);
      setMounted(false);
    }
  };

  const addUsers = async (payload) => {
    try {
      await dispatch(addUsersToStudy(payload))
        .unwrap()
        .then((data) => {
          if (data.status === 'success') {
            setErrorMessage('');
            close();
          } else {
            setErrorMessage(data.message || 'An error occurred while creating');
          }
        });
    } catch (err) {
      setErrorMessage(err.message || 'An error occurred while creating');
    }
  };

  const addOutcomes = async (payload) => {
    try {
      await dispatch(addOutcomesToStudy(payload))
        .unwrap()
        .then((data) => {
          if (data.status === 'success') {
            setErrorMessage('');
            close();
          } else {
            setErrorMessage(data.message || 'An error occurred while creating');
          }
        });
    } catch (err) {
      setErrorMessage(err.message || 'An error occurred while creating');
    }
  };

  const removeUsers = async (payload) => {
    try {
      await dispatch(removeUsersFromStudy(payload))
        .unwrap()
        .then((data) => {
          if (data.status === 'success') {
            setErrorMessage('');
            close();
          } else {
            setErrorMessage(data.message || 'An error occurred while creating');
          }
        });
    } catch (err) {
      setErrorMessage(err.message || 'An error occurred while creating');
    }
  };

  const removeOutcomes = async (payload) => {
    try {
      await dispatch(removeOutcomesFromStudy(payload))
        .unwrap()
        .then((data) => {
          if (data.status === 'success') {
            setErrorMessage('');
            close();
          } else {
            setErrorMessage(data.message || 'An error occurred while creating');
          }
        });
    } catch (err) {
      setErrorMessage(err.message || 'An error occurred while creating');
    }
  };

  return shown ? (
    <div className="b-1 modal-backdrop" onClick={close}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div>
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '1rem', my: 1 }}
            className="text-center">
            {isEditMode ? 'Edit Study' : 'Create A New Study'}
          </Typography>
          <Typography color="error">{errorMessage}</Typography>
          <TextField
            variant="outlined"
            multiline
            placeholder="Study Name"
            value={studyName}
            onChange={(event) => setStudyName(event.target.value)}
            minRows="1"
            sx={{ my: 1 }}
            className="w-full"></TextField>
          <TextField
            variant="outlined"
            multiline
            placeholder="Study Completion Code"
            value={studyCode}
            onChange={(event) => setStudyCode(event.target.value)}
            minRows="1"
            sx={{ my: 1 }}
            className="w-full"></TextField>
          <MultiSelectDropdown
            label={'Study User(s)'}
            options={prolificUsers?.map((user) => user.username)}
            selectedValues={selectedUsers}
            onSelectedValuesChange={setSelectedUsers}
          />
          <MultiSelectDropdown
            label={'Study Outcome(s)'}
            options={outcomes?.map((outcome) => outcome.title)}
            selectedValues={selectedOutcomes}
            onSelectedValuesChange={setSelectedOutcomes}
          />
          <div className="flex pt-2 my-1">
            <div className="w-full mx-2">
              <Button variant="contained" className="w-full" onClick={submit}>
                {isEditMode ? 'Update' : 'Create'}
              </Button>
            </div>
            <div className="w-full mx-2">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  ':hover': { backgroundColor: '#757575' }
                }}
                onClick={close}
                className="w-full">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  ) : null;
}
