import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { Backdrop, Box, Card, CardContent, IconButton } from '@mui/material';
import { useCallback } from 'react';

// Wrapper component to add focus functionality to cards
export default function FocusableCard({
  children,
  widgetKey,
  focusedWidget,
  setFocusedWidget,
  sx,
  ...props
}) {
  const isFocused = focusedWidget === widgetKey;
  const toggleWidgetFocus = useCallback((widgetKey) => {
    setFocusedWidget((current) => (current === widgetKey ? null : widgetKey));
  }, []);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          ...sx,
          position: 'relative',
          transition: 'all 0.3s ease',
          '&:hover': {
            '& .focus-icon': {
              opacity: 1
            }
          }
        }}
        {...props}>
        <CardContent sx={{}}>
          <IconButton
            onClick={() => toggleWidgetFocus(widgetKey)}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              zIndex: 10,
              opacity: 0.5,
              transition: 'opacity 0.3s ease'
            }}
            className="focus-icon">
            {isFocused ? <FullscreenExit /> : <Fullscreen />}
          </IconButton>
          {children}
        </CardContent>
      </Card>

      {isFocused && (
        <Backdrop
          sx={{
            zIndex: 999,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          open={isFocused}
          onClick={() => toggleWidgetFocus(null)}>
          <Card
            variant="outlined"
            sx={{
              width: '90%',
              maxHeight: isFocused ? '90vh' : '',
              overflow: 'scroll',
              // overflow: 'auto',
              position: 'relative',
              p: 2
            }}
            onClick={(e) => e.stopPropagation()}>
            <IconButton
              onClick={() => toggleWidgetFocus(null)}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                zIndex: 10
              }}>
              <FullscreenExit />
            </IconButton>
            {children}
          </Card>
        </Backdrop>
      )}
    </>
  );
}
