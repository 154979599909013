import React, { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { updateUser } from '../../store/slices/userSlice';

import {
  Button,
  Card,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  Backdrop
} from '@mui/material';
import { AuthContext, checkTokenStatus } from '../../App';

export default function ChangeEmailModal({ shown, close }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const { userData } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [changeRequestStatus, setChangeRequestStatus] = useState('idle');

  const changeEmail = (event) => setEmail(event.target.value);

  const canChangeEmail =
    [email].every((val) => val !== '') &&
    checkTokenStatus() &&
    changeRequestStatus === 'idle';

  const handleEmailChange = async () => {
    if (canChangeEmail) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setChangeRequestStatus('pending');
        setIsLoggedIn(true);
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: userData.id,
          email: email,
          auth_token: token
        };
        await dispatch(updateUser(payload))
          .unwrap()
          .then((response) => {
            if (response.status === 'success') {
              userData.email = email;
              setEmail('');
              setResponseMessage(`Successfully updated email address`);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to  update email address: ${err.message}`);
      } finally {
        if (isMounted) {
          setChangeRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      localStorage.removeItem('auth_token');
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (email === '') {
      setErrorMessage('Please set the email before submitting');
    } else {
      setErrorMessage('Email could not be updated.');
    }
  };

  useEffect(() => {
    if (shown) {
      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          close();
        }
      };
      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [shown, close]);

  const closeModal = () => {
    close();
  };

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Backdrop
        sx={{
            zIndex: 999,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}
        open={true}
        onClick={() => closeModal()}>
        <Card
          className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3 z-10"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div>
            <Typography
              sx={{ fontSize: '1.1rem', fontWeight: 600 }}
              className="text-center">
              Change user email address
            </Typography>
            {!responseMessage && (
              <div>
                {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
                <div className="my-2">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="email-input">Email</InputLabel>
                    <OutlinedInput
                      id="email-input"
                      label="Email"
                      autoComplete="new-email"
                      fullWidth
                      sx={{
                        '& fieldset': {
                          borderRadius: '9999px'
                        }
                      }}
                      type="text"
                      value={email}
                      onChange={(event) => changeEmail(event)}
                    />
                  </FormControl>
                </div>

                <div className="flex mt-4">
                  <div className="w-full m-2">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'gray',
                        ':hover': { backgroundColor: '#757575' }
                      }}
                      onClick={closeModal}
                      className="w-full">
                      Cancel
                    </Button>
                  </div>

                  <div className="w-full m-2">
                    <Button
                      variant="contained"
                      onClick={handleEmailChange}
                      className="w-full">
                      Confirm
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {responseMessage && (
              <div>
                {responseMessage && (
                  <Typography
                    sx={{ my: 1.2 }}
                    className="text-center mt-2 py-2"
                    color="success.main">
                    {responseMessage}
                  </Typography>
                )}

                <div className="flex mt-4 justify-center">
                  <Button
                    variant="contained"
                    onClick={closeModal}
                    className="w-6/12">
                    Close
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Backdrop>
    </div>
  ) : null;
}
