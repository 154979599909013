import { OpenInNew } from '@mui/icons-material';
import {
  Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Backdrop
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAIForecast } from '../../store/slices/autoSlice';
import {
  selectFilteredQuestionsByOutcomeGlobal,
  selectOutcomeById
} from '../../store/slices/outcomeSlice';

export default function AIForecastMultipleQuestions({
  shown,
  close,
  cancel,
  outcomeId
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        close();
      }
    };

    if (shown) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [shown, close]);

  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('resolution_impact');
  const [questionList, setQuestionList] = useState([]);

  const minutesSinceOutcomeAIForecastButtonPressed = () => {
    if (outcome?.ai_forecast_button_pressed) {
      return moment().diff(
        moment(outcome?.ai_forecast_button_pressed, 'YYYY-MM-DDThh:mm:ss'),
        'minutes'
      );
    } else {
      return 999999999;
    }
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const closeModal = () => {
    close();
  };

  const cancelModal = () => {
    cancel();
  };

  const outcome = useSelector((state) => selectOutcomeById(state, outcomeId));

  const [isAIForecastButtonDisabled, setIsAIForecastButtonDisabled] =
    useState(false);
  useEffect(() => {
    checkButtonStatus();
  }, [outcome]);

  const checkButtonStatus = async () => {
    try {
      if (minutesSinceOutcomeAIForecastButtonPressed() >= 10) {
        setIsAIForecastButtonDisabled(false);
      } else {
        setIsAIForecastButtonDisabled(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filteredQuestions = useSelector((state) =>
    selectFilteredQuestionsByOutcomeGlobal(state, outcomeId)
  );

  const sortedQuestions = filteredQuestions.sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    } else {
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
  });

  const handleCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setQuestionList((prev) => [...prev, id]);
    } else {
      setQuestionList((prev) => prev.filter((item) => item !== id));
    }
  };

  const isTextSelected = (id) => {
    return questionList.includes(id);
  };

  const createAIForecast = async (e) => {
    e.stopPropagation();
    if (errorMessage) setErrorMessage('');
    try {
      if (questionList.length === 0) {
        setErrorMessage('Select at least one question');
      } else {
        const token = localStorage.getItem('auth_token');
        let payload = {
          outcome_id: outcome.id,
          questionsList: questionList,
          auth_token: token
        };
        setResponseMessage(
          'AI forecast request sent. If successful, the forecast will appear as a comment to the question. You do not need to wait on this page.'
        );
        await dispatch(addAIForecast(payload))
          .unwrap()
          .then((response) => {
            if (response.status === 'success') {
              setResponseMessage('AI Forecast successfully received.');
            } else {
              setErrorMessage(
                `Please wait ${response.data.waitTime} minutes before trying again.`
              );
            }
          });
      }
    } catch (error) {
      setErrorMessage(`Failed to add AI forecasts: ${error.message}`);
    }
  };

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Backdrop
        sx={{
          zIndex: 999,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={true}
        onClick={() => closeModal()}>
        <Card
          className="modal-content w-full sm:w-6/7 md:w-5/6 lg:w-3/5 xl:w-3/5 z-10"
          sx={{ overflowY: 'auto !important', maxHeight: '90vh' }}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div>
            <Typography
              sx={{ mb: 0.6, fontWeight: 'bold', fontSize: '1.1rem' }}
              className="text-center">
              Select Questions for AI Forecasting
            </Typography>
            {!responseMessage && (
              <div>
                <Table overflow="auto">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sortDirection={
                          orderBy === 'resolution_criteria' ? order : false
                        }>
                        <TableSortLabel
                          active={orderBy === 'resolution_criteria'}
                          direction={
                            orderBy === 'resolution_criteria' ? order : 'asc'
                          }
                          onClick={() =>
                            handleSortRequest('resolution_criteria')
                          }>
                          <span className="inline-block cursor-pointer hover:text-blue-500 hover font-bold">
                            Question
                          </span>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sortDirection={
                          orderBy === 'resolution_date' ? order : false
                        }>
                        <TableSortLabel
                          active={orderBy === 'resolution_date'}
                          direction={
                            orderBy === 'resolution_date' ? order : 'asc'
                          }
                          onClick={() => handleSortRequest('resolution_date')}>
                          <span className="inline-block cursor-pointer hover:text-blue-500 hover font-bold">
                            Add AI Forecast
                          </span>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <span className="inline-block cursor-pointer hover:text-blue-500 hover font-bold"></span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedQuestions.map((question) => (
                      <TableRow key={question.id}>
                        <TableCell className="max-width-20vw overflow-hidden">
                          <a
                            href={`/questions/${question.id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="hover:text-blue-500">
                            {question.question_text}
                            <OpenInNew
                              className="inline-block ml-1"
                              sx={{ fontSize: 16 }}
                            />
                          </a>
                        </TableCell>
                        <TableCell className="max-width-20vw overflow-hidden">
                          <Checkbox
                            checked={isTextSelected(question.id)}
                            disabled={
                              question.status !== 'Accepted' &&
                              question.status !== 'Submitted'
                            }
                            onChange={(e) => handleCheckboxChange(e, question.id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )}

                <div className="flex mt-4">
                  <div className="w-full m-2">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'gray',
                        ':hover': { backgroundColor: '#757575' }
                      }}
                      onClick={cancelModal}
                      className="w-full">
                      Cancel
                    </Button>
                  </div>

                  <div className="w-full m-2">
                    <Button
                      variant="contained"
                      onClick={createAIForecast}
                      disabled={isAIForecastButtonDisabled}
                      className="w-full">
                      Create AI Forecasts
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {responseMessage && (
              <div>
                {responseMessage && (
                  <Typography color="success.main" className="text-center">
                    AI forecast request sent. If successful, the forecast will
                    appear as a comment to the question. You do not need to wait
                    on this page.
                  </Typography>
                )}

                <div className="flex mt-4 justify-center">
                  <Button
                    onClick={closeModal}
                    variant="contained"
                    className="w-6/12">
                    Close
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Backdrop>
    </div>
  ) : null;
}
