import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Backdrop,
  Button,
  Card,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteStudy,
  fetchStudies,
  selectAllStudies
} from '../../store/slices/studySlice';
import StudyForm from './StudyForm';

export default function StudyModal({ shown, close }) {
  const dispatch = useDispatch();
  const studies = useSelector((state) => selectAllStudies(state));
  const studyStatus = useSelector((state) => state.studies.status);
  const [filteredStudies, setFilteredStudies] = useState(studies);
  const [search, setSearch] = useState('');
  const [showStudyForm, setShowStudyForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [studyId, setStudyId] = useState();
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  const displayedStudies = filteredStudies.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  useEffect(() => {
    if (studyStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchStudies(token));
    }
  }, [studyStatus, dispatch]);

  useEffect(() => {
    const lowercasedFilter = search.toLowerCase();
    const filteredData = studies.filter((item) =>
      item.name.toLowerCase().includes(lowercasedFilter)
    );
    setFilteredStudies(filteredData);
  }, [search, studies]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
    event.stopPropagation();
  };

  const handleDelete = (studyId) => {
    const token = localStorage.getItem('auth_token');
    dispatch(
      deleteStudy({
        id: studyId,
        auth_token: token
      })
    );
  };

  const handleEdit = (id) => {
    setEditMode(true);
    setStudyId(id);
    setShowStudyForm(true);
  };

  const handleCreate = () => {
    setEditMode(false);
    setStudyId('');
    setShowStudyForm(true);
  };

  function Search() {
    return (
      <div>
        <div className="flex justify-between">
          <TextField
            label="Search Studies"
            value={search}
            onChange={handleSearchChange}
            style={{ marginTop: '-5px', width: '80%' }}
            size="small"
          />
          {displayedStudies.length > 0 && (
            <Button onClick={handleCreate} style={{ fontSize: '12px' }}>
              Create
            </Button>
          )}
        </div>
        {displayedStudies.length > 0 ? (
          <List dense>
            {displayedStudies.map((study) => (
              <Card
                key={study.id}
                sx={{
                  padding: '10px',
                  margin: '10px',
                  backgroundColor: 'background.default'
                }}>
                <ListItem
                  key={study.id}
                  secondaryAction={
                    <>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => handleEdit(study.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDelete(study.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  }>
                  <ListItemText primary={study.name} />
                </ListItem>
              </Card>
            ))}
          </List>
        ) : (
          <Typography>
            No studies found.{' '}
            <Button onClick={handleCreate}>Create a new study</Button>
          </Typography>
        )}
      </div>
    );
  }

  function PageControls() {
    return (
      <div className="flex justify-between">
        <Button
          onClick={() => setPage(page - 1)}
          disabled={page === 1 || filteredStudies.length === 0}>
          Previous
        </Button>
        <Typography>
          {page} of {Math.ceil(filteredStudies.length / itemsPerPage)}
        </Typography>
        <Button
          onClick={() => setPage(page + 1)}
          disabled={
            page === Math.ceil(filteredStudies.length / itemsPerPage) ||
            filteredStudies.length === 0
          }>
          Next
        </Button>
      </div>
    );
  }

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Backdrop
        sx={{
          zIndex: 999,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={true}
        onClick={() => close()}>
        {showStudyForm && (
          <StudyForm
            shown={showStudyForm}
            close={() => setShowStudyForm(false)}
            isEditMode={editMode}
            id={studyId}
          />
        )}
        <Card
          className="modal-content b-2 w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
          onClick={(e) => e.stopPropagation()}
          sx={{ boxShadow: 3 }}>
          <div className="modal-header flex justify-between mb-4">
            <Typography variant="h6" className="modal-title">
              Manage Studies
            </Typography>
            <Button
              onClick={close}
              className="modal-close"
              variant="contained"
              color="error">
              Close
            </Button>
          </div>
          {Search()}
          {PageControls()}
        </Card>
      </Backdrop>
    </div>
  ) : null;
}
