import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../App';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectStrategyById } from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';

import {
  Button,
  Card,
  Collapse,
  Divider,
  Typography,
  useTheme
} from '@mui/material';
import { BsChevronDoubleDown, BsChevronDoubleUp } from 'react-icons/bs';
import MarkdownComponent from '../other/Markdown';

export default function SmallStrategyCard({ strategyId }) {
  const { userData } = useContext(AuthContext);
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(true);
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );

  const strategy = useSelector((state) =>
    selectStrategyById(state, strategyId)
  );

  const getStrategyStatusColour = (status) => {
    switch (status) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };

  if (usernameSettings === undefined) {
    return null;
  } else {
    return (
      <Card id={'strategyCard' + strategyId} className={`w-full`}>
        <div className="px-3 pt-2">
          <div className="break-words">
            <div className="markdown">
              <MarkdownComponent description={strategy.strategy} />
            </div>
          </div>
          <Divider sx={{ my: 0.6 }} />
          <Collapse in={!collapsed}>
            <div className="">
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  marginTop: '0.5vw'
                }}
                color="text.secondary">
                Execution and Reporting Date
              </Typography>
              <Typography>
                This strategy is expected to be executed{' '}
                {strategy.strategy_date_preposition + ' '}
                {strategy.strategy_date.start !== undefined
                  ? strategy.strategy_date.start +
                    ' - ' +
                    strategy.strategy_date.end
                  : strategy.strategy_date.end}
                . As reported by {strategy.resolution_source + ' '}
                {strategy.link_authority &&
                  '(' + strategy.link_authority + ') '}
                {strategy.source_date_preposition + ' '}
                {strategy.resolution_date.start !== undefined
                  ? strategy.resolution_date.start +
                    ' - ' +
                    strategy.resolution_date.end
                  : strategy.resolution_date.end}
                .
              </Typography>
              {strategy.extra_info && (
                <>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      marginTop: '0.5vw'
                    }}
                    color="text.secondary">
                    Extra Info
                  </Typography>
                  <Typography>{strategy.extra_info}</Typography>
                </>
              )}
            </div>
          </Collapse>
          <div className="flex justify-end">
            <Button
              sx={{ px: 1, minWidth: 0 }}
              onClick={(event) => {
                event.stopPropagation();
                setCollapsed(!collapsed);
              }}>
              {collapsed ? (
                <BsChevronDoubleDown size={15} />
              ) : (
                <BsChevronDoubleUp size={15} />
              )}
            </Button>
          </div>
        </div>
      </Card>
    );
  }
}
