import AccountBoxIcon from '@mui/icons-material/AccountBox';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Button, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import NavigationGrid from '../../components/other/NavigationGrid';

import { Checklist } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { AuthContext } from '../../App';
import SuccessModal from '../../components/modals/SuccessModal';
import { selectNotificationByName } from '../../store/slices/notificationSlice';

export default function ProlificNavigationView() {
  const { userData } = useContext(AuthContext);
  const isDarkMode = useTheme().palette.mode === 'dark';
  const completeNotification = useSelector((state) =>
    selectNotificationByName(state, 'Study Complete')
  );
  const [showCompletePopup, setshowCompletePopup] = useState(false);

  useEffect(() => {
    setshowCompletePopup(
      !!completeNotification && completeNotification?.seen === 'False'
    );
  }, [completeNotification]);

  const navigationItems = [
    {
      icon: {
        component: (
          <QuestionAnswerRoundedIcon
            style={{ color: 'white' }}
            fontSize="large"
            sx={{ scale: '1.25' }}
          />
        ),
        color: isDarkMode
          ? 'bg-gradient-to-br from-cyan-600 to-blue-800'
          : 'bg-gradient-to-br from-blue-400 to-cyan-600'
      },
      title: 'Create Questions',
      description: 'Add new questions to existing outcomes.',
      link: '/outcomes?filter=study',
      md: 6
    },
    {
      icon: {
        component: (
          <ShowChartIcon
            style={{ color: 'white' }}
            fontSize="large"
            sx={{ scale: '1.25' }}
          />
        ),
        color: isDarkMode
          ? 'bg-gradient-to-br from-lime-600 to-green-800'
          : 'bg-gradient-to-br from-lime-400 to-green-600'
      },
      title: 'Forecast Questions',
      description: 'Add a forecast to questions.',
      link: '/outcomes?filter=study',
      md: 6
    },
    {
      icon: {
        component: (
          <AccountBoxIcon
            style={{ color: 'white' }}
            fontSize="large"
            sx={{ scale: '1.25' }}
          />
        ),
        color: isDarkMode
          ? 'bg-gradient-to-br from-fuchsia-600 to-purple-800'
          : 'bg-gradient-to-br from-fuchsia-400 to-purple-600'
      },
      title: 'User Profile',
      description: 'Explore user performance.',
      link: `/profile/${userData.username}`,
      md: 6
    },
    {
      icon: {
        component: (
          <Checklist
            style={{ color: 'white' }}
            fontSize="large"
            sx={{ scale: '1.25' }}
          />
        ),
        color: isDarkMode
          ? 'bg-gradient-to-br from-orange-600 to-red-800'
          : 'bg-gradient-to-br from-orange-400 to-red-600'
      },
      title: 'Study Progress',
      description: 'Explore current progress of study.',
      link: '/study',
      md: 6
    }
  ];

  return (
    <div className="ProlificNavigationView">
      {showCompletePopup && (
        <div>
          <SuccessModal
            shown={showCompletePopup}
            close={() => {
              setshowCompletePopup(false);
            }}
            successMessage={
              <>
                {completeNotification?.message}{' '}
                {completeNotification?.link && (
                  <Button
                    sx={{ m: 1 }}
                    variant="outlined"
                    onClick={() =>
                      (window.location.href = completeNotification?.link)
                    }>
                    Return to Prolific
                  </Button>
                )}
              </>
            }
          />
        </div>
      )}
      <div className="flex justify-between ml-5 mt-7 items-center">
        <HierarchicalBreadcrumbs quickActions={true} />
      </div>
      <div className="flex flex-col relative shrink-0 box-border items-center pt-5 pb-12 px-5">
        <div className="box-border mt-3 mx-auto">
          <Typography variant="h4" sx={{ mb: 2 }}>
            <strong>Quick Actions</strong>
          </Typography>
        </div>
        <NavigationGrid navigationItems={navigationItems} />
      </div>
    </div>
  );
}
