import { Tooltip, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

const RelevanceLabel = ({ relevanceLevel }) => {
  const theme = useTheme();

  const getRelevanceText = () => {
    if (relevanceLevel < -0.98) {
      return 'Entirely Negative';
    }
    if (relevanceLevel < -0.333) {
      return 'Strong Negative';
    }
    if (relevanceLevel < -0.16) {
      return 'Moderate Negative';
    }
    if (relevanceLevel < -0.08) {
      return 'Weak Negative';
    }
    if (relevanceLevel < 0.081) {
      return 'No Association';
    }
    if (relevanceLevel < 0.161) {
      return 'Weak Positive';
    }
    if (relevanceLevel < 0.334) {
      return 'Moderate Positive';
    }
    if (relevanceLevel < 0.99) {
      return 'Strong Positive';
    }
    return 'Entirely Positive';
  };

  const getRelevanceExplanation = () => {
    if (relevanceLevel < -0.98) {
      return 'If this happens, then the outcome cannot.';
    }
    if (relevanceLevel < -0.333) {
      return 'If this happens, then the outcome is unlikely.';
    }
    if (relevanceLevel < -0.16) {
      return 'If this happens, then the outcome is quite unlikely.';
    }
    if (relevanceLevel < -0.08) {
      return 'If this happens, then the outcome is slightly less likely.';
    }
    if (relevanceLevel < 0.081) {
      return 'Whether this happens or not does not affect the outcome.';
    }
    if (relevanceLevel < 0.161) {
      return 'If this happens, then the outcome is slightly more likely.';
    }
    if (relevanceLevel < 0.334) {
      return 'If this happens, then the outcome is quite likely.';
    }
    if (relevanceLevel < 0.99) {
      return 'If this happens, then the outcome is very likely.';
    }
    return 'If this happens, then the outcome must.';
  };

  return (
    <Tooltip
      title={
        <div>
          <div>{`Relevance: ${getRelevanceText()} (${relevanceLevel})`}</div>
          <div>{`${getRelevanceExplanation()}`}</div>
        </div>
      }
      arrow
      slotProps={{
        tooltip: {
          sx: {
            color: 'text.primary',
            border: `1px solid ${(theme.vars || theme).palette.divider}`,
            borderRadius: 1,
            background: 'hsl(0, 0%, 100%)',
            ...theme.applyStyles('dark', {
              background: grey[900]
            })
          }
        }
      }}>
      <div className="flex items-center gap-1 text-xs cursor-pointer">
        <Typography
          className={`${
            relevanceLevel > 0.08
              ? 'text-green-400'
              : relevanceLevel < -0.08
              ? 'text-red-500'
              : 'text-amber-500'
          }`}>
          {getRelevanceText()}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default RelevanceLabel;
