import React, { useState, useEffect } from "react";
import { Button, Card, TextField, Typography } from "@mui/material";
import BucketList from "../lists/BucketList";

export default function BucketConfigurationForm({ bucketConfiguration, setBucketConfiguration, onSubmit, errorMessage, setErrorMessage, setShowBucketConfigurationForm }) {
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                setShowBucketConfigurationForm(false);
            }
        };

        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [setShowBucketConfigurationForm]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(newBucketConfiguration);
    };

    const handleCancel = () => {
        setBucketConfiguration({
            name: "",
            buckets: []
        });
        setErrorMessage("");
        setShowBucketConfigurationForm(false);
    };

    const checkForBucketOverlap = (buckets) => {
        for (let i = 0; i < buckets.length; i++) {
            for (let j = i + 1; j < buckets.length; j++) {
                if (buckets[i].upper_limit > buckets[j].lower_limit && buckets[i].lower_limit < buckets[j].upper_limit) {
                    return true;
                }
            }
        }
        return false;
    }

    const checkBucket = (newBucket) => {
        if (newBucket.upper_limit === 0 && newBucket.lower_limit === 0) {
            setErrorMessage("Bucket range must be greater than 0");
            return false;
        }
        if (newBucket.name === "") {
            setErrorMessage("Bucket name cannot be empty");
            return false;
        }
        if (newBucket.upper_limit <= newBucket.lower_limit) {
            setErrorMessage("Upper limit must be greater than lower limit");
            return false;
        }
        if (newBucket.upper_limit > 1 || newBucket.lower_limit < 0) {
            setErrorMessage("Bucket range must be between 0 and 100");
            return false;
        }
        return true;
    }

    const handleAddNewBucket = (newBucket) => {
        if (newBucket.upper_limit === "") {
            newBucket.upper_limit = 0;
        }
        if (newBucket.lower_limit === "") {
            newBucket.lower_limit = 0;
        }
        if (!checkBucket(newBucket)) {
            return;
        }
        if (newBucketConfiguration.buckets === undefined) {
            newBucketConfiguration.buckets = [];
        }
        if (checkForBucketOverlap([...newBucketConfiguration.buckets, newBucket])) {
            setErrorMessage("Bucket ranges cannot overlap");
            return;
        }
        const newBuckets = [...newBucketConfiguration.buckets, newBucket];
        setNewBucketConfiguration({ ...newBucketConfiguration, buckets: newBuckets });
    }

    const handleUpdateBucket = (updatedBucket) => {
        if (updatedBucket.upper_limit === "") {
            updatedBucket.upper_limit = 0;
        }
        if (updatedBucket.lower_limit === "") {
            updatedBucket.lower_limit = 0;
        }
        if (!checkBucket(updatedBucket)) {
            return;
        }
        const updatedBuckets = newBucketConfiguration.buckets.map(bucket => {
            if (bucket.id === updatedBucket.id) {
                return updatedBucket;
            }
            return bucket;
        });
        setNewBucketConfiguration({ ...newBucketConfiguration, buckets: updatedBuckets });
    }

    const handleDeleteBucket = (deletedBucket) => {
        const updatedBuckets = newBucketConfiguration.buckets.filter(bucket => bucket.id !== deletedBucket.id);
        setNewBucketConfiguration({ ...newBucketConfiguration, buckets: updatedBuckets });
    }

    const [newBucketConfiguration, setNewBucketConfiguration] = useState(bucketConfiguration);

    const isEdit = bucketConfiguration?.name !== "" ? true : false;

    return (
        <div
            className="modal-backdrop"
            onClick={() => setShowBucketConfigurationForm(false)}
        >
            <Card
                className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex flex-col">
                    <Typography variant="h6">{isEdit ? "Edit Bucket Configuration" : "Add Bucket Configuration"}</Typography>
                    <Typography color="error" gutterBottom>{errorMessage}</Typography>
                    <TextField
                        label="Bucket Configuration Name"
                        value={newBucketConfiguration.name}
                        onChange={(e) => setNewBucketConfiguration({ ...newBucketConfiguration, name: e.target.value })}
                        style={{ margin: "15px 0px" }}
                    />
                    
                    <BucketList bucketConfiguration={newBucketConfiguration} setBucketConfiguration={setNewBucketConfiguration} errorMessage={errorMessage} isNewConfiguration={true} handleAddNewBucket_new={handleAddNewBucket} handleUpdateBucket_new={handleUpdateBucket} handleDeleteBucket_new={handleDeleteBucket} />

                    <div className="flex justify-between mt-4">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {isEdit ? "Update" : "Create"}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
}
