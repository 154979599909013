import { SparkLineChart } from '@mui/x-charts';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getDaysInMonth } from 'date-fns';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectQuestionsByOutcome,
  setSelectedDashboardQuestionIDs
} from '../../../store/slices/outcomeSlice';

export default function QuestionSelectionGrid({ outcomeId }) {
  const dispatch = useDispatch();
  // Select questions from Redux store
  const questions = useSelector((state) =>
    selectQuestionsByOutcome(state, outcomeId)
  );

  // Define columns for the data grid
  const columns = [
    {
      field: 'question_text',
      headerName: 'Question',
      flex: 2,
      minWidth: 320,
      headerClassName: 'superAppThemeHeader',
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'status',
      headerName: 'Status',
      description: 'The Question status within the Hivemind workflow.',
      width: 150,
      headerClassName: 'superAppThemeHeader',
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'current_crowd_forecast',
      headerName: 'Crowd Forecast',
      description: 'The current aggregated forecast for the Question.',
      width: 150,
      headerClassName: 'superAppThemeHeader',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        const value = params.row.current_crowd_forecast;
        return value != null ? `${(value * 100).toFixed(1)}%` : 'N/A';
      }
    },
    {
      field: 'relevance_level',
      headerName: 'Relevance',
      description:
        'The level of relevance between the Question and Outcome. Specifically, the expected difference a True or False resolution of each Question will make to the Outcome.',
      width: 150,
      headerClassName: 'superAppThemeHeader',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        const value = params.row.relevance_level;
        return value != null ? value.toFixed(3) : 'N/A';
      },
      sortComparator: (v1, v2) => {
        // Handle null/undefined cases
        const value1 = v1 ?? 0;
        const value2 = v2 ?? 0;
        return Math.abs(value1) - Math.abs(value2);
      }
    },
    {
      field: 'current_impact',
      headerName: 'Current Impact',
      description:
        'The current impact the Question has on the Outcome based on likelihood and relevance.',
      width: 150,
      headerClassName: 'superAppThemeHeader',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        const value = params.row.current_impact;
        return value != null ? value.toFixed(4) : 'N/A';
      },
      sortComparator: (v1, v2) => {
        // Handle null/undefined cases
        const value1 = v1 ?? 0;
        const value2 = v2 ?? 0;
        return Math.abs(value1) - Math.abs(value2);
      }
    },
    {
      field: 'daily_forecast_changes',
      headerName: 'Daily Forecast Change',
      description:
        'The amount the Questions forecast has changed day-to-day over the last month.',
      flex: 1,
      minWidth: 150,
      align: 'left',
      valueFormatter: (params) => {
        return JSON.stringify(params);
      },
      renderCell: renderSparklineCell,
      sortComparator: (v1, v2) => {
        // Handle null/undefined cases
        const value1Avg =
          v1.reduce((total, next) => total + next.forecast_change, 0) /
          v1.length;
        const value2Avg =
          v2.reduce((total, next) => total + next.forecast_change, 0) /
          v2.length;
        return Math.abs(value1Avg) - Math.abs(value2Avg);
      }
    }
  ];

  function renderSparklineCell(params) {
    const { value, colDef } = params;
    if (!value || value.length === 0) {
      return null;
    }
    let forecast_changes = value.map((entry) =>
      parseFloat((entry['forecast_change'] * 100).toFixed(2))
    );
    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <SparkLineChart
          data={forecast_changes}
          width={colDef.computedWidth || 100}
          height={32}
          plotType="bar"
          showHighlight
          showTooltip
          colors={['hsl(202 98% 52%)']}
          valueFormatter={(value) => `${value}%`}
          xAxis={{
            scaleType: 'band',
            data: value.map((entry) => entry['date'])
          }}
          yAxis={{
            min: 0,
            max: 100
          }}
        />
      </div>
    );
  }

  // Handle row selection
  const handleRowSelection = (selectedRowIds) => {
    // Dispatch action to update selected question IDs in Redux store
    dispatch(setSelectedDashboardQuestionIDs(selectedRowIds));
  };

  return (
    <div className="w-full h-full my-2">
      <DataGrid
        autoHeight
        checkboxSelection
        rows={questions}
        columns={columns}
        getRowId={(row) => row.id}
        onRowSelectionModelChange={handleRowSelection}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } }
        }}
        pageSizeOptions={[10, 20, 50]}
        disableColumnResize={false}
        disableDensitySelector={true}
        density="compact"
        slotProps={{
          filterPanel: {
            filterFormProps: {
              logicOperatorInputProps: {
                variant: 'outlined',
                size: 'small'
              },
              columnInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' }
              },
              operatorInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' }
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: 'outlined',
                  size: 'small'
                }
              }
            }
          }
        }}
        slots={{ toolbar: GridToolbar }}
      />
    </div>
  );
}
