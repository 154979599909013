import WarningIcon from '@mui/icons-material/Warning';
import {
  Backdrop,
  Button,
  Card,
  Collapse,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  acceptQuestion,
  fetchQuestionById,
  rejectQuestion,
  selectFilteredQuestionsByOutcomeWithoutSelf,
  selectOutcomeById,
  selectQuestionById,
  setQuestionAsDuplicate
} from '../../store/slices/outcomeSlice';
import SmallQuestionCard from '../cards/SmallQuestionCard';
import InfoTooltip from '../other/InfoTooltip';
import useWindowDimensions from '../useWindowDimensions.jsx';

export default function ModerationModal({ shown, close, questionId }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const maxCommentLength = 700;
  const { width } = useWindowDimensions();
  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );
  const outcome = useSelector((state) =>
    selectOutcomeById(state, question.outcome_id)
  );
  var dateSortedComments = question.comments.toSorted(
    (a, b) => moment(b.created_at) - moment(a.created_at)
  );
  var resolvableComment = dateSortedComments.find(
    (comment) => comment.comment_type === 'resolvability rejection'
  );
  var relevanceComment = dateSortedComments.find(
    (comment) => comment.comment_type === 'relevance rejection'
  );
  var duplicateComment = dateSortedComments.find(
    (comment) => comment.comment_type === 'duplicate rejection'
  );

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [resolvableValue, setResolvableValue] = useState(
    question.resolvable === 'True'
      ? 'yes'
      : question.resolvable === 'False'
      ? 'no'
      : 'not set'
  );
  const [resolvableRejectionComment, setResolvableRejectionComment] = useState(
    resolvableComment ? resolvableComment.text : ''
  );
  const [relevantValue, setRelevantValue] = useState(
    question.relevant === 'True'
      ? 'yes'
      : question.relevant === 'False'
      ? 'no'
      : 'not set'
  );
  const [relevantRejectionComment, setRelevantRejectionComment] = useState(
    relevanceComment ? relevanceComment.text : ''
  );
  const [duplicateValue, setDuplicateValue] = useState(
    question.parent_question_id
      ? 'yes'
      : question.rejected_as_duplicate === 'True'
      ? 'yes'
      : question.rejected_as_duplicate === 'False'
      ? 'no'
      : 'not set'
  );
  const [duplicateRejectionComment, setDuplicateRejectionComment] = useState(
    duplicateComment ? duplicateComment.text : ''
  );

  const [acceptErrorMessage, setAcceptErrorMessage] = useState('');
  const [rejectErrorMessage, setRejectErrorMessage] = useState('');
  var rejectStatus = '';
  var acceptStatus = '';
  var duplicateStatus = '';

  const [duplicateRequestStatus, setDuplicateRequestStatus] = useState('idle');
  const [duplicateErrorMessage, setDuplicateErrorMessage] = useState('');
  const [selectedQuestionId, setSelectedQuestionId] = useState(
    question.parent_question_id ? question.parent_question_id : ''
  );
  const questionFilter = {
    pending: true,
    accepted: true,
    rejected: false,
    submitted: false,
    notSubmitted: false,
    closed: false,
    duplicate: false
  };
  const dropdownQuestions = useSelector((state) =>
    selectFilteredQuestionsByOutcomeWithoutSelf(
      state,
      question.outcome_id,
      questionFilter,
      question.created_by.id
    )
  );

  let menuItems;
  if (dropdownQuestions && dropdownQuestions.length > 0) {
    menuItems = dropdownQuestions
      .filter((q) => q.id !== questionId)
      .map((dropdownQuestion, index) => (
        <MenuItem
          key={index}
          value={dropdownQuestion.id}
          disableGutters
          dense
          divider>
          <SmallQuestionCard questionId={dropdownQuestion.id} />
        </MenuItem>
      ));
  } else {
    menuItems = [];
  }
  const handleSelectedIDChange = (event) => {
    setSelectedQuestionId(event.target.value);
  };

  const closeModal = () => {
    close();
  };

  useEffect(() => {
    if (shown) {
      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          close();
        }
      };
      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [shown, close]);

  const handleResolvableValueChange = (event) => {
    setResolvableValue(event.target.value);
  };
  const changeResolvableRejectionComment = (event) =>
    setResolvableRejectionComment(event.target.value);
  const handleRelevantValueChange = (event) => {
    setRelevantValue(event.target.value);
  };
  const changeRelevantRejectionComment = (event) =>
    setRelevantRejectionComment(event.target.value);
  const handleDuplicateValueChange = (event) => {
    setDuplicateValue(event.target.value);
  };
  const changeDuplicateRejectionComment = (event) =>
    setDuplicateRejectionComment(event.target.value);

  const canAccept =
    [questionId, checkTokenStatus()].every(Boolean) &&
    updateRequestStatus === 'idle';

  const accept = async () => {
    if (canAccept) {
      let isMounted = true;
      setAcceptErrorMessage('');
      acceptStatus = '';
      try {
        setUpdateRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: questionId,
          resolvable: resolvableValue === 'yes' ? true : null,
          relevant: relevantValue === 'yes' ? true : null,
          duplicate: duplicateValue === 'no' ? false : null,
          auth_token: token
        };
        await dispatch(acceptQuestion(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setUpdateRequestStatus('idle');
              isMounted = false;
            }
            if (response.status === 'success') {
              acceptStatus = 'success';
            }
          });
      } catch (err) {
        setAcceptErrorMessage(`Failed to accept question: ${err.message}`);
        if (isMounted) {
          setUpdateRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setAcceptErrorMessage('Question acceptance could not be set.');
    }
  };

  const canReject =
    [questionId, checkTokenStatus()].every(Boolean) &&
    (resolvableValue === 'no' ||
      relevantValue === 'no' ||
      duplicateValue === 'yes') &&
    (resolvableValue === 'no' ? resolvableRejectionComment : true) &&
    (relevantValue === 'no' ? relevantRejectionComment : true) &&
    (duplicateValue === 'yes' ? duplicateRejectionComment : true) &&
    updateRequestStatus === 'idle';

  const reject = async () => {
    if (canReject) {
      let isMounted = true;
      setRejectErrorMessage('');
      rejectStatus = '';
      try {
        setUpdateRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        var shouldSubmit = false;
        let payload = {
          id: questionId,
          auth_token: token
        };
        // only submits a value if the comment has changed or the resolvability/relevance/duplicacy has been toggled
        if (
          resolvableValue === 'no' &&
          ((resolvableComment
            ? resolvableRejectionComment !== resolvableComment.text
            : true) ||
            question.resolvable === 'True')
        ) {
          payload['resolvable'] = false;
          payload['resolvableRejectionComment'] = resolvableRejectionComment;
          shouldSubmit = true;
        }
        if (
          relevantValue === 'no' &&
          ((relevanceComment
            ? relevantRejectionComment !== relevanceComment.text
            : true) ||
            question.relevant === 'True')
        ) {
          payload['relevant'] = false;
          payload['relevantRejectionComment'] = relevantRejectionComment;
          shouldSubmit = true;
        }
        if (
          duplicateValue === 'yes' &&
          ((duplicateComment
            ? duplicateRejectionComment !== duplicateComment.text
            : true) ||
            question.rejected_as_duplicate === 'False') &&
          outcome.forecast_mechanism === 'derived'
        ) {
          payload['duplicate'] = true;
          payload['duplicateRejectionComment'] = duplicateRejectionComment;
          shouldSubmit = true;
        }
        // will only send the reject request if one of resolvable relevant or duplicate has been set / comment has been changed
        if (shouldSubmit) {
          await dispatch(rejectQuestion(payload))
            .unwrap()
            .then((response) => {
              if (isMounted) {
                setUpdateRequestStatus('idle');
                isMounted = false;
              }
              if (response.status === 'success') {
                rejectStatus = 'success';
              }
            });
        } else {
          rejectStatus = 'success';
        }
      } catch (err) {
        setRejectErrorMessage(`Failed to reject question: ${err.message}`);
        if (isMounted) {
          setUpdateRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (resolvableValue === 'no' && resolvableRejectionComment === '') {
      setRejectErrorMessage(
        'Please provide a resolvability rejection comment.'
      );
    } else if (relevantValue === 'no' && relevantRejectionComment === '') {
      setRejectErrorMessage('Please provide a relevance rejection comment.');
    } else if (
      duplicateValue === 'yes' &&
      duplicateRejectionComment === '' &&
      outcome.forecast_mechanism === 'derived'
    ) {
      setRejectErrorMessage('Please provide a duplicate rejection comment.');
    } else {
      setRejectErrorMessage('Question rejection could not be set.');
    }
  };

  const canMarkDuplicate =
    [questionId, checkTokenStatus()].every(Boolean) &&
    selectedQuestionId !== '' &&
    duplicateRequestStatus === 'idle';

  const markDuplicate = async () => {
    if (canMarkDuplicate) {
      let isMounted = true;
      setDuplicateErrorMessage('');
      duplicateStatus = '';
      try {
        setDuplicateRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: questionId,
          duplicateOfId: selectedQuestionId,
          auth_token: token
        };
        await dispatch(setQuestionAsDuplicate(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setSelectedQuestionId('');
            }
            if (response.status === 'success') {
              duplicateStatus = 'success';
            }
          });
      } catch (err) {
        setDuplicateErrorMessage(
          `Failed to set question as a duplicate: ${err.message}`
        );
      } finally {
        if (isMounted) {
          setDuplicateRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (selectedQuestionId === '') {
      setDuplicateErrorMessage(
        'Please select a Question for which this is a duplicate before confirming.'
      );
    } else {
      setDuplicateErrorMessage('Failed to set question as a duplicate.');
    }
  };

  const submitConfirmation = async () => {
    if (
      resolvableValue === 'no' ||
      relevantValue === 'no' ||
      duplicateValue === 'yes'
    ) {
      if (duplicateValue === 'yes' && outcome.forecast_mechanism === 'manual') {
        await markDuplicate();
        if (resolvableValue === 'no' || relevantValue === 'no') {
          await reject();
        }
      } else {
        await reject();
      }
      // if some of the values do not meet the reject condition, we should still accept them
      if (
        resolvableValue === 'yes' ||
        relevantValue === 'yes' ||
        duplicateValue === 'no'
      ) {
        await accept();
      }
      if (
        rejectStatus === 'success' &&
        (resolvableValue === 'yes' ||
        relevantValue === 'yes' ||
        duplicateValue === 'no'
          ? acceptStatus === 'success'
          : true) &&
        (duplicateValue === 'yes' && outcome.forecast_mechanism === 'manual'
          ? duplicateStatus === 'success'
          : true)
      )
        close();
    } else {
      await accept();
      if (acceptStatus === 'success') close();
    }
  };

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Backdrop
        sx={{
          zIndex: 999,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={true}
        onClick={() => close()}>
        <Card
          className="modal-content w-full sm:w-6/7 md:w-5/6 lg:w-3/5 xl:w-3/5 2xl:w-3/5"
          sx={{ overflowY: 'auto !important', maxHeight: '90vh' }}
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}>
          <div>
            <Typography
              sx={{ fontWeight: 700, fontSize: '1.1rem' }}
              className="text-center">
              Question Moderation
            </Typography>
            {acceptErrorMessage && (
              <Typography color="error">{acceptErrorMessage}</Typography>
            )}
            {rejectErrorMessage && (
              <Typography color="error">{rejectErrorMessage}</Typography>
            )}
            {duplicateErrorMessage && (
              <Typography color="error">{duplicateErrorMessage}</Typography>
            )}
            <div>
              <div className="break-words font-medium">
                <Typography
                  sx={{ fontWeight: 400, py: 0.6 }}
                  color="primary.main">
                  Question:
                </Typography>
                <div className="border-2 rounded">
                  <SmallQuestionCard questionId={questionId} />
                </div>
              </div>

              <div
                className={`flex flex-row justify-between items-center w-5/6 my-6 mx-auto ${
                  resolvableValue === 'no'
                    ? 'h-36'
                    : width > 700
                    ? 'h-10'
                    : 'h-28'
                }`}>
                <div className="flex w-28">
                  <Typography>Resolvable?</Typography>
                  <div className="ml-1 flex flex-row">
                    <InfoTooltip text="Is the question unambiguously resolvable and does it include a source to an authority that can determine the resolution?" />

                    {resolvableValue === 'not set' && (
                      <Tooltip
                        title={
                          "This value needs to be set before the question can advance from 'Pending' status."
                        }
                        placement="bottom">
                        <div>
                          <WarningIcon color="warning" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <RadioGroup
                  value={resolvableValue}
                  onChange={handleResolvableValueChange}
                  row={width > 700}>
                  <FormControlLabel
                    disabled
                    value="not set"
                    control={<Radio />}
                    label="Not Set"
                  />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                <Collapse
                  orientation="horizontal"
                  in={resolvableValue === 'no'}>
                  <div className="mt-2">
                    <h3 className="text-sm font-semibold">Rejection Comment</h3>
                    <TextField
                      multiline
                      variant="outlined"
                      placeholder="Enter comment"
                      value={resolvableRejectionComment}
                      onChange={(event) =>
                        changeResolvableRejectionComment(event)
                      }
                      rows="3"
                      className="resize-none my-2 p-2 rounded border-solid border-2"
                      helperText={`${resolvableRejectionComment.length}/${maxCommentLength}`}
                      error={
                        resolvableRejectionComment.length > maxCommentLength
                      }
                      sx={{ fontSize: '0.855rem' }}
                    />
                  </div>
                </Collapse>
              </div>

              <div
                className={`flex flex-row justify-between items-center w-5/6 my-6 mx-auto ${
                  relevantValue === 'no'
                    ? 'h-36'
                    : width > 700
                    ? 'h-10'
                    : 'h-28'
                }`}>
                <div className="flex w-28">
                  <Typography>Relevant?</Typography>
                  <div className="ml-1 flex flex-row ">
                    <InfoTooltip
                      text="A question is 'relevant' if understanding its probability would be of use, even if only indirectly, in thinking about the outcome.
                      Mark a question as 'not relevant' if it is completely unrelated to the concerns expressed in the outcome."
                    />
                    {relevantValue === 'not set' && (
                      <Tooltip
                        title={
                          "This value needs to be set before the question can advance from 'Pending' status."
                        }
                        placement="bottom">
                        <div>
                          <WarningIcon color="warning" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <RadioGroup
                  value={relevantValue}
                  onChange={handleRelevantValueChange}
                  row={width > 700}>
                  <FormControlLabel
                    disabled
                    value="not set"
                    control={<Radio />}
                    label="Not Set"
                  />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                <Collapse orientation="horizontal" in={relevantValue === 'no'}>
                  <div className="mt-2">
                    <h3 className="text-sm font-semibold">Rejection Comment</h3>
                    <TextField
                      multiline
                      variant="outlined"
                      placeholder="Enter comment"
                      value={relevantRejectionComment}
                      onChange={(event) =>
                        changeRelevantRejectionComment(event)
                      }
                      rows="3"
                      className="resize-none w-full my-2 p-2 rounded border-solid border-2"
                      helperText={`${relevantRejectionComment.length}/${maxCommentLength}`}
                      error={relevantRejectionComment.length > maxCommentLength}
                      sx={{ fontSize: '0.855rem' }}
                    />
                  </div>
                </Collapse>
              </div>

              <div
                className={`flex flex-row justify-between items-center w-5/6 my-6 mx-auto ${
                  duplicateValue === 'yes'
                    ? 'h-36'
                    : width > 700
                    ? 'h-10'
                    : 'h-28'
                }`}>
                <div className="flex w-28">
                  <Typography>Duplicate?</Typography>
                  <div className="ml-1 flex flex-row">
                    <InfoTooltip text="A question is a duplicate if another question on this outcome has already covered the question topic and was posted before this question." />

                    {duplicateValue === 'not set' && (
                      <Tooltip
                        title={
                          "This value needs to be set before the question can advance from 'Pending' status."
                        }
                        placement="bottom">
                        <div>
                          <WarningIcon color="warning" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <RadioGroup
                  value={duplicateValue}
                  onChange={handleDuplicateValueChange}
                  row={width > 700}>
                  <FormControlLabel
                    disabled
                    value="not set"
                    control={<Radio />}
                    label="Not Set"
                  />
                  <FormControlLabel
                    disabled={question.parent_question_id ? true : false}
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    disabled={question.parent_question_id ? true : false}
                    value="no"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                <Collapse
                  orientation="horizontal"
                  in={duplicateValue === 'yes'}>
                  {outcome.forecast_mechanism === 'derived' ? (
                    <div className="mt-2">
                      <h3 className="text-sm font-semibold">
                        Rejection Comment
                      </h3>
                      <TextField
                        multiline
                        variant="outlined"
                        placeholder="Enter comment"
                        value={duplicateRejectionComment}
                        onChange={(event) =>
                          changeDuplicateRejectionComment(event)
                        }
                        rows="3"
                        className="resize-none w-full my-2 p-2 rounded border-solid border-2"
                        helperText={`${duplicateRejectionComment.length}/${maxCommentLength}`}
                        error={
                          duplicateRejectionComment.length > maxCommentLength
                        }
                        sx={{ fontSize: '0.855rem' }}
                      />
                    </div>
                  ) : (
                    <div className="break-words text-black font-medium mb-3 w-full">
                      <Typography
                        sx={{ fontWeight: 400, py: 0.6 }}
                        color="primary.main">
                        Above Question is a Duplicate Of:{' '}
                      </Typography>

                      {menuItems.length === 0 ? (
                        'No eligible questions found'
                      ) : (
                        <div>
                          <InputLabel id="duplicate-question-selection-label">
                            Duplicate of
                          </InputLabel>
                          <Select
                            disabled={
                              question.parent_question_id ? true : false
                            }
                            labelId="duplicate-question-selection-label"
                            id="duplicate-question-selection"
                            value={selectedQuestionId}
                            onChange={handleSelectedIDChange}
                            label="Duplicate of"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              fontWeight: 'bold',
                              width: '100%'
                            }}
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 600, width: 300 } }
                            }}>
                            {menuItems}
                          </Select>
                        </div>
                      )}
                    </div>
                  )}
                </Collapse>
              </div>

              <div className="flex justify-end mt-4">
                <div className="mx-2">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'gray',
                      ':hover': { backgroundColor: '#757575' }
                    }}
                    onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
                <div className="mx-2">
                  <Button
                    variant="contained"
                    onClick={() =>
                      submitConfirmation().then(
                        dispatch(
                          fetchQuestionById({
                            questionId: questionId,
                            auth_token: localStorage.getItem('auth_token')
                          })
                        )
                      )
                    }>
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Backdrop>
    </div>
  ) : null;
}
