import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Button,
  Collapse,
  Divider,
  Switch,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import Card from '@mui/material/Card';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MDEditor from '@uiw/react-md-editor';
import { parseISO } from 'date-fns';
import ukLocale from 'date-fns/locale/en-GB';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { FiDelete, FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  fetchBucketPresets,
  selectAllPresets
} from '../../store/slices/bucketSlice';
import {
  deleteOutcome,
  selectLatestOutcomeBelief,
  selectOutcomeBeliefChange,
  updateOutcome
} from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';
import {
  fetchUserGroups,
  selectGroupNames
} from '../../store/slices/userGroupSlice';
import { selectUserPreferences } from '../../store/slices/userSlice';
import ConfirmationModal from '../modals/ConfirmationModal';
import InfoTooltip from '../other/InfoTooltip';
import Markdown from '../other/Markdown';
import MultiSelectDropdown from '../other/MultiSelectDropdown';
import OutcomeStatusText from '../other/OutcomeStatusText';
import SelectDropdown from '../other/SelectDropdown';

export default function OutcomeCard({ outcome, selectedFilter }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );
  const title = outcome.title;
  const [newTitle, setNewTitle] = useState(outcome.title);
  const [description, setDescription] = useState(outcome.description);
  const [questionSubmissionLimit, setQuestionSubmissionLimit] = useState(
    outcome.question_submission_limit
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [editCard, setEditCard] = useState(false);
  const [adminEditCard, setAdminEditCard] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [endDate, setEndDate] = useState(parseISO(outcome.end_at));
  const userPreferences = useSelector((state) => selectUserPreferences(state));
  const [forecastingStartDate, setforecastingStartDate] = useState(
    parseISO(outcome.forecasting_start_date)
  );
  const [generationDeadline, setGenerationDeadline] = useState(
    parseISO(outcome.generation_end_date)
  );

  // Additional Fields
  const userGroupStatus = useSelector((state) => state.userGroups.status);
  const bucketPresetStatus = useSelector(
    (state) => state.buckets.presets.status
  );

  const [showNoGoingBackGroups, setShowNoGoingBackGroups] = useState(false);
  const [showNoGoingBackToggle, setShowNoGoingBackToggle] = useState(false);

  const changeOutcomeGroups = (newValue) => {
    if (newValue.length !== outcome.groups.length) {
      setShowNoGoingBackGroups(true);
    }
    setOutcomeGroups(newValue);
  };

  useEffect(() => {
    if (
      userGroupStatus === 'idle' &&
      outcome.created_by.username === userData.username
    ) {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchUserGroups(token));
    }
  }, [userGroupStatus, dispatch, userData.username]);

  useEffect(() => {
    if (
      bucketPresetStatus === 'idle' &&
      outcome.created_by.username === userData.username
    ) {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchBucketPresets(token));
    }
  }, [bucketPresetStatus, dispatch, userData.username]);

  const groupNames = useSelector((state) => selectGroupNames(state));
  const presets = useSelector((state) => selectAllPresets(state));
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [continueForecasting, setContinueForecasting] = useState(
    outcome.continue_forecasting
  );
  const [bucketPreset, setBucketPreset] = useState(outcome.bucket_preset);
  const oldOutcomeGroups = outcome.groups.map((group) => group.name);
  const [outcomeGroups, setOutcomeGroups] = useState(
    outcome.groups.map((group) => group.name)
  );
  const [outcomeOwnerCanQuestion, setOutcomeOwnerCanQuestion] = useState(
    outcome.owner_can_question == true || outcome.owner_can_question === 'True'
  );
  const [outcomeOwnerCanForecast, setOutcomeOwnerCanForecast] = useState(
    outcome.owner_can_forecast == true || outcome.owner_can_forecast === 'True'
  );
  const [crowdCanViewStrategies, setCrowdCanViewStrategies] = useState(
    outcome.crowd_can_view_strategies == 'True'
  );
  const [crowdCanSubmitStrategies, setCrowdCanSubmitStrategies] = useState(
    outcome.crowd_can_submit_strategies == 'True'
  );

  const [aiForecasting, setAiForecasting] = useState(
    outcome.enable_AI_forecasting == 'True'
  );

  const fieldsCanEdit = {
    continueForecasting: true,
    bucketPreset: true,
    outcomeGroups: outcome.groups,
    outcomeOwnerCanQuestion: !(
      outcome.owner_can_question === true ||
      outcome.owner_can_question === 'True'
    ),
    outcomeOwnerCanForecast: !(
      outcome.owner_can_forecast === true ||
      outcome.owner_can_forecast === 'True'
    ),
    crowdCanViewStrategies: !(outcome.crowd_can_view_strategies === 'True'),
    crowdCanSubmitStrategies:
      !(outcome.crowd_can_submit_strategies === 'True') &&
      crowdCanViewStrategies === true,
    aiForecasting: true
  };

  const [lastToggle, setLastToggle] = useState('');

  const revertLastToggle = () => {
    if (lastToggle === 'outcomeOwnerCanForecast') {
      setOutcomeOwnerCanForecast(!outcomeOwnerCanForecast);
    }
    if (lastToggle === 'outcomeOwnerCanQuestion') {
      setOutcomeOwnerCanQuestion(!outcomeOwnerCanQuestion);
    }
    if (lastToggle === 'crowdCanViewStrategies') {
      setCrowdCanViewStrategies(!crowdCanViewStrategies);
    }
    if (lastToggle === 'crowdCanSubmitStrategies') {
      setCrowdCanSubmitStrategies(!crowdCanSubmitStrategies);
    }
  };

  const outcomeTitleMaxLength = 45;
  const outcomeSummaryMaxLength = 2000;
  const isDarkMode = localStorage.getItem('mode') === 'dark';

  const changeTitle = (event) => {
    setNewTitle(event.target.value);
  };
  const changeDescription = (value) => {
    setDescription(value);
  };
  const changeQuestionSubmissionLimit = (event) => {
    const submissionLimit = event.target.validity.valid
      ? event.target.value
      : questionSubmissionLimit;
    setQuestionSubmissionLimit(submissionLimit);
  };
  const latestBelief = useSelector((state) =>
    selectLatestOutcomeBelief(state, outcome.id)
  );
  const beliefChange = useSelector((state) =>
    selectOutcomeBeliefChange(state, outcome.id)
  );

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [deleteRequestStatus, setDeleteRequestStatus] = useState('idle');

  const openOutcomeView = () => {
    navigate(`/outcome/${outcome.id}`);
  };

  const openOutcomeNewTab = () => {
    window.open(`/outcome/${outcome.id}`, '_blank');
  };

  const canUpdate =
    [
      outcome.id,
      newTitle ||
        description ||
        questionSubmissionLimit ||
        forecastingStartDate ||
        endDate ||
        generationDeadline,
      checkTokenStatus()
    ].every(Boolean) && updateRequestStatus === 'idle';

  const updateOutcomeData = async () => {
    if (canUpdate) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setUpdateRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: outcome.id,
          auth_token: token
        };
        if (newTitle) {
          payload.new_title = newTitle;
        }
        if (description) {
          payload.description = description;
        }
        if (questionSubmissionLimit) {
          payload.question_submission_limit = questionSubmissionLimit;
        }
        if (forecastingStartDate) {
          payload.forecasting_start_date = forecastingStartDate;
        }
        if (endDate) {
          payload.end_date = endDate;
        }
        if (generationDeadline) {
          payload.generation_deadline = generationDeadline;
        }
        if (bucketPreset !== outcome.bucket_preset) {
          payload.new_bucket_preset = bucketPreset.id;
        }
        if (outcomeGroups !== oldOutcomeGroups) {
          payload.groups = outcomeGroups;
        }
        if (outcomeOwnerCanForecast) {
          payload.owner_can_forecast = outcomeOwnerCanForecast;
        }
        if (outcomeOwnerCanQuestion) {
          payload.owner_can_question = outcomeOwnerCanQuestion;
        }
        if (continueForecasting !== outcome.continue_forecasting) {
          payload.continue_forecasting = continueForecasting;
        }
        if (crowdCanViewStrategies !== undefined) {
          payload.crowd_can_view_strategies = crowdCanViewStrategies;
        }
        if (crowdCanSubmitStrategies !== undefined) {
          payload.crowd_can_submit_strategies = crowdCanSubmitStrategies;
        }
        if (aiForecasting !== outcome.ai_forecasting) {
          payload.ai_forecasting = aiForecasting;
        }
        await dispatch(updateOutcome(payload)).unwrap();
        setEditCard(!editCard);
      } catch (err) {
        setErrorMessage(`Failed to update outcome: ${err.message}`);
      } finally {
        if (isMounted) {
          setUpdateRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (newTitle && description)
        setErrorMessage('Outcome could not be created.');
      if (!description) setErrorMessage('Description does not exist.');
      if (!newTitle) setErrorMessage('Title does not exist.');
      if (!questionSubmissionLimit)
        setErrorMessage('Question Submission Limit does not exist.');
      if (!forecastingStartDate)
        setErrorMessage('Forecasting Start Date does not exist.');
      if (!endDate) setErrorMessage('Outcome end date does not exist.');
    }
  };

  const canDelete =
    [title, checkTokenStatus()].every(Boolean) &&
    deleteRequestStatus === 'idle';

  const deleteOutcomeData = async () => {
    if (canDelete) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setDeleteRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          outcomeId: outcome.id,
          auth_token: token
        };
        await dispatch(deleteOutcome(payload)).unwrap();
      } catch (err) {
        setErrorMessage(`Failed to delete outcome: ${err.message}`);
      } finally {
        if (isMounted) {
          setDeleteRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (title) setErrorMessage('Outcome could not be created.');
      if (!title) setErrorMessage('Title does not exist.');
    }
  };

  const outcomeCardClick = (e) => {
    if (e.ctrlKey) {
      openOutcomeNewTab();
    } else {
      openOutcomeView();
    }
  };

  const getBorderColour = () => {
    if (outcome.statuses.includes('Forecasting')) {
      return theme.palette.statuses.mid3;
    } else if (outcome.statuses.includes('Evaluation')) {
      return theme.palette.statuses.mid2;
    } else if (outcome.statuses.includes('Generation')) {
      return theme.palette.statuses.mid1;
    } else if (outcome.statuses.includes('Closed')) {
      return theme.palette.statuses.mid4;
    } else {
      return theme.palette.statuses.mid5;
    }
  };

  const getOutcomeStatusColour = (status) => {
    switch (status) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };

  if (usernameSettings === undefined) {
    return null;
  } else {
    return (
      <div>
        {outcome === undefined ? (
          <div></div>
        ) : (
          <Card
            sx={{
              transition: '0.3s',
              boxShadow: '0 7px 20px -9px rgba(0,0,0,0.3)',
              '&:hover': {
                boxShadow: '0 14px 37px -9.125px rgba(0,0,0,0.3)',
                bgcolor: 'hover'
              },
              borderLeftWidth: '5px',
              borderLeftColor: getBorderColour()
            }}
            className={`rounded shadow-lg m-5 px-4 ${
              editCard ? 'py-5' : 'cursor-pointer duration-300 py-7'
            }`}>
            {!editCard ? (
              <div
                tabIndex={0}
                onClick={(e) => outcomeCardClick(e)}
                onKeyDown={(e) => {
                  if (e.key === ' ' || e.key === 'Enter') {
                    outcomeCardClick(e);
                  }
                }}>
                <div className="flex justify-between">
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.1rem'
                    }}>
                    <span className="whitespace-pre-line">{newTitle}</span>
                  </Typography>
                  <div className="flex flex-row">
                    {outcome.statuses.map((status, index) => (
                      <Typography
                        key={index}
                        sx={{
                          borderColor: getOutcomeStatusColour(status),
                          backgroundColor: getOutcomeStatusColour(status),
                          py: 0,
                          px: 0.5,
                          fontSize: '0.95rem',
                          mx: 0.6
                        }}
                        className={`flex justify-center items-center border-1 rounded text-white font-normal text-xs px-1 ml-1`}>
                        {status}
                      </Typography>
                    ))}
                  </div>
                </div>
                <div className="OutcomeInfo flex flex-wrap items-center justify-start">
                  {(userData.role === 'Admin' ||
                    userData.role === 'Moderator' ||
                    usernameSettings.active) && (
                    <div className="flex">
                      <Typography
                        sx={{
                          ml: 0.6,
                          fontSize: '0.95rem',
                          alignItems: 'center'
                        }}
                        color="text.secondary">
                        Created by
                      </Typography>
                      <Typography
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/profile/${outcome.created_by.username}`);
                        }}
                        sx={{ mx: 0.6, fontSize: '0.95rem' }}
                        className="hover:underline hover:cursor-pointer"
                        color="primary.main">
                        {outcome.created_by.username}
                      </Typography>
                    </div>
                  )}
                  {outcome.statuses.map((status, index) => (
                    <OutcomeStatusText
                      status={status}
                      outcome_id={outcome.id}
                      key={index}
                    />
                  ))}
                </div>
                <div className="flex">
                  {latestBelief && (
                    <div className="flex">
                      <Typography
                        sx={{ ml: 0.6, fontSize: '0.95rem' }}
                        color="text.secondary">
                        Belief:
                      </Typography>
                      <Typography
                        sx={{ mx: 0.6, fontSize: '0.95rem' }}
                        color={
                          theme.palette.mode === 'dark'
                            ? latestBelief.belief < 0.4
                              ? 'tomato'
                              : latestBelief.belief < 0.6
                              ? 'orange'
                              : 'limegreen'
                            : latestBelief.belief < 0.4
                            ? 'red'
                            : latestBelief.belief < 0.6
                            ? 'orange'
                            : 'green'
                        }>
                        {(latestBelief.belief * 100).toFixed(1)}%
                      </Typography>
                    </div>
                  )}
                  {beliefChange !== null && (
                    <div className="flex">
                      <Typography
                        sx={{ ml: 0.6, fontSize: '0.95rem' }}
                        color="text.secondary">
                        Today:
                      </Typography>
                      <div className="flex">
                        <Typography
                          sx={{ ml: 0.6, fontSize: '0.95rem' }}
                          color={
                            theme.palette.mode === 'dark'
                              ? beliefChange > 0
                                ? 'limegreen'
                                : beliefChange < 0
                                ? 'tomato'
                                : 'text.secondary'
                              : beliefChange > 0
                              ? 'green'
                              : beliefChange < 0
                              ? 'red'
                              : 'text.secondary'
                          }>
                          {(beliefChange * 100).toFixed(1)}%
                        </Typography>
                        {beliefChange > 0 ? (
                          <ArrowDropUpIcon
                            sx={{
                              color:
                                theme.palette.mode === 'dark'
                                  ? 'limegreen'
                                  : 'green'
                            }}
                          />
                        ) : beliefChange < 0 ? (
                          <ArrowDropDownIcon
                            sx={{
                              color:
                                theme.palette.mode === 'dark' ? 'tomato' : 'red'
                            }}
                          />
                        ) : (
                          <RemoveIcon color="disabled" />
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {userPreferences.outcome_layout === 'detail' && (
                  <div className="">
                    <Divider />
                    <div className="markdown flex my-1 max-h-36 overflow-y-auto">
                      <Typography
                        sx={{
                          ml: 0.6,
                          mr: 0.6,
                          fontSize: '0.95rem',
                          fontWeight: 'bold'
                        }}
                        color="text.secondary">
                        Description:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '0.95rem'
                        }}
                        component="div">
                        <Markdown description={outcome.description} />
                      </Typography>
                    </div>
                    <div className="flex">
                      <Typography
                        sx={{
                          ml: 0.6,
                          mr: 0.6,
                          fontSize: '0.95rem',
                          fontWeight: 'bold'
                        }}
                        color="text.secondary">
                        Outcome Ends:
                      </Typography>
                      <Typography sx={{ fontSize: '0.95rem' }}>{`${moment
                        .utc(outcome.end_at)
                        .local()
                        .format('dddd, MMMM Do YYYY')}`}</Typography>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                <Typography className="px-1" color="text.secondary">
                  Outcome Title
                </Typography>

                {errorMessage && (
                  <p className="text-xs text-red-600 py-2">{errorMessage}</p>
                )}

                <TextField
                  type="text"
                  variant="outlined"
                  value={newTitle}
                  onChange={(event) => changeTitle(event)}
                  className="w-full"
                  disabled={
                    outcome.questions.length !== 0 && userData.role !== 'Admin'
                  }
                  inputProps={{ style: { fontSize: '0.875rem' } }}
                  helperText={`${newTitle.length}/${outcomeTitleMaxLength}`}
                  error={newTitle.length > outcomeTitleMaxLength}
                  sx={{ my: 0.6 }}
                />

                <Typography
                  className="px-1"
                  color="text.secondary"
                  sx={{ my: 0.6 }}>
                  Outcome Description
                </Typography>
                <div data-color-mode={isDarkMode ? 'dark' : 'light'}>
                  <MDEditor
                    id="outcomeDescription"
                    value={description}
                    onChange={(event) => changeDescription(event)}
                    textareaProps={{
                      placeholder:
                        'A text description that describes your decision, objectives, and any other key concerns.',
                      maxLength: outcomeSummaryMaxLength,
                      required: true,
                      autoCorrect: 'on'
                    }}
                    preview="edit"
                    style={{
                      backgroundColor: isDarkMode
                        ? theme.palette.background.paper
                        : '#fff',
                      color: isDarkMode ? theme.palette.text.primary : '#000'
                    }}
                    readOnly={
                      outcome.questions.length !== 0 &&
                      userData.role !== 'Admin'
                    }
                    defaultTabEnable={true}
                  />
                </div>
                {outcome.forecast_mechanism === 'manual' && (
                  <div className="flex justify-start items-center mb-1">
                    <div className="">
                      <Typography
                        className="px-1"
                        color="text.secondary"
                        sx={{ my: 0.6, fontSize: '0.875rem' }}>
                        Question Submission Limit
                      </Typography>
                      <TextField
                        type="text"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        value={questionSubmissionLimit}
                        size="small"
                        onChange={(event) =>
                          changeQuestionSubmissionLimit(event)
                        }
                        className="md:w-1/2"
                        sx={{ my: 0.6 }}
                      />
                    </div>
                  </div>
                )}
                <div className="flex flex-wrap items-center my-2 justify-start">
                  <div className="w-fit lg:w-1/4 m-1 flex">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ukLocale}>
                      <DatePicker
                        clearable
                        label="Question Generation Deadline"
                        value={generationDeadline}
                        onChange={(newValue) => {
                          setGenerationDeadline(newValue);
                        }}
                        maxDateTime={forecastingStartDate}
                        textField={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <div className="ml-1">
                      <InfoTooltip text="The date on which Question generation activity will stop and Questions will be submitted to the forecasting platform." />
                    </div>
                  </div>
                  <div className="w-fit lg:w-1/4 m-1 flex">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ukLocale}>
                      <DatePicker
                        clearable
                        label="Forecasting Start Date"
                        value={forecastingStartDate}
                        onChange={(newValue) => {
                          setforecastingStartDate(newValue);
                        }}
                        maxDateTime={endDate}
                        textField={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <div className="ml-1">
                      <InfoTooltip text="The date on which the Question Evaluation phase will end and Questions will be submitted to the forecasting platform." />
                    </div>
                  </div>
                  <div className="w-fit lg:w-1/4 m-1 flex">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ukLocale}>
                      <DatePicker
                        clearable={false}
                        label="Outcome End Date"
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        minDateTime={forecastingStartDate}
                        textField={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <div className="ml-1">
                      <InfoTooltip text="The date to which the Outcome is no longer valid." />
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-row"
                  onClick={() =>
                    setShowAdditionalFields(!showAdditionalFields)
                  }>
                  <Typography className="pr-2">Advanced Settings</Typography>
                  <button className="mr-1">
                    {!showAdditionalFields ? (
                      <BsChevronDown size={15} />
                    ) : (
                      <BsChevronUp size={15} />
                    )}
                  </button>
                </div>
                <Collapse in={showAdditionalFields}>
                  <div>
                    <div className="flex mt-3">
                      <Typography className="">Bucket Preset</Typography>
                      <div className="ml-1">
                        <InfoTooltip text="Select the Preset of Forecasting Buckets for this Outcome" />
                      </div>
                    </div>
                    <SelectDropdown
                      label={'Select Preset'}
                      options={presets}
                      selectedValue={bucketPreset || ''}
                      onSelectedValueChange={setBucketPreset}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="mt-3">
                      <div className="flex">
                        <Typography className="">
                          Outcome Owner Can Forecast?
                        </Typography>
                        <div className="ml-1">
                          <InfoTooltip text="Indicates whether the Outcome owner can forecast on this Outcome." />
                        </div>
                      </div>
                      <Switch
                        disabled={!fieldsCanEdit.outcomeOwnerCanForecast}
                        checked={outcomeOwnerCanForecast}
                        onChange={() => {
                          setOutcomeOwnerCanForecast(!outcomeOwnerCanForecast);
                          setLastToggle('outcomeOwnerCanForecast');
                          setShowNoGoingBackToggle(true);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>

                    <div className="mt-3">
                      <div className="flex">
                        <Typography className="">
                          Outcome Owner Can Create A Question?
                        </Typography>
                        <div className="ml-1">
                          <InfoTooltip text="Indicates whether the Outcome owner can submit questions on this Outcome." />
                        </div>
                      </div>
                      <Switch
                        disabled={!fieldsCanEdit.outcomeOwnerCanQuestion}
                        checked={outcomeOwnerCanQuestion}
                        onChange={() => {
                          setOutcomeOwnerCanQuestion(!outcomeOwnerCanQuestion);
                          setLastToggle('outcomeOwnerCanQuestion');
                          setShowNoGoingBackToggle(true);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>

                    <div className="mt-3">
                      <div className="flex">
                        <Typography className="">
                          Continue forecasting past end date?
                        </Typography>
                        <div className="ml-1">
                          <InfoTooltip text="If this option is enabled, Hivemind will continue forecasting questions past your Outcome's end date." />
                        </div>
                      </div>
                      <Switch
                        checked={continueForecasting}
                        onChange={() =>
                          setContinueForecasting(!continueForecasting)
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                    <div className="mt-3">
                      <div className="flex">
                        <Typography className="">AI Forecasting</Typography>
                        <div className="ml-1">
                          <InfoTooltip text="Indicates whether the to enable AI forecasting on this outcome" />
                        </div>
                      </div>
                      <Switch
                        checked={aiForecasting}
                        onChange={() => {
                          setAiForecasting(!aiForecasting);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="mt-3">
                      <div className="flex">
                        <Typography className="">
                          Crowd Can View Strategies?
                        </Typography>
                        <div className="ml-1">
                          <InfoTooltip text="Indicates whether the Crowd can view strategies on this Outcome." />
                        </div>
                      </div>
                      <Switch
                        checked={crowdCanViewStrategies}
                        disabled={!fieldsCanEdit.crowdCanViewStrategies}
                        onChange={() => {
                          if (crowdCanViewStrategies) {
                            setCrowdCanViewStrategies(!crowdCanViewStrategies);
                            setCrowdCanSubmitStrategies(false);
                          } else {
                            setCrowdCanViewStrategies(!crowdCanViewStrategies);
                          }
                          setLastToggle('crowdCanViewStrategies');
                          setShowNoGoingBackToggle(true);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                    <div className="mt-3">
                      <div className="flex">
                        <Typography className="">
                          Crowd Can Submit Strategies?
                        </Typography>
                        <div className="ml-1">
                          <InfoTooltip text="Indicates whether the Crowd can submit strategies on this Outcome." />
                        </div>
                      </div>
                      <Switch
                        checked={crowdCanSubmitStrategies}
                        disabled={!fieldsCanEdit.crowdCanSubmitStrategies}
                        onChange={() => {
                          setCrowdCanSubmitStrategies(
                            !crowdCanSubmitStrategies
                          );
                          setLastToggle('crowdCanSubmitStrategies');
                          setShowNoGoingBackToggle(true);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex mt-3">
                      <Typography className="">Outcome Group(s)</Typography>
                      <div className="ml-1">
                        <InfoTooltip text="Groups that will be able to see and participate on this Outcome" />
                      </div>
                    </div>
                    <MultiSelectDropdown
                      label={'Select group(s)'}
                      options={groupNames}
                      selectedValues={outcomeGroups}
                      onSelectedValuesChange={changeOutcomeGroups}
                      disabledOptions={oldOutcomeGroups}
                    />
                  </div>
                </Collapse>
              </>
            )}
            <div className="flex pt-1 items-center justify-between">
              <div className="flex">
                {(userData.role === 'Admin' ||
                  outcome.created_by.username === userData.username) && (
                  <>
                    <div className="flex items-center">
                      {outcome.statuses.includes('Generation') &&
                      outcome.created_by.username === userData.username &&
                      outcome.questions.length === 0 ? (
                        <Button
                          variant="text"
                          sx={{ color: 'text.primary' }}
                          onClick={() => {
                            setEditCard(!editCard);
                          }}>
                          <FiEdit className="mr-1" />
                          {editCard ? 'Cancel' : 'Edit'}
                        </Button>
                      ) : (
                        userData.role === 'Admin' && (
                          <Button
                            variant="text"
                            sx={{ color: 'text.primary' }}
                            onClick={() => {
                              setEditCard(!editCard);
                              setAdminEditCard(true);
                            }}>
                            <FiEdit className="mr-1" />
                            {editCard ? 'Cancel' : 'Edit'}
                          </Button>
                        )
                      )}
                    </div>
                    <div className="flex items-center">
                      <Button
                        variant="text"
                        sx={{ color: 'text.primary' }}
                        onClick={() => {
                          setShowDeleteConfirm(true);
                        }}>
                        <FiDelete className="mr-1" />
                        Delete
                      </Button>
                    </div>
                  </>
                )}
              </div>
              {editCard && (
                <div className="flex justify-end px-5">
                  <Button variant="contained" onClick={updateOutcomeData}>
                    Save
                  </Button>
                </div>
              )}
            </div>
          </Card>
        )}
        {showDeleteConfirm && (
          <ConfirmationModal
            shown={showDeleteConfirm}
            close={() => {
              setShowDeleteConfirm(false);
            }}
            confirm={() => {
              setShowDeleteConfirm(false);
              deleteOutcomeData();
            }}
            confirmationMessage="Do you really want to delete this outcome? This process cannot be undone"
          />
        )}
        {showNoGoingBackGroups && (
          <ConfirmationModal
            shown={showNoGoingBackGroups}
            close={() => {
              setShowNoGoingBackGroups(false);
            }}
            confirm={() => {
              setShowNoGoingBackGroups(false);
            }}
            confirmationMessage="Assigning new groups is an irreversible action. Once you save, you cannot revert this change."
          />
        )}
        {showNoGoingBackToggle && (
          <ConfirmationModal
            shown={showNoGoingBackToggle}
            close={() => {
              setShowNoGoingBackToggle(false);
              revertLastToggle();
            }}
            confirm={() => {
              setShowNoGoingBackToggle(false);
            }}
            confirmationMessage="Toggling this setting is an irreversible action. Once you save, you cannot revert this change."
          />
        )}
      </div>
    );
  }
}
