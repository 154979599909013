import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { hivemindAPI } from "../HivemindAPI";

const studyAdapter = createEntityAdapter({});
const initialState = studyAdapter.getInitialState({
  status: "idle",
  error: null,
  studyProgress: {}
});

export const fetchStudies = createAsyncThunk(
  "study/fetchStudies",
  async (auth_token) => {
    const response = await hivemindAPI.fetch(
      "/api/studies",
      null,
      auth_token
    );
    return response.json();
  }
);

export const deleteStudy = createAsyncThunk(
  "study/deleteStudy",
  async (payload) => {
    const response = await hivemindAPI.delete(
      "/api/studies",
      {
        id: payload.id,
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateStudy = createAsyncThunk(
  "study/updateStudy",
  async (payload) => {
    const response = await hivemindAPI.put(
      "/api/studies",
      {
        id: payload.id,
        name: payload.name,
        completion_code: payload.completion_code
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addUsersToStudy = createAsyncThunk(
  "study/addUserToStudy",
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/studies/${payload.id}/add_user`,
      {
        user_ids: payload.user_ids,
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addOutcomesToStudy = createAsyncThunk(
  "study/addOutcomeToStudy",
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/studies/${payload.id}/add_outcome`,
      {
        outcome_ids: payload.outcome_ids,
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const removeUsersFromStudy = createAsyncThunk(
  "study/removeUserFromStudy",
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/studies/${payload.id}/remove_user`,
      {
        user_ids: payload.user_ids,
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const removeOutcomesFromStudy = createAsyncThunk(
  "study/removeOutcomeFromStudy",
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/studies/${payload.id}/remove_outcome`,
      {
        outcome_ids: payload.outcome_ids,
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewStudy = createAsyncThunk(
  "study/addNewStudy",
  async (payload) => {
    const response = await hivemindAPI.post(
      "/api/studies",
      {
        name: payload.name,
        completion_code: payload.completion_code
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchStudyProgress = createAsyncThunk(
  'users/fetchStudyProgress',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/studies/${payload.study_id}/progress`,
      null,
      payload.auth_token
    );
    const json = await response.json();
    return {response: json, study_id: payload.study_id};
  }
);

export const studySlice = createSlice({
  name: "study",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchStudies.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStudies.fulfilled, (state, action) => {
        state.status = "succeeded";
        studyAdapter.upsertMany(state, action.payload.data);
      })
      .addCase(fetchStudies.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateStudy.fulfilled, (state, action) => {
        const updatedStudy = action.payload.data;
        studyAdapter.updateOne(state, {
          id: updatedStudy.id,
          changes: updatedStudy,
        });
      })
      .addCase(deleteStudy.fulfilled, (state, action) => {
        studyAdapter.removeOne(state, action.meta.arg.id);
      })
      .addCase(addNewStudy.fulfilled, (state, action) => {
        studyAdapter.addOne(state, action.payload.data);
      })
      .addCase(addUsersToStudy.fulfilled, (state, action) => {
        const updatedStudy = action.payload.data;
        studyAdapter.updateOne(state, {
          id: updatedStudy.id,
          changes: updatedStudy,
        });
      })
      .addCase(addOutcomesToStudy.fulfilled, (state, action) => {
        const updatedStudy = action.payload.data;
        studyAdapter.updateOne(state, {
          id: updatedStudy.id,
          changes: updatedStudy,
        });
      })
      .addCase(removeUsersFromStudy.fulfilled, (state, action) => {
        const updatedStudy = action.payload.data;
        studyAdapter.updateOne(state, {
          id: updatedStudy.id,
          changes: updatedStudy,
        });
      })
      .addCase(removeOutcomesFromStudy.fulfilled, (state, action) => {
        const updatedStudy = action.payload.data;
        studyAdapter.updateOne(state, {
          id: updatedStudy.id,
          changes: updatedStudy,
        });
      })
      .addCase(fetchStudyProgress.fulfilled, (state, action) => {
        state.studyProgress[action.payload.study_id] = action.payload.response.data;
      });
  },
});

export const studyActions = studySlice.actions;
export default studySlice.reducer;

export const {
  selectAll: selectAllStudies,
  selectById: selectStudyById,
  selectIds: selectStudyIds,
} = studyAdapter.getSelectors((state) => state.studies);

export const selectStudyNames = createSelector(
  [selectAllStudies, (state) => state],
  (studies) => {
    return studies.map((study) => study.name);
  }
);

export const selectStudyByName = createSelector(
  [selectAllStudies, (state, name) => name],
  (studies, name) => studies.find((study) => study.name === name)
);
