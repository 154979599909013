import React, { useContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  addNewForecastLink,
  selectQuestionById
} from '../../store/slices/outcomeSlice';

import { Button, Card, TextField, Typography, Backdrop } from '@mui/material';
import './styles.css';

export default function AddForecastLinkModal({
  shown,
  close,
  afterSubmit,
  questionId
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);

  const [forecastLink, setForecastLink] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );

  const changeForecastLinkText = (event) => setForecastLink(event.target.value);

  const canCreate =
    [forecastLink, question, checkTokenStatus()].every(
      Boolean
    ) && addRequestStatus === 'idle';

  const addForecastLink = async () => {
    if (canCreate) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setAddRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          question_id: question.id,
          forecasting_source_link: forecastLink,
          auth_token: token
        };
        await dispatch(addNewForecastLink(payload))
          .unwrap()
          .then((data) => {
            if (isMounted) setForecastLink('');
          });
        if (isMounted) setAddRequestStatus('idle');
        isMounted = false;
        afterSubmit();
      } catch (err) {
        setErrorMessage(`Failed to save forecast link: ${err.message}`);
        if (isMounted) setAddRequestStatus('idle');
        isMounted = false;
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (forecastLink) setErrorMessage('Forecast link could not be created.');
      if (!forecastLink) setErrorMessage('Forecast link does not exist.');
    }
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        close();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [close]);

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Backdrop
        sx={{
          zIndex: 999,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={true}
        onClick={() => close()}>
        <Card
          className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}>
          <div>
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '1rem', my: 1 }}
              className="text-center">
              Add link to the question on a forecasting platform
            </Typography>
            <Typography color="error">{errorMessage}</Typography>
            <TextField
              variant="outlined"
              multiline
              placeholder="Forecast link"
              value={forecastLink}
              onChange={(event) => changeForecastLinkText(event)}
              minRows="1"
              sx={{ my: 3 }}
              className="w-full"></TextField>
            <div className="flex my-1">
              <div className="w-full mx-2">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'gray',
                    ':hover': { backgroundColor: '#757575' }
                  }}
                  onClick={close}
                  className="w-full">
                  Cancel
                </Button>
              </div>
              <div className="w-full mx-2">
                <Button
                  variant="contained"
                  onClick={addForecastLink}
                  className="w-full">
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </Backdrop>
    </div>
  ) : null;
}
