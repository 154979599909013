import {
  ArrowRight,
  CrisisAlert,
  PriorityHigh,
  Public,
  Warning
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectQuestionsByOutcome } from '../../../store/slices/outcomeSlice';

const ImpactMatrix = ({ outcomeId }) => {
  const questions = useSelector((state) =>
    selectQuestionsByOutcome(state, outcomeId)
  );

  const impactLevels = [
    { label: 'Minor', max: 0.1 },
    { label: 'Limited', max: 0.25 },
    { label: 'Moderate', max: 0.45 },
    { label: 'Significant', max: 0.7 },
    { label: 'Major', max: 1 }
  ];
  const likelihoodLevels = [
    { label: '<20%', max: 0.2 },
    { label: '20-40%', max: 0.4 },
    { label: '40-60%', max: 0.6 },
    { label: '60-80%', max: 0.8 },
    { label: '>80%', max: 1 }
  ];
  const theme = useTheme();

  const getCellColor = (impact, likelihood) => {
    const impactIndex = impactLevels.findIndex(
      (level) => level.label === impact.label
    );
    const likelihoodIndex = likelihoodLevels.findIndex(
      (level) => level.label === likelihood.label
    );

    if (impactIndex >= 3 && likelihoodIndex >= 3)
      return theme.palette.mode === 'dark' ? 'bg-red-300' : 'bg-red-100';
    if (impactIndex >= 3 && likelihoodIndex >= 2)
      return theme.palette.mode === 'dark' ? 'bg-orange-300' : 'bg-orange-100';
    if (impactIndex >= 2 && likelihoodIndex >= 3)
      return theme.palette.mode === 'dark' ? 'bg-orange-300' : 'bg-orange-100';
    return theme.palette.mode === 'dark' ? 'bg-zinc-500' : 'bg-gray-50';
  };

  const getIcon = (impact, likelihood) => {
    const impactIndex = impactLevels.findIndex(
      (level) => level.label === impact.label
    );
    const likelihoodIndex = likelihoodLevels.findIndex(
      (level) => level.label === likelihood.label
    );

    if (impactIndex >= 3 && likelihoodIndex >= 3) return <CrisisAlert />;
    if (impactIndex >= 3 && likelihoodIndex >= 2) return <PriorityHigh />;
    if (impactIndex >= 2 && likelihoodIndex >= 3) return <PriorityHigh />;
    if (impactIndex >= 1 && likelihoodIndex >= 2) return <Warning />;
    if (impactIndex >= 2 && likelihoodIndex >= 1) return <Warning />;
    return <Public />;
  };

  return (
    <div className="w-full">
      <Box>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Impact Matrix
        </Typography>
      </Box>
      <Box>
        <div className="relative pl-32 pr-4 pt-8">
          <div className="absolute left-0 top-1/2 -translate-y-1/2">
            <div className="transform -rotate-90 whitespace-nowrap flex items-center">
              <span className="font-bold tracking-wider text-sm uppercase">
                Impact
              </span>
              <ArrowRight className="w-4 h-4 stroke-[2]" />
            </div>
          </div>
          {/* Impact level labels on Y-axis */}
          <div className="absolute left-14 top-0 h-full flex flex-col justify-between text-xs">
            {[...impactLevels].reverse().map((level) => (
              <div
                key={level.label}
                className="h-32 flex items-center font-medium">
                {level.label}
              </div>
            ))}
          </div>
          {/* Matrix Grid */}
          <div className="grid grid-cols-5 gap-0.5 xl:gap-1">
            {impactLevels
              .slice()
              .reverse()
              .map((impact, i) =>
                likelihoodLevels.map((likelihood, j) => (
                  <div
                    key={`${impact.label}-${likelihood.label}`}
                    className={`relative h-12 xs-14 md:h-20 lg:24 xl:32 p-2 border ${getCellColor(
                      impact,
                      likelihood
                    )} overflow-y-auto`}>
                    <div className="flex flex-col gap-0.5">
                      {questions
                        .filter(
                          (question) =>
                            question.relevance_level &&
                            Math.abs(question.relevance_level) >=
                              (impactLevels[impactLevels.length - (i + 2)]
                                ?.max || 0) &&
                            Math.abs(question.relevance_level) < impact.max &&
                            question.current_crowd_forecast >=
                              (likelihoodLevels[j - 1]?.max || 0) &&
                            question.current_crowd_forecast < likelihood.max
                        )
                        .map((question, index) => (
                          <Tooltip
                            title={
                              <div>
                                <div>{`Question: ${question.question_text}`}</div>
                                <div>{`Forecast: ${(
                                  question.current_crowd_forecast * 100
                                ).toFixed(1)}%`}</div>
                                <div>{`Relevance: ${question.relevance_level}`}</div>
                              </div>
                            }
                            key={index}
                            arrow
                            slotProps={{
                              tooltip: {
                                sx: {
                                  color: 'text.primary',
                                  border: `1px solid ${
                                    (theme.vars || theme).palette.divider
                                  }`,
                                  borderRadius: 1,
                                  background: 'hsl(0, 0%, 100%)',
                                  ...theme.applyStyles('dark', {
                                    background: grey[900]
                                  })
                                }
                              }
                            }}>
                            <div
                              className="flex items-center gap-1 text-xs cursor-pointer"
                              onClick={() =>
                                window.open(
                                  `/questions/${question.id}`,
                                  '_blank'
                                )
                              }>
                              <span className="flex-shrink-0">
                                {getIcon(impact, likelihood)}
                              </span>
                              <span className="truncate">
                                {question.question_text}
                              </span>
                            </div>
                          </Tooltip>
                        ))}
                    </div>
                  </div>
                ))
              )}
          </div>
          {/* Likelihood labels */}
          <div className="mt-4 mb-4 grid grid-cols-5 gap-1">
            {likelihoodLevels.map((level) => (
              <div
                key={level.label}
                className="text-center text-xs font-medium">
                {level.label}
              </div>
            ))}
          </div>
        </div>
        {/* X-axis label with arrow */}
        <div className="mt-4 mb-4">
          <div className="flex items-center justify-center gap-1">
            <span className="font-bold tracking-wider text-sm uppercase">
              Likelihood
            </span>
            <ArrowRight className="w-4 h-4 stroke-[2]" />
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ImpactMatrix;
