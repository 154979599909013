import {
  Campaign,
  DoneAll,
  Flag,
  Message,
  ThumbDown,
  ThumbUp
} from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Tab,
  Typography
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  markNotificationAsSeen,
  selectUnseenUserNotifications,
  selectUserNotifications
} from '../../store/slices/notificationSlice';

const NOTIFICATIONS_PER_PAGE = 6;

export default function NotificationMenu({ userData, anchorEl, close }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const [visibleNotifications, setVisibleNotifications] = useState(
    NOTIFICATIONS_PER_PAGE
  );

  const unseenNotifications = useSelector(selectUnseenUserNotifications);
  const allNotifications = useSelector(selectUserNotifications);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setVisibleNotifications(NOTIFICATIONS_PER_PAGE); // Reset pagination when changing tabs
  };

  const handleShowMore = () => {
    setVisibleNotifications((prev) => prev + NOTIFICATIONS_PER_PAGE);
  };

  const dismissNotification = (notificationId) => {
    const token = localStorage.getItem('auth_token');
    const payload = {
      notification_id: notificationId,
      user_id: userData.id,
      auth_token: token
    };
    dispatch(markNotificationAsSeen(payload));
  };

  const NotificationItem = ({ notification, isUnseen }) => (
    <MenuItem
      sx={{
        py: 1.5,
        px: 2,
        backgroundColor: isUnseen ? 'rgba(24, 119, 242, 0.05)' : 'transparent',
        '&:hover': {
          backgroundColor: isUnseen
            ? 'rgba(24, 119, 242, 0.1)'
            : 'rgba(0, 0, 0, 0.04)'
        },
        whiteSpace: 'normal',
        height: 'auto',
        borderBottom: 1
      }}
      onClick={() => {
        navigate(`/profile/${userData.username}?tab=notifications`);
        close();
      }}>
      <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
        <Avatar
          sx={{
            width: 42,
            height: 42,
            bgcolor: isUnseen ? 'primary.main' : 'grey.200',
            flexShrink: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          {notification.type === 'reply' ? (
            <Message sx={{ color: isUnseen ? 'default' : 'primary.main' }} />
          ) : notification.type === 'success' ? (
            <ThumbUp sx={{ color: isUnseen ? 'default' : 'primary.main' }} />
          ) : notification.type === 'fail' ? (
            <ThumbDown sx={{ color: isUnseen ? 'default' : 'primary.main' }} />
          ) : notification.type === 'progress' ? (
            <Flag sx={{ color: isUnseen ? 'default' : 'primary.main' }} />
          ) : notification.type === 'complete' ? (
            <DoneAll sx={{ color: isUnseen ? 'default' : 'primary.main' }} />
          ) : (
            <Campaign sx={{ color: isUnseen ? 'default' : 'primary.main' }} />
          )}
        </Avatar>
        <Box sx={{ flex: 1, minWidth: 0 }}>
          <Typography
            sx={{
              fontSize: '0.95rem',
              lineHeight: 1.4,
              mb: 0.5,
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              color: isUnseen ? 'text.primary' : 'text.secondary'
            }}>
            {notification.message}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1
            }}>
            <Typography
              variant="body2"
              color={isUnseen ? 'primary' : 'text.secondary'}
              sx={{ fontWeight: isUnseen ? 600 : 400 }}>
              {moment.utc(notification.created_at).local().fromNow()}
            </Typography>
            {notification?.link && (
              <Button
                size="small"
                variant={isUnseen ? 'contained' : 'text'}
                sx={{
                  textTransform: 'none',
                  borderRadius: '4px',
                  px: 2,
                  color: isUnseen ? undefined : 'text.secondary'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  window.location.href = notification?.link;
                }}>
                View
              </Button>
            )}
            {isUnseen && (
              <Button
                size="small"
                variant="text"
                sx={{
                  textTransform: 'none',
                  color: 'text.secondary',
                  ':hover': { backgroundColor: '#ececec80' }
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  dismissNotification(notification.id);
                }}>
                Mark as read
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </MenuItem>
  );

  const renderNotificationList = (notifications) => {
    if (notifications.length === 0) {
      return (
        <MenuItem sx={{ py: 4 }}>
          <Typography textAlign="center" color="text.secondary">
            No notifications
          </Typography>
        </MenuItem>
      );
    }

    const visibleItems = notifications.slice(0, visibleNotifications);
    const hasMore = notifications.length > visibleNotifications;

    return (
      <>
        {visibleItems.map((notification, index) => (
          <NotificationItem
            key={index}
            notification={notification}
            isUnseen={
              currentTab === 1 ||
              unseenNotifications.some((n) => n.id === notification.id)
            }
          />
        ))}
        {hasMore && (
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <Button
              variant="text"
              onClick={handleShowMore}
              sx={{
                textTransform: 'none',
                fontWeight: 600
              }}>
              Show Older
            </Button>
          </Box>
        )}
      </>
    );
  };

  return (
    <Menu
      sx={{
        mt: '45px',
        '& .MuiPaper-root': {
          width: '360px',
          maxWidth: '90vw',
          borderRadius: '8px',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
          maxHeight: '80vh'
        }
      }}
      id="menu-notifications"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorEl)}
      onClose={close}>
      <Box sx={{ py: 1.5 }}>
        <Typography variant="h6" sx={{ fontWeight: 700, mb: 2, px: 2 }}>
          Notifications
        </Typography>
        <TabContext value={currentTab}>
          <TabList
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              '& .MuiTab-root': {
                textTransform: 'none',
                fontWeight: 600
              }
            }}>
            <Tab label="All" value={0} />
            <Tab
              label="Unseen"
              value={1}
              sx={{
                '& .MuiTab-root': {
                  color:
                    unseenNotifications.length > 0 ? 'primary.main' : 'inherit'
                }
              }}
            />
          </TabList>
          <Divider sx={{ my: 2 }} />
          <TabPanel value={0} sx={{ p: 0 }}>
            {renderNotificationList(allNotifications)}
          </TabPanel>
          <TabPanel value={1} sx={{ p: 0 }}>
            {renderNotificationList(unseenNotifications)}
          </TabPanel>
        </TabContext>
      </Box>
    </Menu>
  );
}
