import { alpha, Box, Stack } from '@mui/material';
import React from 'react';
import DashboardGrid from '../../components/other/dashboard/DashboardGrid';

const Dashboard = () => {
  return (
    <div>
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          overflow: 'auto'
        })}>
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            mx: 3,
            pb: 5,
            mt: { xs: 8, md: 0 }
          }}>
          <DashboardGrid />
        </Stack>
      </Box>
    </div>
  );
};

export default Dashboard;
