import { TextField, Button, FormLabel } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectSettingById,
  updateSetting
} from '../../store/slices/settingsSlice';
import InfoTooltip from '../other/InfoTooltip';

export default function SettingText({ id }) {
  const setting = useSelector((state) => selectSettingById(state, id));
  const dispatch = useDispatch();
  const [editedText, setEditedText] = useState(setting.text);

  const handleTextChange = (e) => {
    setEditedText(e.target.value);
  };

  const handleUpdateText = () => {
    dispatch(
      updateSetting({
        id: setting.id,
        text: editedText,
        auth_token: localStorage.getItem('auth_token')
      })
    );
  };

  return (
    <div className="grid grid-cols-2 gap-4 w-full">
      <div className="flex items-center justify-end mr-1">
        <FormLabel
          id="settings-text-label"
          sx={{ fontSize: '0.8rem', color: 'text.primary' }}
        >
          {setting.name}
        </FormLabel>
        <div className="text-sm ml-1 pb-1 align-text-top">
          <InfoTooltip text={setting.description} />
        </div>
      </div>
      <div className="flex items-center justify-start my-2">
        <TextField
          value={editedText}
          onChange={handleTextChange}
          name={setting.name}
          size="small"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateText}
          sx={{ marginLeft: '10px' }}
        >
          Change
        </Button>
      </div>
    </div>
  );
}
