import { combineReducers, configureStore } from '@reduxjs/toolkit';
import bucketReducer from './slices/bucketSlice';
import notificationReducer from './slices/notificationSlice';
import outcomeReducer from './slices/outcomeSlice';
import questionnaireReducer from './slices/questionnaireSlice';
import settingsReducer from './slices/settingsSlice';
import studyReducer from './slices/studySlice';
import userGroupReducer from './slices/userGroupSlice';
import usersReducer from './slices/userSlice';

const combinedReducer = combineReducers({
  outcomes: outcomeReducer,
  settings: settingsReducer,
  users: usersReducer,
  questionnaires: questionnaireReducer,
  userGroups: userGroupReducer,
  buckets: bucketReducer,
  notifications: notificationReducer,
  studies: studyReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'users/logout/fulfilled') {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer
});
