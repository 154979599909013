import {
  Box,
  Card,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {
  fetchOutcomes,
  fetchQuestionsByOutcome,
  selectAllOutcomes,
  selectOutcomeById,
  setDashboardOutcomeId
} from '../../../store/slices/outcomeSlice';
import HierarchicalBreadcrumbs from '../HierarchicalBreadcrumbs';
import FocusableCard from './FocusableCard';
import ImpactBreakdownPie from './ImpactBreakdownPie';
import ImpactMatrix from './ImpactMatrix';
import { OutcomeExplainer } from './OutcomeExplainer';
import QuestionSelectionGrid from './QuestionSelectionGrid';
import StackedProbabilitiesWidget from './StackedProbabilitiesWidget';

const DashboardGrid = () => {
  const dispatch = useDispatch();
  let { outcomeId } = useParams();
  const [outcomeQuestionStatus, setOutcomeQuestionStatus] = useState({});
  const [focusedWidget, setFocusedWidget] = useState(null);

  const outcomeStatus = useSelector(
    (_state) => _state.outcomes.outcomes.status
  );

  const selectedOutcomeId = useSelector(
    (_state) => _state.outcomes.outcomes.dashboardOutcomeId
  );

  const changeSelectedOutcome = (event) => {
    dispatch(setDashboardOutcomeId({ outcomeId: event.target.value }));
  };

  useEffect(() => {
    if (outcomeId) {
      dispatch(setDashboardOutcomeId({ outcomeId: parseInt(outcomeId) }));
    }
  }, [outcomeId]);

  useEffect(() => {
    let isMounted = true;
    function fetchOutcomeData() {
      if (isMounted) {
        if (outcomeStatus === 'idle') {
          const token = localStorage.getItem('auth_token');
          dispatch(fetchOutcomes({ auth_token: token }));
        }
      }
    }
    fetchOutcomeData();
    return () => {
      isMounted = false;
    };
  }, [outcomeStatus, dispatch]);

  const outcomes = useSelector((state) => selectAllOutcomes(state));
  const outcome = useSelector((state) =>
    selectOutcomeById(state, selectedOutcomeId)
  );
  useEffect(() => {
    if (selectedOutcomeId) {
      let outcomeQuestionStatusCopy = { ...outcomeQuestionStatus };
      outcomeQuestionStatusCopy[selectedOutcomeId] = 'idle';
      setOutcomeQuestionStatus(outcomeQuestionStatusCopy);
    }
  }, [selectedOutcomeId]);

  useEffect(() => {
    let isMounted = true;
    function fetchQuestionsData() {
      if (isMounted) {
        if (
          outcomeQuestionStatus &&
          outcomeQuestionStatus[selectedOutcomeId] === 'idle'
        ) {
          let outcomeQuestionStatusCopy = { ...outcomeQuestionStatus };
          outcomeQuestionStatusCopy[selectedOutcomeId] = 'loading';
          setOutcomeQuestionStatus(outcomeQuestionStatusCopy);
          const token = localStorage.getItem('auth_token');
          dispatch(
            fetchQuestionsByOutcome({
              outcomeId: selectedOutcomeId,
              auth_token: token
            })
          ).then((response) => {
            if (response.payload.status === 'success') {
              let outcomeQuestionStatusCopy = { ...outcomeQuestionStatus };
              outcomeQuestionStatusCopy[selectedOutcomeId] = 'succeeded';
              setOutcomeQuestionStatus(outcomeQuestionStatusCopy);
            } else {
              let outcomeQuestionStatusCopy = { ...outcomeQuestionStatus };
              outcomeQuestionStatusCopy[selectedOutcomeId] = 'failed';
              setOutcomeQuestionStatus(outcomeQuestionStatusCopy);
            }
          });
        }
      }
    }

    fetchQuestionsData();
    return () => {
      isMounted = false;
    };
  }, [outcomeQuestionStatus, dispatch, selectedOutcomeId]);

  return (
    <div className="Dashboard mt-5 w-full">
      <div className="flex ml-10 mt-7 items-center">
        <HierarchicalBreadcrumbs
          outcomes={true}
          outcome={outcome ? outcome.title : 'Loading...'}
          outcomeId={outcome ? outcome.id : '?'}
          dashboard={true}
        />
      </div>
      <div className="flex justify-center">
        <Box
          sx={{
            width: '100%',
            maxWidth: { sm: '100%', lg: '90%', xl: '80%' },
            position: 'relative'
          }}>
          <div className="flex justify-between items-center my-1">
            <Typography component="h2" variant="h5" sx={{ m: 2 }}>
              Outcome Dashboard
            </Typography>

            <div className="flex items-center">
              <InputLabel id="sort-select-label" sx={{ mx: 1 }}>
                Outcome:
              </InputLabel>
              <Select
                labelId="sort-select-label"
                id="sort-select"
                value={selectedOutcomeId}
                sx={{ minWidth: 200 }}
                onChange={changeSelectedOutcome}>
                {outcomes.map((outcome) => (
                  <MenuItem key={outcome.id} value={outcome.id}>
                    {outcome.title}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          {!selectedOutcomeId ? (
            <div>Select an Outcome</div>
          ) : (
            <>
              {outcomeQuestionStatus[selectedOutcomeId] === 'failed' ? (
                <div>Data not found</div>
              ) : outcomeQuestionStatus[selectedOutcomeId] === 'succeeded' ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 2,
                    mb: (theme) => theme.spacing(2)
                  }}>
                  <FocusableCard
                    widgetKey="impact-matrix"
                    focusedWidget={focusedWidget}
                    setFocusedWidget={setFocusedWidget}
                    sx={{
                      flex: '1 1 calc(100% - 16px)'
                    }}>
                    <ImpactMatrix outcomeId={selectedOutcomeId} />
                  </FocusableCard>

                  <FocusableCard
                    widgetKey="stacked-probabilities"
                    focusedWidget={focusedWidget}
                    setFocusedWidget={setFocusedWidget}
                    sx={{
                      flex: '1 1 calc(50% - 16px)'
                    }}>
                    <StackedProbabilitiesWidget
                      outcomeId={selectedOutcomeId}
                      focussed={focusedWidget}
                    />
                  </FocusableCard>

                  <FocusableCard
                    widgetKey="impact-breakdown"
                    focusedWidget={focusedWidget}
                    setFocusedWidget={setFocusedWidget}
                    sx={{
                      flex: '1 1 calc(50% - 16px)'
                    }}>
                    <ImpactBreakdownPie
                      outcomeId={selectedOutcomeId}
                      focussed={focusedWidget}
                    />
                  </FocusableCard>

                  <FocusableCard
                    widgetKey="questions"
                    focusedWidget={focusedWidget}
                    setFocusedWidget={setFocusedWidget}
                    sx={{
                      flex: '1 1 calc(100% - 16px)'
                    }}>
                    <Typography component="h2" variant="subtitle2">
                      Questions
                    </Typography>
                    <QuestionSelectionGrid outcomeId={selectedOutcomeId} />
                  </FocusableCard>
                  <FocusableCard
                    widgetKey="outcome-explainer"
                    focusedWidget={focusedWidget}
                    setFocusedWidget={setFocusedWidget}
                    sx={{
                      flex: '1 1 calc(100% - 16px)'
                    }}>
                    <OutcomeExplainer
                      outcomeId={selectedOutcomeId}
                      focussed={focusedWidget}
                    />
                  </FocusableCard>
                </Box>
              ) : (
                <ClipLoader color="primary" loading={true} size={100} />
              )}
            </>
          )}
        </Box>
      </div>
    </div>
  );
};

export default DashboardGrid;
