import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../App';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectOutcomeById } from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';

import {
  Button,
  Card,
  Collapse,
  Divider,
  Typography,
  useTheme
} from '@mui/material';
import { BsChevronDoubleDown, BsChevronDoubleUp } from 'react-icons/bs';
import MarkdownComponent from '../other/Markdown';

export default function SmallOutcomeCard({ outcomeId }) {
  const { userData } = useContext(AuthContext);
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(true);
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );

  const outcome = useSelector((state) => selectOutcomeById(state, outcomeId));

  const getOutcomeStatusColour = (status) => {
    switch (status) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };

  if (usernameSettings === undefined) {
    return null;
  } else {
    return (
      <Card id={'outcomeCard' + outcomeId} className={`w-full`}>
        <div className="px-3 pt-2">
          <div className="break-words">
            <Typography sx={{ fontWeight: 'bold', lineHeight: 1.3 }}>
              <span className="whitespace-pre-line">{outcome.title}</span>
            </Typography>
          </div>
          <Divider sx={{ my: 0.6 }} />
          <Collapse in={!collapsed}>
            <div className="">
              <div className="markdown my-1 max-h-36 overflow-y-auto">
                <Typography
                  sx={{
                    fontSize: '0.95rem',
                    fontWeight: 'bold'
                  }}
                  color="text.secondary">
                  Description
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.95rem'
                  }}
                  component="div">
                  <MarkdownComponent description={outcome.description} />
                </Typography>
              </div>
              <div className="">
                <Typography
                  sx={{
                    fontSize: '0.95rem',
                    fontWeight: 'bold'
                  }}
                  color="text.secondary">
                  Outcome Ends
                </Typography>
                <Typography sx={{ fontSize: '0.95rem' }}>{`${moment
                  .utc(outcome.end_at)
                  .local()
                  .format('dddd, MMMM Do YYYY')}`}</Typography>
              </div>
            </div>
          </Collapse>
          <div className="flex justify-end">
            <Button
              sx={{ px: 1, minWidth: 0 }}
              onClick={(event) => {
                event.stopPropagation();
                setCollapsed(!collapsed);
              }}>
              {collapsed ? (
                <BsChevronDoubleDown size={15} />
              ) : (
                <BsChevronDoubleUp size={15} />
              )}
            </Button>
          </div>
        </div>
      </Card>
    );
  }
}
