import { DataSaverOff, DataSaverOn, Warning } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { cheerfulFiestaPalette, mangoFusionPalette } from '@mui/x-charts';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { PieChart } from '@mui/x-charts/PieChart';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  selectNegativeImpactQuestions,
  selectOutcomeById,
  selectPositiveImpactQuestions,
  selectQuestionsByOutcome
} from '../../../store/slices/outcomeSlice';
import { graphColors } from '../GraphColors';

const StyledText = styled('text', {
  shouldForwardProp: (prop) => prop !== 'variant'
})(({ theme }) => ({
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fill: (theme.vars || theme).palette.text.secondary,
  variants: [
    {
      props: {
        variant: 'primary'
      },
      style: {
        fontSize: theme.typography.h5.fontSize
      }
    },
    {
      props: ({ variant }) => variant !== 'primary',
      style: {
        fontSize: theme.typography.body2.fontSize
      }
    },
    {
      props: {
        variant: 'primary'
      },
      style: {
        fontWeight: theme.typography.h5.fontWeight
      }
    },
    {
      props: ({ variant }) => variant !== 'primary',
      style: {
        fontWeight: theme.typography.body2.fontWeight
      }
    }
  ]
}));

function PieCenterLabel({ primaryText, secondaryText }) {
  const { width, height, left, top } = useDrawingArea();
  const primaryY = top + height / 2 - 10;
  const secondaryY = primaryY + 24;

  return (
    <React.Fragment>
      <StyledText variant="primary" x={left + width / 2} y={primaryY}>
        {primaryText}
      </StyledText>
      <StyledText variant="secondary" x={left + width / 2} y={secondaryY}>
        {secondaryText}
      </StyledText>
    </React.Fragment>
  );
}

const colours = graphColors.map((color) => color.mid);

export default function ImpactBreakdownPie({ outcomeId, focussed }) {
  const [pieTab, setPieTab] = React.useState('1');

  const positiveQuestions = useSelector((state) =>
    selectPositiveImpactQuestions(state, outcomeId)
  );
  const negativeQuestions = useSelector((state) =>
    selectNegativeImpactQuestions(state, outcomeId)
  );
  const outcome = useSelector((state) => selectOutcomeById(state, outcomeId));

  const handleTabChange = (event, newValue) => {
    setPieTab(newValue);
  };

  let positiveData = positiveQuestions?.map((question) => ({
    label: `Question: ${question.question_text}`,
    value: question.current_impact
  }));

  let negativeData = negativeQuestions?.map((question) => ({
    label: `Question: ${question.question_text}`,
    value: Math.abs(question.current_impact)
  }));

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        flexGrow: 1
      }}>
      <Typography component="h2" variant="subtitle2">
        Impact Breakdown
      </Typography>
      <TabContext value={pieTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange}>
            <Tab label="Positive" value="1" />

            <Tab label="Negative" value="2" />
          </TabList>
        </Box>

        <TabPanel value="1">
          {positiveQuestions && positiveQuestions.length > 0 && (
            <div>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PieChart
                  colors={cheerfulFiestaPalette}
                  margin={{
                    left: 80,
                    right: 80,
                    top: 80,
                    bottom: 80
                  }}
                  series={[
                    {
                      data: positiveData,
                      innerRadius: focussed ? 110 : 95,
                      outerRadius: focussed ? 160 : 120,
                      paddingAngle: 0,
                      highlightScope: { faded: 'global', highlighted: 'item' }
                    }
                  ]}
                  height={focussed ? 360 : 300}
                  width={focussed ? 360 : 300}
                  slotProps={{
                    legend: { hidden: true },
                    popper: {
                      sx: {
                        '& .MuiChartsTooltip-paper': {
                          maxWidth: '26rem'
                        }
                      }
                    }
                  }}>
                  <PieCenterLabel
                    primaryText={
                      parseFloat(outcome?.positive_impact_percent?.toFixed(1)) +
                      '%'
                    }
                    secondaryText="Total Positive Impact"
                  />
                </PieChart>
              </Box>
              <div className="max-h-64 overflow-y-scroll">
                {positiveQuestions?.map((question, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    sx={{ alignItems: 'center', gap: 2, pb: 2 }}>
                    {/* {question.flag} */}
                    <DataSaverOn />
                    <Stack sx={{ gap: 1, flexGrow: 1 }}>
                      <Stack
                        direction="row"
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 2
                        }}>
                        <Typography variant="body2" sx={{ fontWeight: '500' }}>
                          {question.question_text}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary' }}>
                          {question.current_impact?.toFixed(3)}
                        </Typography>
                      </Stack>
                      <LinearProgress
                        variant="determinate"
                        aria-label="Question Impact"
                        value={
                          ((question.current_impact * 100) /
                            outcome?.positive_impact_percent) *
                          100
                        }
                        sx={{
                          [`& .${linearProgressClasses.bar}`]: {
                            backgroundColor: question.color
                          }
                        }}
                      />
                    </Stack>
                  </Stack>
                ))}
              </div>
            </div>
          )}
        </TabPanel>
        <TabPanel value="2">
          {negativeQuestions && negativeQuestions.length > 0 && (
            <div>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PieChart
                  colors={mangoFusionPalette}
                  margin={{
                    left: 80,
                    right: 80,
                    top: 80,
                    bottom: 80
                  }}
                  series={[
                    {
                      data: negativeData,
                      innerRadius: focussed ? 110 : 95,
                      outerRadius: focussed ? 160 : 120,
                      paddingAngle: 0,
                      highlightScope: { faded: 'global', highlighted: 'item' }
                    }
                  ]}
                  height={focussed ? 350 : 300}
                  width={focussed ? 350 : 300}
                  slotProps={{
                    legend: { hidden: true },
                    popper: {
                      sx: {
                        '& .MuiChartsTooltip-paper': {
                          maxWidth: '26rem'
                        }
                      }
                    }
                  }}>
                  <PieCenterLabel
                    primaryText={
                      parseFloat(outcome?.negative_impact_percent?.toFixed(1)) +
                      '%'
                    }
                    secondaryText="Total Negative Impact"
                  />
                </PieChart>
              </Box>
              <div className="max-h-64 overflow-y-scroll">
                {negativeQuestions?.map((question, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    sx={{ alignItems: 'center', gap: 2, pb: 2 }}>
                    {/* {question.flag} */}
                    <DataSaverOff />
                    <Stack sx={{ gap: 1, flexGrow: 1 }}>
                      <Stack
                        direction="row"
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 2
                        }}>
                        <Typography variant="body2" sx={{ fontWeight: '500' }}>
                          {question.question_text}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary' }}>
                          {question.current_impact?.toFixed(3)}
                        </Typography>
                      </Stack>
                      <LinearProgress
                        variant="determinate"
                        aria-label="Number of users by country"
                        value={
                          (Math.abs(question.current_impact * 100) /
                            outcome?.negative_impact_percent) *
                          100
                        }
                        sx={{
                          [`& .${linearProgressClasses.bar}`]: {
                            backgroundColor: question.color
                          }
                        }}
                      />
                    </Stack>
                  </Stack>
                ))}
              </div>
            </div>
          )}
        </TabPanel>
      </TabContext>
    </div>
  );
}
