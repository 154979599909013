import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  LinearProgress,
  Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../App';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import { fetchUserStudyProgress } from '../../store/slices/userSlice';

export default function StudyView() {
  const { userData } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(null);
  const studyProgress = useSelector((state) => state.users.studyProgress);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('auth_token');
      if (userData?.studies?.length > 0 && !loading) {
        setLoading(true);
        try {
          const promises = userData?.studies?.map((study) =>
            dispatch(
              fetchUserStudyProgress({ auth_token: token, study_id: study.id })
            )
          );

          await Promise.all(promises);
        } catch (error) {
          console.error('Error fetching study progress:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [userData, dispatch]);

  useEffect(() => {
    if (userData?.studies?.length > 0) {
      setExpanded(userData.studies[0].id);
    }
  }, [userData]);

  const handleAccordionChange = (studyId) => (event, isExpanded) => {
    setExpanded(isExpanded ? studyId : null);
  };

  const renderProgress = (title, progress) => {
    if (!progress) return null;

    const { complete, completed_amount, threshold } = progress;
    const percentage =
      completed_amount >= threshold
        ? 100
        : (completed_amount / threshold) * 100;
    return (
      <Box mb={2}>
        <Typography variant="subtitle1" gutterBottom>
          {title}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={percentage}
              color={complete === 'True' ? 'success' : 'primary'}
            />
          </Box>
          <Typography variant="body2" color="textSecondary">
            {`${completed_amount}/${threshold}`}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <div className="Study-Progress">
      <div className="m-5">
        <div className="flex justify-between ml-5 mt-7 items-center">
          <HierarchicalBreadcrumbs study={true} />
        </div>
        <Box className="study-progress" p={5}>
          <Typography variant="h4" gutterBottom>
            Study Progress
          </Typography>
          {(!userData?.studies || userData?.studies?.length === 0) && (
            <Typography>You are not part of any studies!</Typography>
          )}
          {userData?.studies?.map((study) => (
            <Accordion
              key={study.id}
              expanded={expanded === study.id}
              onChange={handleAccordionChange(study.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${study.id}-content`}
                id={`panel-${study.id}-header`}>
                <Typography variant="h6">
                  {study.name}
                  <Typography
                    color={
                      studyProgress[study.id]?.completed_study === 'True'
                        ? 'success'
                        : 'error'
                    }>
                    {studyProgress[study.id]?.completed_study === 'True'
                      ? 'Completed'
                      : 'Incomplete'}
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box width="100%">
                  {renderProgress(
                    'Forecast Criteria Progress',
                    studyProgress[study.id]?.forecast_criteria_progress
                  )}
                  {renderProgress(
                    'Commented Forecast Criteria Progress',
                    studyProgress[study.id]
                      ?.commented_forecast_criteria_progress
                  )}
                  {renderProgress(
                    'Question Criteria Progress',
                    studyProgress[study.id]?.question_criteria_progress
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </div>
    </div>
  );
}
