import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { addNewUserGroup, addUsersToUserGroup, addOutcomesToUserGroup } from "../../store/slices/userGroupSlice";
import { selectAllUsers } from '../../store/slices/userSlice';
import { selectAllOutcomes, fetchOutcomes } from "../../store/slices/outcomeSlice";
import MultiSelectDropdown from "../../components/other/MultiSelectDropdown"

import { Button, Card, TextField, Typography, Backdrop } from "@mui/material";
import { AuthContext, checkTokenStatus } from "../../App";

export default function CreateUserGroupModal({
  shown,
  close,
  setCreatorDisabled,
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const outcomeStatus = useSelector(
    (state) => state.outcomes.outcomes.status
  );

  useEffect(() => {
    if (outcomeStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchOutcomes({"auth_token": token}));
    }
  }, [outcomeStatus, dispatch]);

  const users = useSelector((state) => selectAllUsers(state));
  const userNames = users.map(user => {return user.username})
  const outcomes = useSelector((state) => selectAllOutcomes(state));
  const outcomeNames = outcomes.map(outcome => {return outcome.title})
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedOutcomes, setSelectedOutcomes] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [registerRequestStatus, setRegisterRequestStatus] = useState("idle");
  const changeName = (event) => setName(event.target.value);
  const changeDescription = (event) => setDescription(event.target.value);
  
  const handleUsersChange = (selectedValues) => {
    setSelectedUsers(selectedValues);
  };

  const handleOutcomesChange = (selectedValues) => {
    setSelectedOutcomes(selectedValues);
  };

  const canRegister =
    [name, description].every((val) => val !== "") &&
    checkTokenStatus() &&
    registerRequestStatus === "idle";

  const handleCreation = async () => {
    if (canRegister) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setRegisterRequestStatus("pending");
        setIsLoggedIn(true);
        const token = localStorage.getItem("auth_token");
        const userIds = users.map(user => {
          if (selectedUsers.includes(user.username)) {
            return user.id;
          }
          return null;
        }).filter(id => id !== null);
        
        const outcomeIds = outcomes.map(outcome => {
          if (selectedOutcomes.includes(outcome.title)) {
            return outcome.id;
          }
          return null;
        }).filter(id => id !== null);
        let payload = {
          name: name,
          description: description,
          auth_token: token,
          user_ids: userIds,
          outcome_ids: outcomeIds
        };
        await dispatch(addNewUserGroup(payload))
          .unwrap()
          .then((response) => {
            if (response.status === "success" && response.data.id) {
              setName("");
              setDescription("");
              payload.id = response.data.id
              setResponseMessage(`Group ${name} Successfully Created`);
            }
          });
          dispatch(addUsersToUserGroup(payload))
          .unwrap()
          .then((response) => {
            if (response.status === "success") {
              setSelectedUsers([]);
              setResponseMessage(`Group ${name} Successfully Created`);
            }
          });
          dispatch(addOutcomesToUserGroup(payload))
          .unwrap()
          .then((response) => {
            if (response.status === "success") {
              setSelectedOutcomes([]);
              setResponseMessage(`Group ${name} Successfully Created`);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to create: ${err.message}`);
      } finally {
        if (isMounted) {
          setRegisterRequestStatus("idle");
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      localStorage.removeItem("auth_token");
      setIsLoggedIn(false);
      return <Navigate to={"/login"} />;
    } else if (name === "") {
      setErrorMessage("Please set the name before creating a new group");
    } else if (description === "") {
        setErrorMessage("Please set the description before creating a new group");
    } else {
      setErrorMessage("Group could not be created.");
    }
  };

  const closeModal = () => {
    close();
    setCreatorDisabled(false);
  };

  useEffect(() => {
    if (shown) {
      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          close();
          setCreatorDisabled(false);
        }
      };
      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [shown, close]);

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Backdrop
        sx={{
            zIndex: 999,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}
        open={true}
        onClick={() => closeModal()}>
        <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 z-10"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <Typography
            sx={{ mb: 0.6, fontWeight: "bold", fontSize: "1.1rem" }}
            className="text-center"
          >
            Create New Group
          </Typography>
          {!responseMessage && (
            <div>
              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}

              <div className="mx-2">
                <Typography className="">Group Name</Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  autoComplete="Name"
                  value={name}
                  onChange={(event) => changeName(event)}
                  placeholder="Enter Name"
                  sx={{ my: 1.2 }}
                  className={`w-full`}
                />
              </div>

              <div className="mx-2">
                <Typography className="">Group Description</Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  autoComplete="Description"
                  value={description}
                  onChange={(event) => changeDescription(event)}
                  placeholder="Enter Description"
                  sx={{ my: 1.2 }}
                  className={`w-full`}
                />
              </div>

              <div className="mx-2">
                <Typography className="">Users</Typography>
                <MultiSelectDropdown
                  label={"Select user(s)"}
                  options={userNames}
                  selectedValues={selectedUsers}
                  onSelectedValuesChange={handleUsersChange}
                />
              </div>

              <div className="mx-2">
                <Typography className="">Outcomes</Typography>
                <MultiSelectDropdown
                  label={"Select outcome(s)"}
                  options={outcomeNames}
                  selectedValues={selectedOutcomes}
                  onSelectedValuesChange={handleOutcomesChange}   
                />
              </div>

              <div className="flex mt-4">
                <div className="w-full m-2">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "gray",
                      ":hover": { backgroundColor: "#757575" },
                    }}
                    onClick={closeModal}
                    className="w-full"
                  >
                    Cancel
                  </Button>
                </div>

                <div className="w-full m-2">
                  <Button
                    variant="contained"
                    onClick={handleCreation}
                    className="w-full"
                  >
                    Create Group
                  </Button>
                </div>
              </div>
            </div>
          )}

          {responseMessage && (
            <div>
              {responseMessage && (
                <Typography color="success.main" className="text-center">
                  {responseMessage}
                </Typography>
              )}

              <div className="flex mt-4 justify-center">
                <Button
                  onClick={closeModal}
                  variant="contained"
                  className="w-6/12"
                >
                  Close
                </Button>
              </div>
            </div>
          )}
        </div>
        </Card>
      </Backdrop>
    </div>
  ) : null;
}