import { TrendingDown, TrendingUp } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Typography,
  useTheme,
  Backdrop
} from '@mui/material';
import moment from 'moment';
import React, { useContext, useState, useEffect } from 'react';
import { FiCalendar } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  selectOutcomeByQuestionId,
  setQuestionResolutionImpact
} from '../../store/slices/outcomeSlice';
import SmallQuestionCard from '../cards/SmallQuestionCard';

export default function SetResolutionImpactModal({
  shown,
  close,
  questionId,
  submitter
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const [responseMessage, setResponseMessage] = useState('');
  const [resolutionImpactRequestStatus, setResolutionImpactRequestStatus] =
    useState('idle');
  const [selectedImpact, setSelectedImpact] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const closeModal = () => {
    close();
  };

  const outcome = useSelector((state) =>
    selectOutcomeByQuestionId(state, questionId)
  );

  const canSetResolutionImpact =
    [questionId, checkTokenStatus()].every(Boolean) &&
    selectedImpact !== '' &&
    resolutionImpactRequestStatus === 'idle';

  const setResolutionImpactConfirm = async () => {
    if (canSetResolutionImpact) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setResolutionImpactRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: questionId,
          resolutionImpact:
            selectedImpact === 'positive'
              ? true
              : selectedImpact === 'negative'
              ? false
              : null,
          auth_token: token
        };
        await dispatch(setQuestionResolutionImpact(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setSelectedImpact('');
            }
            if (response.status === 'success') {
              setErrorMessage('');
              setResponseMessage(
                `The Question's Resolution Impact has been successfully set`
              );
            }
          });
      } catch (err) {
        setErrorMessage(
          `Failed to set Question's Resolution Impact: ${err.message}`
        );
      } finally {
        if (isMounted) {
          setResolutionImpactRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (selectedImpact === '') {
      setErrorMessage('Please select a Resolution Impact before confirming.');
    } else {
      setErrorMessage("Failed to set Question's Resolution Impact");
    }
  };

  useEffect(() => {
    if (shown) {
      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          close();
        }
      };
      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [shown, close]);

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Backdrop
        sx={{
          zIndex: 999,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={true}
        onClick={() => close()}>   
        <Card
          className="modal-content w-full sm:w-6/7 md:w-5/6 lg:w-3/5 xl:w-3/5 2xl:w-3/5"
          sx={{ overflowY: 'auto !important', maxHeight: '90vh' }}
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}>
          <div>
            <Typography
              sx={{ fontWeight: 700, fontSize: '1.1rem' }}
              className="text-center">
              Resolution Impact
            </Typography>
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
            <div className="flex justify-center">
              <div className="break-words text-xs font-medium m-1 my-2">
                <Typography sx={{ fontSize: '0.8rem' }}>
                  Decide whether truthful resolution of this question would result
                  in positive or negative impact to the Outcome statement.
                </Typography>
              </div>
            </div>
            {!responseMessage && (
              <div>
                {outcome && (
                  <div className="break-words font-medium">
                    <Typography
                      sx={{ fontWeight: 400, py: 0.6 }}
                      color="primary.main">
                      Outcome:
                    </Typography>
                    <div className="border-2 rounded p-2 px-4">
                      <Typography sx={{ fontWeight: 'bold', py: 0.6 }}>
                        {outcome.title}
                      </Typography>
                      <Divider sx={{ my: 0.6 }} />
                      <Typography sx={{ fontWeight: 400, py: 0.6 }}>
                        {outcome.description}
                      </Typography>
                      {(outcome.statuses.includes('Generation') ||
                        outcome.statuses.includes('Evaluation')) && (
                        <div className="flex items-center mr-2">
                          <FiCalendar className="mr-1 mb-0.5" />
                          <Typography
                            sx={{
                              mr: 0.6,
                              fontSize: '0.875rem'
                            }}>{`Outcome Ends: ${moment
                            .utc(outcome.end_at)
                            .local()
                            .format('dddd, MMMM Do YYYY')}`}</Typography>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="break-words font-medium">
                  <Typography
                    sx={{ fontWeight: 400, py: 0.6 }}
                    color="primary.main">
                    Question:
                  </Typography>
                  <div className="border-2 rounded">
                    <SmallQuestionCard questionId={questionId} />
                  </div>
                </div>

                <div className="flex justify-center w-full mx-1 my-4">
                  <Button
                    sx={{
                      borderRadius: '0.5rem',
                      p: '0.125rem',
                      mx: 2,
                      overflow: 'hidden',
                      position: 'relative',
                      '&:hover': {
                        color: theme.palette.secondary.main
                      }
                    }}
                    onClick={() => setSelectedImpact('positive')}
                    className={`relative group bg-gradient-to-br from-lightGreen-500 to-green-500 group-hover:from-lightGreen-500 group-hover:to-green-500 ${
                      selectedImpact === 'positive'
                        ? 'ring-4 ring-lightGreen-300'
                        : ''
                    }`}>
                    <div
                      style={{
                        transitionTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
                        transitionDuration: '75ms',
                        borderRadius: '0.375rem',
                        transitionProperty: 'all',
                        backgroundImage:
                          selectedImpact === 'positive'
                            ? 'linear-gradient(to bottom right, from-lightGreen-500 to-green-500)'
                            : '',
                        backgroundColor:
                          selectedImpact === 'positive'
                            ? ''
                            : theme.palette.background.paper
                      }}
                      className={`relative px-2 py-2 flex items-center rounded-md group-hover:bg-opacity-0`}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          color: 'text.primary',
                          '&:hover': {
                            color:
                              selectedImpact === 'positive' ? '' : 'primary.main'
                          }
                        }}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            mr: 1
                          }}
                          className="font-sans uppercase">
                          Positive
                        </Typography>
                        <TrendingUp sx={{}} />
                      </Box>
                    </div>
                  </Button>

                  <Button
                    sx={{
                      borderRadius: '0.5rem',
                      p: '0.125rem',
                      mx: 2,
                      overflow: 'hidden',
                      position: 'relative',
                      '&:hover': {
                        color: theme.palette.secondary.main
                      }
                    }}
                    onClick={() => setSelectedImpact('negative')}
                    className={`relative group bg-gradient-to-br from-rose-500 to-red-500 group-hover:from-rose-500 group-hover:to-red-500 ${
                      selectedImpact === 'negative' ? 'ring-4 ring-rose-400' : ''
                    }`}>
                    <div
                      style={{
                        transitionTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
                        transitionDuration: '75ms',
                        borderRadius: '0.375rem',
                        transitionProperty: 'all',
                        backgroundImage:
                          selectedImpact === 'negative'
                            ? 'linear-gradient(to bottom right, from-rose-500 to-red-500)'
                            : '',
                        backgroundColor:
                          selectedImpact === 'negative'
                            ? ''
                            : theme.palette.background.paper
                      }}
                      className={`relative px-2 py-2 flex items-center rounded-md group-hover:bg-opacity-0`}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          color: 'text.primary',
                          '&:hover': {
                            color:
                              selectedImpact === 'negative' ? '' : 'primary.main'
                          }
                        }}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            mr: 1
                          }}
                          className="font-sans uppercase">
                          Negative
                        </Typography>
                        <TrendingDown sx={{}} />
                      </Box>
                    </div>
                  </Button>
                </div>

                <Collapse
                  in={selectedImpact !== ''}
                  sx={{ my: 1.8, textAlign: 'center' }}>
                  <Typography
                    sx={{ fontSize: '0.795rem' }}
                    className="text-xs font-medium">
                    {selectedImpact === 'positive' ? (
                      <span>
                        Truthful resolution will make the Outcome statement{' '}
                        <strong>more</strong> likely.
                      </span>
                    ) : selectedImpact === 'negative' ? (
                      <span>
                        Truthful resolution will make the Outcome statement{' '}
                        <strong>less</strong> likely.
                      </span>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Collapse>

                <div className="flex justify-end mt-2">
                  <div className="mx-2">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'gray',
                        ':hover': { backgroundColor: '#757575' }
                      }}
                      onClick={closeModal}>
                      Cancel
                    </Button>
                  </div>
                  <div className="mx-2">
                    <Button
                      variant="contained"
                      onClick={setResolutionImpactConfirm}>
                      Confirm
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {responseMessage && (
              <div className="my-2">
                {responseMessage && (
                  <Typography color="success.main" className="text-center">
                    {responseMessage}
                  </Typography>
                )}

                <div className="flex mt-4 justify-center">
                  <Button
                    onClick={closeModal}
                    variant="contained"
                    className="w-4/12">
                    Close
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Backdrop>
    </div>
  ) : null;
}
