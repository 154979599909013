import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../App';
import {
  fetchBucketConfigurations,
  selectConfigurationByName
} from '../../store/slices/bucketSlice';
import {
  addNewBucketUserForecast,
  fetchQuestionById,
  fetchUserForecastsByQuestion,
  selectCurrentUserForecastByQuestion
} from '../../store/slices/outcomeSlice';
import SuccessModal from '../modals/SuccessModal';
import UserForecastCommentModal from '../modals/UserForecastCommentModal';
import BucketButtons from './BucketButtons';

export default function FrugalForecastInput({ questionId, configurationName }) {
  const dispatch = useDispatch();
  const { userData } = useContext(AuthContext);
  const [forecastErrorMessage, setForecastErrorMessage] = useState('');
  const [predictionSuccess, setPredictionSuccess] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [showForecastCommentModal, setShowForecastCommentModal] =
    useState(false);
  const [bucket, setBucket] = useState();

  const configStatus = useSelector(
    (state) => state.buckets.configurations.status
  );

  const configuration = useSelector((state) =>
    selectConfigurationByName(state, configurationName)
  );

  useEffect(() => {
    if (configStatus === 'idle') {
      const auth_token = localStorage.getItem('auth_token');
      dispatch(fetchBucketConfigurations(auth_token));
    }
  }, [configStatus, dispatch]);

  const currentForecast = useSelector((state) =>
    selectCurrentUserForecastByQuestion(state, {
      userId: userData.id,
      questionId: questionId
    })
  );

  const submitBucket = (bucket) => {
    setShowForecastCommentModal(true);
    setBucket(bucket);
  };

  const submit = async (comment) => {
    const auth_token = localStorage.getItem('auth_token');
    try {
      setIsMounted(true);
      const payload = {
        bucketId: bucket.id,
        questionId,
        auth_token
      };

      if (comment !== '') {
        payload.comment = comment;
      }

      await dispatch(addNewBucketUserForecast(payload))
        .unwrap()
        .then((response) => {
          if (response.status === 'success') {
            setShowForecastCommentModal(false);
            setPredictionSuccess(true);
          } else {
            setForecastErrorMessage(response.message);
            setShowForecastCommentModal(false);
          }
          // clears local storage 'draft' when comment is submitted
          localStorage.removeItem(
            'forecastcomment:' + questionId + userData.username
          );
          // refreshes user stats and comments
          dispatch(
            fetchQuestionById({
              questionId: questionId,
              auth_token: auth_token
            })
          );
          dispatch(fetchUserForecastsByQuestion(questionId));
        });
    } catch (err) {
      setShowForecastCommentModal(false);
      setForecastErrorMessage(
        `An error occurred while submitting your forecast: ${err.message}`
      );
    } finally {
      setIsMounted(false);
    }
  };

  return (
    <>
      <div className="px-5 py-2 flex flex-col items-center">
        {forecastErrorMessage && (
          <p className="text-s m-2 text-red-600 py-2">{forecastErrorMessage}</p>
        )}
        <div className="flex justify-center items-center w-full">
          <div className="flex-1 text-center">
            <Typography variant="h6" sx={{ mb: 2 }}>
              Select a bucket that best reflects your current forecast:
            </Typography>
          </div>
        </div>
        {configuration && (
          <BucketButtons
            currentForecast={currentForecast}
            configuration={configuration}
            submit={submitBucket}
          />
        )}
      </div>

      {predictionSuccess && (
        <div>
          <SuccessModal
            shown={predictionSuccess}
            close={() => {
              setPredictionSuccess(false);
            }}
            successMessage={'Thanks for submitting your prediction!'}
          />
        </div>
      )}

      {showForecastCommentModal && (
        <UserForecastCommentModal
          shown={showForecastCommentModal}
          closeAndSubmit={(submittedComment) => {
            submit(submittedComment);
          }}
          cancel={() => {
            setShowForecastCommentModal(false);
          }}
          questionId={questionId}
          forecastErrorMessage={forecastErrorMessage}
          isMounted={isMounted}
        />
      )}
    </>
  );
}
