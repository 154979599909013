import { AutoFixHigh } from '@mui/icons-material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
  fetchOutcomeExplanations,
  generateOutcomeExplanation,
  selectOutcomeExplanationById,
  selectOutcomeExplanationsByOutcomeId,
  selectQuestionsByIDs
} from '../../../store/slices/outcomeSlice';
import MarkdownComponent from '../Markdown';
import QuestionTimelineCard from './QuestionTimelineCard';

export const OutcomeExplainer = ({ outcomeId, focussed }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isMounted, setIsMounted] = useState(false);
  const [isFetchMounted, setIsFetchMounted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [generateExplanationStatus, setGenerateExplanationStatus] =
    useState('idle');
  const [fetchExplanationStatus, setFetchExplanationStatus] = useState('idle');
  const [outcomeExplanationId, setOutcomeExplanationId] = useState('');
  const outcomeExplanations = useSelector((state) =>
    selectOutcomeExplanationsByOutcomeId(state, outcomeId)
  );
  const outcomeExplanation = useSelector((state) =>
    selectOutcomeExplanationById(state, outcomeExplanationId)
  );

  const changeSelectedOutcomeExplanation = (event) => {
    setOutcomeExplanationId(event.target.value);
  };

  const topPositiveQuestionIDs = outcomeExplanation
    ? outcomeExplanation.top_success_questions.map(
        (question) => question.question_id
      )
    : [];

  const topNegativeQuestionIDs = outcomeExplanation
    ? outcomeExplanation.top_failure_questions.map(
        (question) => question.question_id
      )
    : [];

  useEffect(() => {
    // Remove the nested function for clarity
    if (fetchExplanationStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      setFetchExplanationStatus('pending');
      dispatch(
        fetchOutcomeExplanations({
          outcomeId: outcomeId,
          auth_token: token
        })
      ).then((response) => {
        setFetchExplanationStatus('idle');
      });
    }

    // Remove the cleanup that was resetting the status
    return () => {
      // Only cleanup mount state if component unmounts
      setIsFetchMounted(false);
    };
  }, [dispatch, outcomeId]);

  const positiveQuestions = useSelector((state) =>
    selectQuestionsByIDs(state, topPositiveQuestionIDs)
  );

  const negativeQuestions = useSelector((state) =>
    selectQuestionsByIDs(state, topNegativeQuestionIDs)
  );

  const createOutcomeExplanation = async () => {
    if (generateExplanationStatus === 'idle') {
      setIsMounted(true);
      try {
        if (errorMessage) setErrorMessage('');
        setGenerateExplanationStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          outcomeId: outcomeId,
          auth_token: token
        };
        await dispatch(generateOutcomeExplanation(payload))
          .unwrap()
          .then((response) => {
            if (response.status === 'success') {
              setOutcomeExplanationId(response.data.id);
            }
          });
      } catch (err) {
        setErrorMessage(
          `Failed to generate outcome explanation: ${err.message}`
        );
      } finally {
        setGenerateExplanationStatus('idle');
        setIsMounted(false);
      }
    }
  };

  const renderQuestionGraphCard = (question_rationale, index) => {
    return (
      <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 4 }}>
        <QuestionTimelineCard
          question_id={question_rationale.question_id}
          rationale={question_rationale.rationale}
          index={index}
        />
      </Grid2>
    );
  };

  const renderQuestionRow = (question_rationale, index, positive) => {
    let question = positive
      ? positiveQuestions.find(
          (question) => question.id === question_rationale.question_id
        )
      : negativeQuestions.find(
          (question) => question.id === question_rationale.question_id
        );
    return (
      <TableRow key={index}>
        <TableCell>
          <Typography variant="subtitle2" component="h3">
            {index + 1}
          </Typography>
        </TableCell>
        <TableCell>
          <Tooltip
            title={
              <div>
                <div>{`Question: ${question?.question_text}`}</div>
                <div>{`Forecast: ${parseFloat(
                  (question?.current_crowd_forecast * 100).toFixed(1)
                )}%`}</div>
                <div>{`Relevance: ${question?.relevance_level}`}</div>
                <div>{`Impact: ${(question?.current_impact).toFixed(4)}`}</div>
              </div>
            }
            key={index}
            arrow
            slotProps={{
              tooltip: {
                sx: {
                  color: 'text.primary',
                  border: `1px solid ${(theme.vars || theme).palette.divider}`,
                  borderRadius: 1,
                  background: 'hsl(0, 0%, 100%)',
                  ...theme.applyStyles('dark', {
                    background: grey[900]
                  })
                }
              }
            }}>
            <div
              className="flex items-center gap-1 text-xs cursor-pointer"
              onClick={() =>
                window.open(`/questions/${question.id}`, '_blank')
              }>
              <Typography
                className="truncate"
                variant="subtitle2"
                component="h3">
                {question?.question_text}
              </Typography>
            </div>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" component="h3">
            {' '}
            {question_rationale.rationale}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" component="h3">
            {parseFloat((question?.current_crowd_forecast * 100).toFixed(1))}%
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className="w-full">
      <Box>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          <AutoFixHigh />
          AI Outcome Explainer
        </Typography>
        <Typography component="h2" variant="subtitle3" gutterBottom>
          Generate or view AI-powered explanations for this outcome
        </Typography>
      </Box>
      <div className="my-2">
        {fetchExplanationStatus === 'pending' ? (
          <ClipLoader loading={true} size={50} />
        ) : (
          outcomeExplanations?.length > 0 && (
            <div className="my-2">
              <div className="flex items-center gap-4">
                <InputLabel id="select-label" sx={{ mx: 1 }}>
                  Select explanation:
                </InputLabel>
                <Select
                  labelId="select-label"
                  id="sort-select"
                  value={outcomeExplanationId}
                  sx={{ minWidth: 200, my: 1 }}
                  onChange={changeSelectedOutcomeExplanation}>
                  {outcomeExplanations.map((outcomeExplanation) => (
                    <MenuItem
                      key={outcomeExplanation.id}
                      value={outcomeExplanation.id}>
                      {moment(outcomeExplanation.created_at).format(
                        'YYYY-MM-DD'
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {outcomeExplanation && (
                <Card
                  className="mt-4 shadow-md"
                  sx={{ maxHeight: '38rem', overflow: 'scroll' }}>
                  <CardContent className="space-y-6">
                    <div className="space-y-2">
                      <Typography
                        variant="subtitle2"
                        component="h3"
                        className="font-bold text-lg border-b pb-2">
                        Summary
                      </Typography>
                      <div className="markdown max-h-96 overflow-y-auto rounded-md p-4">
                        <Typography
                          sx={{
                            fontSize: '0.95rem',
                            lineHeight: '1.6'
                          }}
                          component="div">
                          <MarkdownComponent
                            description={outcomeExplanation.overall_summary}
                          />
                        </Typography>
                      </div>
                    </div>

                    {positiveQuestions && (
                      <div className="space-y-2">
                        <Typography
                          variant="subtitle2"
                          component="h3"
                          className="font-bold text-lg border-b pb-2">
                          Top Positive Factors
                        </Typography>
                        <Grid2
                          container
                          spaceing={2}
                          columns={12}
                          sx={{ mb: 1 }}>
                          {outcomeExplanation.top_success_questions.map(
                            (question_rationale, index) =>
                              renderQuestionGraphCard(question_rationale, index)
                          )}
                        </Grid2>
                        {/* <TableContainer className="border rounded-md">
                          <Table sx={{ tableLayout: 'fixed' }}>
                            <TableHead>
                              <TableRow>
                                <TableCell width="5%">#</TableCell>
                                <TableCell width="35%">Question</TableCell>
                                <TableCell width="50%">Rationale</TableCell>
                                <TableCell width="10%">Forecast</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {outcomeExplanation.top_success_questions.map(
                                (question_rationale, index) =>
                                  renderQuestionRow(
                                    question_rationale,
                                    index,
                                    true
                                  )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer> */}
                      </div>
                    )}

                    {negativeQuestions && (
                      <div className="space-y-2">
                        <Typography
                          variant="subtitle2"
                          component="h3"
                          className="font-bold text-lg border-b pb-2">
                          Top Negative Factors
                        </Typography>
                        <Grid2
                          container
                          spaceing={2}
                          columns={12}
                          sx={{ mb: 1 }}>
                          {outcomeExplanation.top_failure_questions.map(
                            (question_rationale, index) =>
                              renderQuestionGraphCard(question_rationale, index)
                          )}
                        </Grid2>
                        {/* <TableContainer className="border rounded-md">
                          <Table sx={{ tableLayout: 'fixed' }}>
                            <TableHead>
                              <TableRow>
                                <TableCell width="5%">#</TableCell>
                                <TableCell width="35%">Question</TableCell>
                                <TableCell width="50%">Rationale</TableCell>
                                <TableCell width="10%">Forecast</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {outcomeExplanation.top_failure_questions.map(
                                (question_rationale, index) =>
                                  renderQuestionRow(
                                    question_rationale,
                                    index,
                                    false
                                  )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer> */}
                      </div>
                    )}
                    <div>
                      <Typography
                        variant="subtitle2"
                        component="h3"
                        className="font-bold text-lg border-b pb-2">
                        Data Analysis
                      </Typography>
                      <div className="max-h-96 overflow-y-auto rounded-md p-4">
                        <Typography
                          sx={{
                            fontSize: '0.95rem',
                            lineHeight: '1.6'
                          }}
                          component="div">
                          {outcomeExplanation.data_analysis_summary}
                        </Typography>
                      </div>
                    </div>
                    <div>
                      <Typography
                        variant="subtitle2"
                        component="h3"
                        className="font-bold text-lg border-b pb-2">
                        AI Revised Likelihood
                      </Typography>
                      <div className="max-h-96 overflow-y-auto rounded-md p-4">
                        <Typography variant="h4" component="p">
                          {parseFloat(
                            (
                              outcomeExplanation.outcome_likelihood * 100
                            ).toFixed(1)
                          )}
                          %
                        </Typography>
                        <div className="flex gap-2">
                          <Typography
                            variant="subtitle2"
                            component="h3"
                            className="font-bold">
                            Rationale:
                          </Typography>
                          <Typography variant="subtitle2" component="h3">
                            {outcomeExplanation.outcome_likelihood_rationale}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>
          )
        )}
        <div className="flex justify-end">
          <Button
            variant="outlined"
            onClick={createOutcomeExplanation}
            disabled={isMounted || generateExplanationStatus === 'pending'}
            sx={{ px: isMounted ? 8 : 1, gap: 1 }}>
            {isMounted ? (
              <ClipLoader loading={true} size={50} />
            ) : (
              <>
                <AutoAwesomeIcon />
                Generate AI Outcome Explanation
              </>
            )}
          </Button>
        </div>
        {(isMounted || generateExplanationStatus === 'pending') && (
          <div className="flex justify-end">
            <Typography sx={{ fontSize: '0.8rem' }}>
              This can take up to 60 seconds
            </Typography>
          </div>
        )}
        <div className="flex justify-end">
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
        </div>
      </div>
    </div>
  );
};
