import React, { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../store/slices/userSlice';

import {
  Button,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Backdrop
} from '@mui/material';
import { AuthContext } from '../../App';

export default function ResetUserPasswordModal({
  shown,
  close,
  userId,
  username,
  role,
  email
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [newUsername, setUsername] = useState(username);
  const [newEmail, setEmail] = useState(email);
  const [newRole, setRole] = useState(
    role === 'Admin' ? 'administrator' : role.toLowerCase()
  );

  const changeUsername = (event) => setUsername(event.target.value);
  const changeEmail = (event) => setEmail(event.target.value);
  const changeRole = (event) => setRole(event.target.value);

  const handleSubmitChanges = async () => {
    let isMounted = true;
    setErrorMessage('');
    try {
      setIsLoggedIn(true);
      const token = localStorage.getItem('auth_token');
      const payload = {
        id: userId,
        auth_token: token,
        username: newUsername || username,
        email: newEmail || email,
        role: newRole || role
      };

      Object.keys(payload).forEach((key) => {
        if (
          payload[key] === username ||
          payload[key] === email ||
          payload[key] === role
        ) {
          delete payload[key];
        }
      });

      if (Object.keys(payload).length > 2) {
        await dispatch(updateUser(payload))
          .unwrap()
          .then((response) => {
            if (response.status === 'success') {
              setResponseMessage(`Successfully updated user details`);
            }
          });
      } else {
        setErrorMessage('No changes made');
      }
    } catch (err) {
      setErrorMessage(`Failed to update fields: ${err.message}`);
    } finally {
      if (isMounted) {
        isMounted = false;
      }
    }
  };

  const closeModal = () => {
    close();
  };

  useEffect(() => {
    if (shown) {
      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          close();
        }
      };
      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [shown, close]);

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Backdrop
        sx={{
          zIndex: 999,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={true}
        onClick={() => closeModal()}>
        <Card
          className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3 z-10"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div>
            <Typography
              variant="h5"
              sx={{ fontWeight: 600 }}
              className="text-center pb-3">
              Update User Details
            </Typography>

            <Typography variant="body2" sx={{ mb: 3 }} className="text-center">
              Here you can update the user's username, email address, and role
            </Typography>

            {!responseMessage && (
              <div>
                <div className="flex items-center w-full my-3">
                  <div className="w-full mx-2">
                    <Typography variant="body1">Username</Typography>
                    <TextField
                      name="username"
                      type="text"
                      variant="outlined"
                      value={newUsername}
                      onChange={changeUsername}
                      placeholder={username}
                      sx={{ my: 1.5 }}
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="flex items-center w-full my-3">
                  <div className="w-full mx-2">
                    <Typography variant="body1">Email Address</Typography>
                    <TextField
                      name="email"
                      type="text"
                      variant="outlined"
                      value={newEmail}
                      onChange={changeEmail}
                      placeholder={email}
                      sx={{ my: 1.5 }}
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="flex items-center w-full my-3">
                  <div className="w-full mx-2">
                    <Typography variant="body1">Role</Typography>
                    <div className="flex justify-center my-1 flex-wrap">
                      <RadioGroup
                        aria-labelledby="role-radio-buttons-group"
                        name="role-radio-buttons-group"
                        value={newRole}
                        onChange={changeRole}
                        row={true}
                        sx={{ display: 'flex', justifyContent: 'center' }}>
                        <FormControlLabel
                          value="questioner"
                          control={<Radio />}
                          label="Questioner"
                        />
                        <FormControlLabel
                          value="outcomeowner"
                          control={<Radio />}
                          label="Outcome Owner"
                        />
                        <FormControlLabel
                          value="moderator"
                          control={<Radio />}
                          label="Moderator"
                        />
                        <FormControlLabel
                          value="administrator"
                          control={<Radio />}
                          label="Administrator"
                        />
                        <FormControlLabel
                          value="observer"
                          control={<Radio />}
                          label="Observer"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>

                {errorMessage && (
                  <Typography color="error" sx={{ my: 2, textAlign: 'center' }}>
                    {errorMessage}
                  </Typography>
                )}

                <div className="flex mt-4 justify-center">
                  <Button
                    variant="contained"
                    sx={{ width: '100%' }}
                    onClick={handleSubmitChanges}>
                    Submit Changes
                  </Button>
                </div>

                <div className="flex mt-4 justify-center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'gray',
                      ':hover': { backgroundColor: '#757575' },
                      width: '100%'
                    }}
                    onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
              </div>
            )}

            {responseMessage && (
              <div>
                {responseMessage && (
                  <Typography
                    sx={{ my: 1.5, textAlign: 'center' }}
                    color="success.main">
                    {responseMessage}
                  </Typography>
                )}

                <div className="flex mt-4 justify-center">
                  <Button
                    variant="contained"
                    onClick={closeModal}
                    sx={{ width: '50%' }}>
                    Close
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Backdrop>
    </div>
  ) : null;
}
