import { darkScrollbar, tooltipClasses } from '@mui/material';
import { grey } from '@mui/material/colors';
import { chartsCustomizations } from './dashboard/chartsTheme';
import { dataGridCustomizations } from './dashboard/dataGridTheme';

export const getColourPalette = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: { main: '#378dcf' }, //'#41AEF2' }, //'#3281C0' }, //'#F5F5F2' }, //'#A5F1E9' }, // '#96ADC8' },
          secondary: { main: '#41f2dd' }, //'#2196f3' }, //'#D7FFAB' },
          hover: 'rgb(249 250 251)',
          navbar: '#ffffff',
          divider: '#34aff6',
          background: {
            default: '#f0f0f0',
            secondary: '#ebebeb',
            paper: '#ffffff'
          },
          text: {
            primary: 'rgba(0,0,0,0.87)',
            secondary: 'rgb(113 113 122)'
          },
          error: { main: '#B00020' },
          success: { main: '#66bb6a' },
          statuses: {
            mid1: '#3778bd',
            dark1: '#164e8a',
            mid2: '#E0D431',
            dark2: '#e5c827',
            mid3: '#8fbc6a',
            dark3: '#7bb14f',
            mid4: '#A576AE',
            dark4: '#925d9d',
            mid5: '#e36052',
            dark5: '#d84d39',
            mid6: '#c0aa30',
            dark6: '#ad8b00',
            mid7: '#7E4F4F',
            dark7: '#613b3c',
            mid8: '#504F7E',
            dark8: '#474572',
            mid9: '#ed842f',
            dark9: '#ED944D'
          },
          generation: { main: '#3778bd' },
          forecasting: { main: '#8fbc6a' }
        }
      : {
          // palette values for dark mode
          primary: { main: '#8bd7fa' }, //'#81D4FA' }, //'#466486' },
          secondary: { main: '#69CC00' },
          hover: '#524E4E',
          navbar: '#4d4949',
          divider: '#8bd7fa',
          background: {
            default: '#2c2a2a',
            secondary: '#474343',
            paper: '#5d5959' //'#4d4949'
          },
          text: {
            primary: '#ffffff',
            secondary: grey[500]
          },
          error: { main: '#CF6679' },
          success: { main: '#9ccc65' },
          statuses: {
            mid1: '#346aa3',
            dark1: '#1e4873',
            mid2: '#E0D431',
            dark2: '#e5c827',
            mid3: '#84c44f',
            dark3: '#68a13a',
            mid4: '#A576AE',
            dark4: '#925d9d',
            mid5: '#e36052',
            dark5: '#d84d39',
            mid6: '#c0aa30',
            dark6: '#ad8b00',
            mid7: '#7E4F4F',
            dark7: '#613b3c',
            mid8: '#504F7E',
            dark8: '#474572',
            mid9: '#ed842f',
            dark9: '#ED944D'
          },
          generation: { main: '#346aa3' },
          forecasting: { main: '#84c44f' }
        })
  },
  typography: {
    fontFamily: [
      'Fujitsu Infinity Pro',
      'IBM Plex Sans',
      'Arial',
      'Roboto'
    ].join(',')
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          [`.${tooltipClasses.popper}[data-popper-placement*="bottom"] &`]: {
            margin: '0.25rem 0'
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          ...darkScrollbar(
            mode === 'light'
              ? {
                  track: grey[200],
                  thumb: grey[400],
                  active: grey[400]
                }
              : undefined
          ),
          //scrollbarWidth for Firefox
          scrollbarWidth: 'thin'
        }
      }
    },
    ...chartsCustomizations,
    ...dataGridCustomizations
  }
});
