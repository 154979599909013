import { Button, Grid, Spacer, Textarea, useToasts } from '@geist-ui/react';
import { Box, Card, Divider, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import TableDragSelect from 'react-table-drag-select';
import 'react-table-drag-select/style.css';
import '../../App.css';
import defaultColorsState from './GridColours.json';
import InfoTooltip from './InfoTooltip';

export default function RelevanceGrid({
  jointOccuranceProbability,
  justQuestionProbability,
  justOutcomeProbability,
  neitherOutcomeQuestionProbability,
  updateProbabilities,
  relevanceLevel
}) {
  const gridWidth = 10;
  const gridHeight = 10;

  const [showUnusedColors, setShowUnusedColors] = React.useState(true);

  const [cellsSelected] = React.useState(
    [...Array(gridHeight)].map((e) => Array(gridWidth).fill(false))
  );

  const [colorPalette, setColorPalette] = React.useState(defaultColorsState);

  const [gridColours, setGridColours] = React.useState([
    {
      id: 0,
      hex: '#072F5F',
      percent: jointOccuranceProbability,
      title: 'Question and Outcome both Occur'
    },
    {
      id: 1,
      hex: '#1261A0',
      percent: justQuestionProbability,
      title: 'Question Occurs, Outcome Does Not'
    },
    {
      id: 2,
      hex: '#58CCED',
      percent: justOutcomeProbability,
      title: 'Outcome Occurs, Question Does Not'
    },
    {
      id: 3,
      hex: '#8B0000',
      percent: neitherOutcomeQuestionProbability,
      title: 'Neither Question nor Outcome Occur'
    }
  ]);

  const [selectedColorID, setSelectedColorID] = React.useState(0);
  const [selectedGridColors, setSelectedGridColors] = React.useState(
    [...Array(gridHeight)].map((e) => Array(gridWidth).fill(0))
  );

  const [freq, setFreq] = React.useState([
    ...Array(colorPalette.length).fill(0)
  ]);

  useEffect(() => {
    const initializeGridColors = () => {
      const baseGridColors = copyOf(selectedGridColors);
      let totalCells = gridWidth * gridHeight;
      let currentCell = 0;

      for (let i = 0; i < gridColours.length; i++) {
        let cellsForColor = Math.round(
          (gridColours[i].percent / 100) * totalCells
        );
        for (let j = 0; j < cellsForColor; j++) {
          let row = Math.floor(currentCell / gridWidth) % gridHeight;
          let col = currentCell % gridWidth;
          try {
            baseGridColors[row][col] = i;
          } catch (e) {
            console.log(e);
          }
          currentCell++;
        }
      }
      setSelectedGridColors(baseGridColors);
    };

    initializeGridColors();
  }, [gridColours]);

  const [gridHistory, setGridHistory] = React.useState([]);

  const copyOf = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  const selectCells = (cells) => {
    const currSelectedGridColors = copyOf(selectedGridColors);

    // Save current state to history.
    setGridHistory((gridHistory) => [
      ...gridHistory,
      copyOf(selectedGridColors)
    ]);

    cells.forEach((rows, rowIndex) => {
      rows.forEach((cell, cellIndex) => {
        if (cell) {
          currSelectedGridColors[rowIndex][cellIndex] = selectedColorID;
        }
      });
    });

    // Save new state of colors.
    setSelectedGridColors(currSelectedGridColors);

    let newJointOccurancePercent = 0;
    let newJustQuestionPercent = 0;
    let newJustOutcomePercent = 0;
    let newNeitherOutcomeQuestionPercent = 0;
    currSelectedGridColors.forEach((row, i) => {
      row.forEach((cell, j) => {
        switch (cell) {
          case 0:
            newJointOccurancePercent++;
            break;
          case 1:
            newJustQuestionPercent++;
            break;
          case 2:
            newJustOutcomePercent++;
            break;
          case 3:
            newNeitherOutcomeQuestionPercent++;
            break;
          default:
            break;
        }
      });
    });

    updateProbabilities({
      jointOccurrencePercent: newJointOccurancePercent,
      justQuestionPercent: newJustQuestionPercent,
      justOutcomePercent: newJustOutcomePercent
    });
  };

  const undo = () => {
    if (gridHistory.length > 0) {
      const lastGridState = gridHistory[gridHistory.length - 1];
      setSelectedGridColors(copyOf(lastGridState));
      setGridHistory(gridHistory.slice(0, gridHistory.length - 1));
    }
  };

  function KeyPress(e) {
    var evtobj = e;
    if (evtobj.keyCode === 90 && evtobj.ctrlKey) undo();
  }

  document.onkeydown = KeyPress;

  const changeColor = (id) => {
    setSelectedColorID(id);
  };

  const handleGridRightClick = (e) => {
    e.preventDefault();
    setSelectedGridColors((selectedGridColors) => {
      const id = e.target.id;
      if (id) {
        const [row, col] = id.split('-');
        if (
          row &&
          col &&
          row >= 0 &&
          col >= 0 &&
          row < gridHeight &&
          col < gridWidth
        ) {
          changeColor(selectedGridColors[row][col]);
        }
      }
      return selectedGridColors;
    });
  };

  useEffect(() => {
    // calculateProbabilities();

    const frequency = [...Array(colorPalette.length)].map((_) => 0);
    selectedGridColors.forEach((row, _) => {
      row.forEach((color, _) => {
        frequency[color]++;
      });
    });

    setFreq(frequency);
  }, [selectedGridColors]);

  useEffect(() => {
    var style = document.createElement('style');
    style.appendChild(document.createTextNode(''));
    document.head.appendChild(style);
    style.sheet.insertRule(
      '.table-drag-select td.cell-being-selected{border:  5px solid ' +
        gridColours[selectedColorID].hex +
        ';}',
      0
    );
  }, [selectedColorID]);

  useEffect(() => {
    selectedGridColors.forEach((row, i) => {
      row.forEach((cell, j) => {
        const element = window.document.getElementById(`${i}-${j}`);
        element.style.backgroundColor =
          gridColours[selectedGridColors[i][j]].hex;
      });
    });
    return;
  }, [selectedGridColors, gridColours]);

  useEffect(() => {
    changeColor(1);
  }, []);

  const [, setToast] = useToasts();

  useEffect(() => {
    setToast({
      text: "This is supposed to be a 10x10 grid. If it isn't, it's because your screen is too zoomed in.",
      type: 'error',
      actions: [
        {
          name: 'Got it!',
          passive: true,
          handler: (e, cancel) => cancel()
        }
      ]
    });
  }, []);

  useEffect(() => {
    setGridColours((prevGridColours) => {
      const newGridColours = [...prevGridColours];
      newGridColours[0].percent = jointOccuranceProbability;
      return newGridColours;
    });
  }, [jointOccuranceProbability]);

  useEffect(() => {
    setGridColours((prevGridColours) => {
      const newGridColours = [...prevGridColours];
      newGridColours[1].percent = justQuestionProbability;
      return newGridColours;
    });
  }, [justQuestionProbability]);

  useEffect(() => {
    setGridColours((prevGridColours) => {
      const newGridColours = [...prevGridColours];
      newGridColours[2].percent = justOutcomeProbability;
      return newGridColours;
    });
  }, [justOutcomeProbability]);

  useEffect(() => {
    setGridColours((prevGridColours) => {
      const newGridColours = [...prevGridColours];
      newGridColours[3].percent = neitherOutcomeQuestionProbability;
      return newGridColours;
    });
  }, [neitherOutcomeQuestionProbability]);

  const componentRef = React.useRef();

  return (
    <div>
      <Box>
        <Spacer h={1} />
        <div ref={componentRef}>
          <div className="flex-item2">
            <Grid>
              <Card sx={{ w: '100%', p: 1 }}>
                <div className="grid grid-cols-12 gap-2">
                  {gridColours.map(
                    (color, i) =>
                      (showUnusedColors ||
                        (!showUnusedColors && freq[i] !== 0)) && (
                        <div
                          key={color.id}
                          className="col-span-12 lg:col-span-6 grid gap-1 grid-cols-12 items-center">
                          <Typography className="col-span-2">
                            {color.percent}%
                          </Typography>
                          <div className="col-span-2">
                            <Button
                              scale={1}
                              auto
                              style={{
                                backgroundColor: color.hex,
                                border:
                                  selectedColorID === i ? '3px solid black' : ''
                              }}
                              onClick={() => changeColor(color.id)}
                            />
                          </div>
                          <Typography className="col-span-8">
                            {color.title}
                          </Typography>
                        </div>
                      )
                  )}
                </div>
              </Card>
            </Grid>
          </div>
          <Grid.Container>
            {' '}
            <div className="flex-item1">
              <Grid>
                <div>
                  <TableDragSelect
                    value={cellsSelected}
                    onChange={selectCells}
                    style={{ width: '41em', height: '20%' }}>
                    {[...Array(gridHeight)].map((_, i) => (
                      <tr key={i}>
                        {[...Array(gridWidth)].map((_, j) => (
                          <td
                            key={`${i}-${j}`}
                            id={`${i}-${j}`}
                            onContextMenu={handleGridRightClick}
                          />
                        ))}
                      </tr>
                    ))}
                  </TableDragSelect>
                </div>
              </Grid>
            </div>
            {/* <Spacer w={2} inline /> */}
          </Grid.Container>
        </div>
        <div className="flex">
          <Typography sx={{ fontSize: '1rem' }}>
            {`Relevance Level: ${relevanceLevel}`}
          </Typography>
          <div className="ml-1">
            <InfoTooltip
              text="The forecasts provided in the grid are used to calculate the Relevance Level between the Question and the
                      Outcome."
            />
          </div>
        </div>
      </Box>
    </div>
  );
}
