import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  isFulfilled
} from '@reduxjs/toolkit';
import { hivemindAPI } from '../HivemindAPI';

const notificationsAdapter = createEntityAdapter({
  sortComparer: (a, b) => sort_by_created(a, b)
});
const initialState = notificationsAdapter.getInitialState({
  status: 'idle',
  error: null
});

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (notificationsId) => {
    const response = await hivemindAPI.fetch(`/api/notifications`);
    return response.json();
  }
);

export const updateNotification = createAsyncThunk(
  'notifications/updateNotifications',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/notifications',
      {
        id: payload.id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const markNotificationAsSeen = createAsyncThunk(
  'notifications/markNotificationAsSeen',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/notifications/user',
      {
        notification_id: payload.notification_id,
        user_id: payload.user_id,
        seen: true
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchNotifications.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = 'succeeded';
        notificationsAdapter.addMany(state, action.payload.data);
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        const updateNotification = action.payload.data;
        notificationsAdapter.updateOne(state, {
          id: updateNotification.id,
          changes: updateNotification
        });
      })
      .addCase(markNotificationAsSeen.fulfilled, (state, action) => {
        const updateNotification = action.payload.data;
        notificationsAdapter.updateOne(state, {
          id: updateNotification.id,
          changes: updateNotification
        });
      });
    builder.addMatcher(
      (action) =>
        isFulfilled(action) && action.payload && action.payload.notifications,
      (state, action) => {
        if (
          action.payload.notifications &&
          action.payload.notifications.length > 0
        ) {
          notificationsAdapter.upsertMany(state, action.payload.notifications);
        }
      }
    );
  }
});

export default notificationsSlice.reducer;

export const {
  selectAll: selectAllNotifications,
  selectById: selectNotificationById,
  selectIds: selectNotificationsIds
} = notificationsAdapter.getSelectors((state) => state.notifications);

export const selectNotificationByName = createSelector(
  [selectAllNotifications, (state, name) => name],
  (notifications, name) =>
    notifications.find((notification) => notification.name === name)
);

export const selectUserNotifications = createSelector(
  [selectAllNotifications, (state) => state],
  (notifications) => {
    return notifications
      .filter((notification) => notification?.user)
      .sort((a, b) => b.created_at - a.created_at);
  }
);

export const selectUnseenUserNotifications = createSelector(
  [selectAllNotifications, (state) => state],
  (notifications) => {
    return notifications.filter(
      (notification) => notification?.seen === 'False'
    );
  }
);

function sort_by_created(a, b) {
  let a_val = a.created_at;
  let b_val = b.created_at;
  return b_val.localeCompare(a_val);
}
