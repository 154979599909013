import { Radio, FormControl, FormControlLabel, RadioGroup, FormLabel, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSettingById, updateSetting } from '../../store/slices/settingsSlice';
import ConfirmationModal from '../modals/ConfirmationModal';
import InfoTooltip from './InfoTooltip';

export default function SettingRadioButtons({ id }) {
  const dispatch = useDispatch();
  const setting = useSelector((state) => selectSettingById(state, id));
  
  const [selectedValue, setSelectedValue] = useState('None');
  const [showChangeConfirm, setShowChangeConfirm] = useState(false);

  useEffect(() => {
    if (setting) {
      const selectedOption = setting.options?.find(option => option.selected)?.name || 'None';
      setSelectedValue(selectedOption);
    }
  }, [setting]);

  const handleProviderChange = (event) => {
    setSelectedValue(event.target.value);
    setShowChangeConfirm(true);
  };

  const handleConfirm = () => {
    setShowChangeConfirm(false);
    dispatch(
      updateSetting({
        id: setting.id,
        toggle_options: selectedValue,
        auth_token: localStorage.getItem('auth_token')
      })
    );
  };

  if (!setting) return null;

  return (
    <div>
      {showChangeConfirm && (
        <ConfirmationModal
          shown={showChangeConfirm}
          close={() => setShowChangeConfirm(false)}
          confirm={handleConfirm}
          confirmationMessage="Are you sure you want to change the auto question provider setting? This will modify the provider for all users."
        />
      )}
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="flex items-center justify-end mr-1">
          <FormLabel sx={{ fontSize: '0.8rem', color: 'text.primary' }}>
            {setting.name}
          </FormLabel>
          <div className="ml-1 pb-1 text-sm">
            <InfoTooltip text={setting.description} />
          </div>
        </div>
        <div className="flex items-center justify-start">
          <FormControl key={setting.id} component="fieldset">
            <RadioGroup
              aria-label={setting.name}
              name={setting.name}
              value={selectedValue}
              onChange={handleProviderChange}
            >
              <FormControlLabel
                value="None"
                control={<Radio sx={{ transform: 'scale(0.8)' }} />}
                label={<Typography sx={{ fontSize: '0.8rem' }}>None</Typography>}
              />
              <FormControlLabel
                value="OpenAI"
                control={<Radio sx={{ transform: 'scale(0.8)' }} />}
                label={<Typography sx={{ fontSize: '0.8rem' }}>OpenAI</Typography>}
              />
              <FormControlLabel
                value="ChatSonic"
                control={<Radio sx={{ transform: 'scale(0.8)' }} />}
                label={<Typography sx={{ fontSize: '0.8rem' }}>ChatSonic</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
