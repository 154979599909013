import loadable from '@loadable/component';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, Collapse, Divider, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import { AuthContext } from '../../App';
import {
  selectEvaluationByQuestionUser,
  selectInactiveRejectionCommentIdsByQuestion,
  selectOutcomeById,
  selectRejectionCommentIdsByQuestion,
  selectStandardCommentIdsByQuestion,
  selectStandardCommentIdsByStrategy
} from '../../store/slices/outcomeSlice';
import RejectionCommentCard from '../cards/RejectionCommentCard';

export default function CommentList({
  question = null,
  strategy = null,
  maxIndentLevels
}) {
  const { userData } = useContext(AuthContext);
  const userId = userData.id;
  var questionId = -1;
  var strategyId = -1;
  questionId = question?.id;
  strategyId = strategy?.id;

  const orderCommentIds = useSelector((state) =>
    selectStandardCommentIdsByQuestion(state, questionId)
  );
  const strategyOrderCommentIds = useSelector((state) =>
    selectStandardCommentIdsByStrategy(state, strategyId)
  );
  const evaluation = useSelector((state) =>
    selectEvaluationByQuestionUser(state, questionId, userId)
  );
  const outcome = useSelector((state) =>
    selectOutcomeById(
      state,
      question ? question.outcome_id : strategy.outcome_id
    )
  );
  const rejectionComments = useSelector((state) =>
    selectRejectionCommentIdsByQuestion(state, questionId)
  );
  const inactiveRejectionComments = useSelector((state) =>
    selectInactiveRejectionCommentIdsByQuestion(state, questionId)
  );
  const [extraComments, setExtraComments] = useState(false);
  const [commentsCollapsed, setCommentsCollapsed] = useState(true);
  const [rejectionCommentsCollapsed, setRejectionCommentsCollapsed] =
    useState(false);

  const showExtraComments = () => {
    setExtraComments(true);
  };

  const changeCommentsCollapsed = () => {
    setCommentsCollapsed(!commentsCollapsed);
  };

  const changeRejectionCommentsCollapsed = () => {
    setRejectionCommentsCollapsed(!rejectionCommentsCollapsed);
  };

  const LoadableCommentCard = loadable(() => import('../cards/CommentCard'), {
    fallback: <ClipLoader color="#f87171" loading={true} size={30} />
  });

  let rejectionCommentList;
  rejectionCommentList = rejectionComments.map((rejectionCommentId) => (
    <RejectionCommentCard
      key={rejectionCommentId}
      questionId={questionId}
      commentId={rejectionCommentId}
      maxIndentLevels={maxIndentLevels}
      indentLevel={1}
    />
  ));

  let inactiveRejectionCommentList;
  inactiveRejectionCommentList = inactiveRejectionComments.map(
    (inactiveRejectionCommentId) => (
      <RejectionCommentCard
        key={inactiveRejectionCommentId}
        questionId={questionId}
        commentId={inactiveRejectionCommentId}
        maxIndentLevels={maxIndentLevels}
        indentLevel={1}
      />
    )
  );

  let content;
  if (questionId)
    content = orderCommentIds.map((commentId, index) => (
      <div key={index}>
        {index < 2 && (
          <LoadableCommentCard
            questionId={questionId}
            commentId={commentId}
            maxIndentLevels={maxIndentLevels}
            indentLevel={1}
          />
        )}
        <Collapse className="w-full" in={extraComments && index >= 2}>
          <LoadableCommentCard
            questionId={questionId}
            commentId={commentId}
            maxIndentLevels={maxIndentLevels}
            indentLevel={1}
          />
        </Collapse>
      </div>
    ));
  if (strategyId)
    content = strategyOrderCommentIds.map((commentId, index) => (
      <div key={index}>
        {index < 2 && (
          <LoadableCommentCard
            strategyId={strategyId}
            commentId={commentId}
            maxIndentLevels={maxIndentLevels}
            indentLevel={1}
          />
        )}
        <Collapse className="w-full" in={extraComments && index >= 2}>
          <LoadableCommentCard
            strategyId={strategyId}
            commentId={commentId}
            maxIndentLevels={maxIndentLevels}
            indentLevel={1}
          />
        </Collapse>
      </div>
    ));
  return (
    <div>
      {rejectionCommentList.length > 0 &&
        outcome &&
        (!outcome.statuses.includes('Generation') ||
          question?.created_by.id === userId ||
          strategy?.created_by.id === userId ||
          outcome.created_by.username === userData.username ||
          userData.role === 'Admin' ||
          userData.role === 'Moderator') && (
          <div>
            <div className="ml-20 mr-10">
              <div className="flex justify-between items-center">
                <Typography sx={{ mx: 0.6 }} className="flex-shrink">
                  Rejection Comments
                </Typography>
                <Button
                  sx={{ px: 1, minWidth: 0 }}
                  onClick={changeRejectionCommentsCollapsed}>
                  {rejectionCommentsCollapsed ? <ExpandMore /> : <ExpandLess />}
                </Button>
              </div>
              <Divider sx={{ mb: 1.8 }} className="w-full"></Divider>
            </div>
            <Collapse in={!rejectionCommentsCollapsed}>
              <section className="rejection-comment-list">
                {rejectionCommentList}
              </section>
            </Collapse>
          </div>
        )}
      {inactiveRejectionCommentList.length > 0 &&
        (userData.role === 'Admin' || userData.role === 'Moderator') && (
          <div>
            <p className="ml-20 mr-10 border-b border-gray-400 mb-1 text-sm">
              Previous Rejection Comments
            </p>
            <section className="previous-rejection-comment-list">
              {inactiveRejectionCommentList}
            </section>
          </div>
        )}

      <Collapse
        className="w-full"
        in={
          (outcome?.forecast_mechanism === 'derived' ||
            evaluation ||
            question?.created_by.id === userId ||
            strategy?.created_by.id === userId ||
            userData?.role === 'Admin' ||
            userData?.role === 'Moderator' ||
            question?.status === 'Submitted' ||
            question?.status === 'Closed' ||
            question?.status === 'Completed' ||
            outcome?.statuses.includes('Forecasting') ||
            outcome?.statuses.includes('Closed')) &&
          content.length > 0
        }>
        <div>
          <div className="ml-20 mr-10">
            <div className="flex justify-between items-center">
              <Typography sx={{ mx: 0.6 }} className="flex-shrink">
                Comments
              </Typography>
              <Button
                sx={{ px: 1, minWidth: 0 }}
                onClick={changeCommentsCollapsed}>
                {commentsCollapsed ? (
                  <ExpandMore size="small" />
                ) : (
                  <ExpandLess size="small" />
                )}
              </Button>
            </div>
            <Divider sx={{ mb: 1.8 }} className="w-full"></Divider>
          </div>
          <Collapse className="w-full" in={!commentsCollapsed}>
            <section className="comment-list">
              {content}
              <Collapse
                className="w-full"
                in={orderCommentIds.length > 2 && !extraComments}>
                <Button
                  variant="text"
                  size="small"
                  sx={{ ml: 12, pl: 1.2, mb: 1.2 }}
                  onClick={showExtraComments}>
                  View {orderCommentIds.length - 2} more comment
                  {orderCommentIds.length > 3 ? 's' : ''}
                </Button>
              </Collapse>
            </section>
          </Collapse>
        </div>
      </Collapse>
    </div>
  );
}
