import { useTheme } from '@emotion/react';
import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { fetchBuckets, selectBucketById } from '../../store/slices/bucketSlice';

const darkColours = [
  '#f54e49', // Red
  '#ff8e6f', // Red-Orange
  '#f9c986', // Orange
  '#ffa826', // Orange-Yellow
  '#ffd662', // Yellow-Orange
  '#dbc503', // Yellow
  '#b4d92a', // Yellow-Green
  '#91d926', // Light Green
  '#4ad155', // Greenish
  '#26d926' // Green
];

const lightColours = [
  '#eb706e', // Red
  '#ff754f', // Red-Orange
  '#e16d1e', // Orange
  '#FF9900', // Orange-Yellow
  '#fec237', // Yellow-Orange
  '#f8f731', // Yellow
  '#cae56d', // Yellow-Green
  '#b6e674', // Light Green
  '#a0e674', // Greenish
  '#5be050' // Green
];

export default function BucketButtons({
  currentForecast,
  configuration,
  submit
}) {
  const dispatch = useDispatch();
  const setBucketId = currentForecast?.probability?.bucket?.id;
  const setBucket = useSelector((state) =>
    selectBucketById(state, setBucketId)
  );
  const bucketStatus = useSelector((state) => state.buckets.buckets.status);
  const [selectedBucket, setSelectedBucket] = useState(setBucket);
  const [isMounted, setIsMounted] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (!setBucket && bucketStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchBuckets(token));
    }
  }, [setBucketId, setBucket, bucketStatus]);

  function calculateColour(bucket) {
    const colours = theme.palette.mode === 'dark' ? darkColours : lightColours;
    const distanceTo50 = Math.abs(50 - bucket.mid_point * 100) / 50;
    const index = Math.round((1 - distanceTo50) * (colours.length - 1));
    return colours[index];
  }

  const handleBucketSelection = (event, newBucket) => {
    setSelectedBucket(newBucket);
  };

  return (
    <div className="w-full">
      <ToggleButtonGroup
        value={selectedBucket}
        exclusive
        onChange={handleBucketSelection}
        fullWidth
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        aria-label="bucket selection">
        {configuration &&
          configuration.buckets.map((bucket) => (
            <ToggleButton
              key={bucket.name}
              value={bucket}
              selected={selectedBucket === bucket.name}
              style={{
                width: `${(bucket.upper_limit - bucket.lower_limit) * 100}%`,
                minWidth: '80px',
                minHeight: '80px',
                marginTop: 5,
                marginRight: 10,
                borderRadius: 6,
                backgroundColor: calculateColour(bucket),
                border:
                  selectedBucket?.name === bucket.name
                    ? '2px solid black'
                    : 'none'
              }}
              sx={{
                color: 'text.primary',
                boxShadow: 3
              }}>
              {bucket.name}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
      {selectedBucket && (
        <div className="flex flex-col items-end justify-end">
          <Typography
            sx={{
              fontWeight: 400,
              textAlign: 'center',
              p: 1,
              mr: 1,
              fontSize: '1.1rem'
            }}>
            {`You have selected: ${Math.round(
              selectedBucket.lower_limit * 100
            )} - ${Math.round(selectedBucket.upper_limit * 100)}%`}
          </Typography>

          <Button
            variant="contained"
            disabled={isMounted}
            onClick={() => {
              try {
                submit(selectedBucket);
              } finally {
                setIsMounted(false);
              }
            }}
            sx={{ mr: 3 }}>
            {isMounted ? (
              <ClipLoader color="#ffffff" loading={true} size={50} />
            ) : !currentForecast ? (
              'Submit'
            ) : (
              'Update'
            )}
          </Button>
        </div>
      )}
    </div>
  );
}
