import {
  Button,
  MenuItem,
  Slider,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO, set } from 'date-fns';
import ukLocale from 'date-fns/locale/en-GB';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import useWindowDimensions from '../../useWindowDimensions.jsx';

import MDEditor from '@uiw/react-md-editor';
import { AuthContext, checkTokenStatus } from '../../../App';
import {
  addNewStrategy,
  fetchOutcomes,
  fetchStrategiesByOutcome,
  selectOutcomeById,
  updateStrategy
} from '../../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../../store/slices/settingsSlice';
import InfoTooltip from '../InfoTooltip.jsx';
import AutocompleteSource from '../StructuredQuestionForm/AutocompleteSource';

export default function StrategyForm({
  outcomeId,
  setEditCard = null,
  saveClicked = null,
  setSaveClicked = null,
  strategy = null
}) {
  const dispatch = useDispatch();
  const isDarkMode = localStorage.getItem('mode') === 'dark';
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const userId = userData.id;
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );

  const strategyId = strategy?.id;

  const maxStrategyLength = 4000;
  const minStrategyLength = 6;
  const maxExtraInfoLength = 4000;
  const outcome = useSelector((state) =>
    selectOutcomeById(state, strategy ? strategy.outcome_id : outcomeId)
  );
  const outcomeStatus = useSelector(
    (_state) => _state.outcomes.outcomes.status
  );

  var localStrategyObj = JSON.parse(
    localStorage.getItem(
      'strategy:' + outcome.id.toString() + userData.username
    )
  );
  if (localStrategyObj === null) {
    localStrategyObj = {};
  }

  const [strategyText, setStrategyText] = useState(
    localStrategyObj.strategyText ? localStrategyObj.strategyText : ''
  );
  const [strategyDatePreposition, setStrategyDatePreposition] = useState(
    localStrategyObj.strategyDatePreposition
      ? localStrategyObj.strategyDatePreposition
      : ''
  );
  const [strategyDate, setStrategyDate] = useState(
    localStrategyObj.strategyDate
      ? parseISO(localStrategyObj.strategyDate)
      : null
  );
  const [strategyDateRange, setStrategyDateRange] = useState(
    localStrategyObj.strategyDateRange
      ? [
          parseISO(localStrategyObj.strategyDateRange[0]),
          parseISO(localStrategyObj.strategyDateRange[1])
        ]
      : [null, null]
  );
  const [resolutionSource, setResolutionSource] = useState(
    localStrategyObj.strategyResolutionSource
      ? localStrategyObj.strategyResolutionSource
      : ''
  );
  const [linkAuthority, setLinkAuthority] = useState(
    localStrategyObj.strategyLinkAuthority
      ? localStrategyObj.strategyLinkAuthority
      : ''
  );
  const [sourceDatePreposition, setSourceDatePreposition] = useState(
    localStrategyObj.strategySourceDatePreposition
      ? localStrategyObj.strategySourceDatePreposition
      : ''
  );
  const [resolutionDate, setResolutionDate] = useState(
    localStrategyObj.strategyResolutionDate
      ? parseISO(localStrategyObj.strategyResolutionDate)
      : null
  );
  const [resolutionDateRange, setResolutionDateRange] = useState(
    localStrategyObj.strategyResolutionDateRange
      ? [
          parseISO(localStrategyObj.strategyResolutionDateRange[0]),
          parseISO(localStrategyObj.strategyResolutionDateRange[1])
        ]
      : [null, null]
  );
  const [extraInfo, setExtraInfo] = useState(
    localStrategyObj.strategyExtraInfo ? localStrategyObj.strategyExtraInfo : ''
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [updateStrategyRequestStatus, setUpdateStrategyRequestStatus] =
    useState('idle');
  const [createStrategyRequestStatus, setCreateStrategyRequestStatus] =
    useState('idle');
  const [likelihoodPrediction, setLikelihoodPrediction] = useState(
    localStrategyObj.likelihoodPrediction
      ? localStrategyObj.likelihoodPrediction
      : ''
  );
  const [isMounted, setIsMounted] = useState(false);

  const datePreposition = ['on or before', 'before', 'on', 'in', 'between'];
  const sourceValues = ['the Outcome Owner'];
  const dateRangePrepositions = ['in', 'between'];
  const lastAllowedDate = !outcome.continue_forecasting
    ? parseISO(outcome.end_at)
    : null;

  const changeStrategyText = (value) => {
    updateLocalStorage('strategyText', value);
    setStrategyText(value);
  };
  const changeStrategyDatePreposition = (event) => {
    updateLocalStorage('strategyDatePreposition', event.target.value);
    setStrategyDatePreposition(event.target.value);
  };
  const changeStrategyDate = (newValue) => {
    updateLocalStorage('strategyDate', newValue);
    setStrategyDate(newValue);
  };
  const changeStrategyDateRange = (newRange) => {
    updateLocalStorage('strategyDateRange', newRange);
    setStrategyDateRange(newRange);
  };
  const changeResolutionSource = (newValue) => {
    updateLocalStorage('strategyResolutionSource', newValue);
    setResolutionSource(newValue);
  };
  const changeLinkAuthority = (event) => {
    updateLocalStorage('strategyLinkAuthority', event.target.value);
    setLinkAuthority(event.target.value);
  };
  const changeSourceDatePreposition = (event) => {
    updateLocalStorage('strategySourceDatePreposition', event.target.value);
    setSourceDatePreposition(event.target.value);
  };
  const changeResolutionDate = (newValue) => {
    updateLocalStorage('strategyResolutionDate', newValue);
    setResolutionDate(newValue);
  };
  const changeResolutionDateRange = (newRange) => {
    updateLocalStorage('strategyResolutionDateRange', newRange);
    setResolutionDateRange(newRange);
  };
  const changeExtraInfo = (event) => {
    updateLocalStorage('strategyExtraInfo', event.target.value);
    setExtraInfo(event.target.value);
  };
  const handleSliderLikelihoodPrediction = (event, newValue) => {
    setLikelihoodPrediction(newValue);
    updateLocalStorage('likelihoodPrediction', newValue);
  };

  const handleInputLikelihoodPrediction = (event) => {
    const inputValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setLikelihoodPrediction(inputValue);
    updateLocalStorage('likelihoodPrediction', inputValue);
  };

  const handleLikelihoodPredictionBlur = () => {
    if (likelihoodPrediction < 0) {
      setLikelihoodPrediction(0);
    } else if (likelihoodPrediction > 100) {
      setLikelihoodPrediction(100);
    }
  };

  const sliderMarks = () => {
    let marks = [];
    marks.push({ value: 100, label: `100%` });
    if (width > 700) {
      var increment = 10;
    } else if (width > 500) {
      var increment = 20;
    } else if (width > 450) {
      var increment = 25;
    } else {
      var increment = 50;
    }
    for (let i = 0; i < 100 / increment; i++) {
      let value = increment * i;
      marks.push({ value: value, label: `${value}%` });
    }
    return marks;
  };

  const updateLocalStorage = (field, value) => {
    // outcome id + username is the composite unique field for items saved in local storage (this makes drafts unique per outcome per user)
    // object is saved in stringified format so we parse it after fetching
    var localStrategyObj = JSON.parse(
      localStorage.getItem(
        'strategy:' + outcome.id.toString() + userData.username
      )
    );
    if (localStrategyObj === null) {
      localStrategyObj = {};
    }
    // update strategy object's specified field
    localStrategyObj[field] = value;
    // save updated object in local storage (stringified format)
    localStorage.setItem(
      'strategy:' + outcome.id.toString() + userData.username,
      JSON.stringify(localStrategyObj)
    );
  };

  useEffect(() => {
    if (strategy) {
      setStrategyText(strategy.strategy);
      setStrategyDatePreposition(strategy.strategy_date_preposition);

      if (strategy.strategy_date.start) {
        setStrategyDateRange([
          moment(strategy.strategy_date.start).toDate(),
          moment(strategy.strategy_date.end).toDate()
        ]);
      } else {
        setStrategyDate(moment(strategy.strategy_date.end).toDate());
      }

      setResolutionSource(strategy.resolution_source);
      if (strategy.link_authority) setLinkAuthority(strategy.link_authority);
      setSourceDatePreposition(strategy.source_date_preposition);

      if (strategy.resolution_date.start) {
        setResolutionDateRange([
          moment(strategy.resolution_date.start).toDate(),
          moment(strategy.resolution_date.end).toDate()
        ]);
      } else {
        setResolutionDate(moment(strategy.resolution_date.end).toDate());
      }
      if (strategy.extra_info) setExtraInfo(strategy.extra_info);
    }
  }, [strategy]);

  useEffect(() => {
    if (saveClicked === true) {
      submitStrategyEdit();
    }
  }, [saveClicked]);

  useEffect(() => {
    // check if auth token is valid
    if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn]);

  useEffect(() => {
    let isMounted = true;
    setErrorMessage('');
    function fetchOutcomeData() {
      if (isMounted) {
        if (outcomeStatus === 'idle') {
          const token = localStorage.getItem('auth_token');
          dispatch(fetchOutcomes({ auth_token: token }));
        }
      }
    }
    fetchOutcomeData();
    return () => {
      isMounted = false;
    };
  }, [outcomeStatus, dispatch, outcome]);

  const canUpdateStrategy =
    [
      strategy?.id,
      strategyText,
      strategyText.length <= maxStrategyLength,
      strategyText.length > minStrategyLength,
      strategyDatePreposition,
      resolutionSource,
      extraInfo.length <= maxExtraInfoLength,
      checkTokenStatus()
    ].every(Boolean) &&
    (dateRangePrepositions.indexOf(strategyDatePreposition) === -1
      ? strategyDate
      : strategyDateRange[0] && strategyDateRange[1]) &&
    (dateRangePrepositions.indexOf(sourceDatePreposition) === -1
      ? resolutionDate
      : resolutionDateRange[0] && resolutionDateRange[1]) &&
    updateStrategyRequestStatus === 'idle';

  const canCreateStrategy =
    [
      outcome.id,
      strategyText,
      strategyText.length <= maxStrategyLength,
      strategyText.length > minStrategyLength,
      strategyDatePreposition,
      resolutionSource,
      sourceDatePreposition,
      extraInfo.length <= maxExtraInfoLength,
      outcome.created_by.username === userData.username
        ? likelihoodPrediction
        : true,
      checkTokenStatus()
    ].every(Boolean) &&
    (dateRangePrepositions.indexOf(strategyDatePreposition) === -1
      ? strategyDate
      : strategyDateRange[0] && strategyDateRange[1]) &&
    (dateRangePrepositions.indexOf(sourceDatePreposition) === -1
      ? resolutionDate
      : resolutionDateRange[0] && resolutionDateRange[1]) &&
    createStrategyRequestStatus === 'idle';

  const submitStrategyEdit = async () => {
    if (canUpdateStrategy) {
      try {
        setIsMounted(true);
        setUpdateStrategyRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: strategyId,
          strategy: strategyText,
          strategy_date_preposition: strategyDatePreposition,
          strategy_date_start:
            dateRangePrepositions.indexOf(strategyDatePreposition) !== -1
              ? moment(strategyDateRange[0]).format('YYYY-MM-DD')
              : null,
          strategy_date_end:
            dateRangePrepositions.indexOf(strategyDatePreposition) !== -1
              ? moment(strategyDateRange[1]).format('YYYY-MM-DD')
              : moment(strategyDate).format('YYYY-MM-DD'),
          resolution_source: resolutionSource,
          link_authority: linkAuthority,
          source_date_preposition: sourceDatePreposition,
          resolution_date_start:
            dateRangePrepositions.indexOf(sourceDatePreposition) !== -1
              ? moment(resolutionDateRange[0]).format('YYYY-MM-DD')
              : null,
          resolution_date_end:
            dateRangePrepositions.indexOf(sourceDatePreposition) !== -1
              ? moment(resolutionDateRange[1]).format('YYYY-MM-DD')
              : moment(resolutionDate).format('YYYY-MM-DD'),
          extra_info: extraInfo,

          outcome_id: outcome.id,
          auth_token: token
        };
        await dispatch(updateStrategy(payload))
          .unwrap()
          .then((response) => {
            // clears local storage 'draft' when strategy is submitted
            localStorage.removeItem(
              'strategy:' + outcome.id.toString() + userData.username
            );
            setSaveClicked(false);
            if (response.status === 'success') {
              setEditCard(false);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to update strategy: ${err.message}`);
      } finally {
        if (isMounted) setUpdateStrategyRequestStatus('idle');
        setIsMounted(false);
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (!strategy.id) {
        setErrorMessage(
          'No strategy id could be found. Please reload the page.'
        );
      } else if (!strategyText) {
        setErrorMessage('Please enter your strategy text description.');
      } else if (strategyText.length > maxStrategyLength) {
        setErrorMessage(
          `Strategy text must be less than ${maxStrategyLength} characters.`
        );
      } else if (strategyText.length <= minStrategyLength) {
        setErrorMessage('Strategy text must be at least 6 characters.');
      } else if (!strategyDatePreposition) {
        setErrorMessage('Please enter a strategy date preposition.');
      } else if (
        dateRangePrepositions.indexOf(strategyDatePreposition) === -1 &&
        !strategyDate
      ) {
        setErrorMessage('Please provide the strategy date.');
      } else if (
        dateRangePrepositions.indexOf(strategyDatePreposition) !== -1 &&
        (!strategyDateRange[0] || !strategyDateRange[1])
      ) {
        setErrorMessage('Please provide the strategy date range.');
      } else if (!resolutionSource) {
        setErrorMessage('Please enter a resolution source.');
      } else if (!sourceDatePreposition) {
        setErrorMessage('Please enter a source date preposition.');
      } else if (
        dateRangePrepositions.indexOf(sourceDatePreposition) === -1 &&
        !resolutionDate
      ) {
        setErrorMessage('Please provide the resolution date.');
      } else if (
        dateRangePrepositions.indexOf(sourceDatePreposition) !== -1 &&
        (!resolutionDateRange[0] || !resolutionDateRange[1])
      ) {
        setErrorMessage('Please provide the resolution date range.');
      } else {
        setErrorMessage('Strategy could not be submitted.');
      }
    }
  };

  const submitStrategy = async () => {
    if (canCreateStrategy) {
      setIsMounted(true);
      try {
        setCreateStrategyRequestStatus('pending');
        const floatProbability = (
          parseFloat(likelihoodPrediction) / 100
        ).toFixed(3);
        const token = localStorage.getItem('auth_token');
        let payload = {
          strategy: strategyText,
          strategy_date_preposition: strategyDatePreposition,
          strategy_date_start:
            dateRangePrepositions.indexOf(strategyDatePreposition) !== -1
              ? moment(strategyDateRange[0]).format('YYYY-MM-DD')
              : null,
          strategy_date_end:
            dateRangePrepositions.indexOf(strategyDatePreposition) !== -1
              ? moment(strategyDateRange[1]).format('YYYY-MM-DD')
              : moment(strategyDate).format('YYYY-MM-DD'),
          resolution_source: resolutionSource,
          link_authority: linkAuthority,
          source_date_preposition: sourceDatePreposition,
          resolution_date_start:
            dateRangePrepositions.indexOf(sourceDatePreposition) !== -1
              ? moment(resolutionDateRange[0]).format('YYYY-MM-DD')
              : null,
          resolution_date_end:
            dateRangePrepositions.indexOf(sourceDatePreposition) !== -1
              ? moment(resolutionDateRange[1]).format('YYYY-MM-DD')
              : moment(resolutionDate).format('YYYY-MM-DD'),
          extra_info: extraInfo,
          likelihood_prediction: floatProbability.toString(),

          outcome_id: outcome.id,
          auth_token: token
        };
        await dispatch(addNewStrategy(payload))
          .unwrap()
          .then((response) => {
            if (response.status === 'success') {
              // clears local storage 'draft' when strategy is submitted
              localStorage.removeItem(
                'strategy:' + outcome.id.toString() + userData.username
              );
              if (isMounted) setStrategyText('');
              if (isMounted) setStrategyDatePreposition('');
              if (isMounted) setStrategyDate(null);
              if (isMounted) setStrategyDateRange([null, null]);
              if (isMounted) setResolutionSource('');
              if (isMounted) setLinkAuthority('');
              if (isMounted) setSourceDatePreposition('');
              if (isMounted) setResolutionDate(null);
              if (isMounted) setResolutionDateRange([null, null]);
              if (isMounted) setExtraInfo('');
              if (isMounted) setLikelihoodPrediction('');
              if (typeof response.data.id !== 'undefined') {
                dispatch(
                  fetchStrategiesByOutcome({
                    outcomeId: outcome.id,
                    auth_token: token
                  })
                );
              }
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to create strategy: ${err.message}`);
      } finally {
        if (isMounted) setCreateStrategyRequestStatus('idle');
        setIsMounted(false);
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (!outcome.id) {
        setErrorMessage(
          'No outcome id could be found. Please reload the page.'
        );
      } else if (!strategyText) {
        setErrorMessage('Please enter your strategy text description.');
      } else if (strategyText.length > maxStrategyLength) {
        setErrorMessage(
          `Strategy text must be less than ${maxStrategyLength} characters.`
        );
      } else if (strategyText.length <= minStrategyLength) {
        setErrorMessage('Strategy text must be at least 6 characters.');
      } else if (!strategyDatePreposition) {
        setErrorMessage('Please enter a strategy date preposition.');
      } else if (
        dateRangePrepositions.indexOf(strategyDatePreposition) === -1 &&
        !strategyDate
      ) {
        setErrorMessage('Please provide the strategy date.');
      } else if (
        dateRangePrepositions.indexOf(strategyDatePreposition) !== -1 &&
        (!strategyDateRange[0] || !strategyDateRange[1])
      ) {
        setErrorMessage('Please provide the strategy date range.');
      } else if (!resolutionSource) {
        setErrorMessage('Please enter a resolution source.');
      } else if (!sourceDatePreposition) {
        setErrorMessage('Please enter a source date preposition.');
      } else if (
        dateRangePrepositions.indexOf(sourceDatePreposition) === -1 &&
        !resolutionDate
      ) {
        setErrorMessage('Please provide the resolution date.');
      } else if (
        dateRangePrepositions.indexOf(sourceDatePreposition) !== -1 &&
        (!resolutionDateRange[0] || !resolutionDateRange[1])
      ) {
        setErrorMessage('Please provide the resolution date range.');
      } else if (!likelihoodPrediction) {
        setErrorMessage('Please provide a likelihood prediction.');
      } else {
        setErrorMessage('Strategy could not be submitted.');
      }
    }
  };

  let content;
  let canRenderCard =
    outcomeStatus === 'succeeded' &&
    outcome &&
    (outcome.statuses !== 'Generation' ||
      strategy.created_by.username === userData.username ||
      userData.role === 'Moderator' ||
      userData.role === 'Admin' ||
      userData.role === 'Observer' ||
      outcome.created_by.username === userData.username);

  if (usernameSettings === undefined) {
    content = null;
  } else if (outcomeStatus === 'loading') {
    content = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (canRenderCard) {
    content = (
      <div className="px-5 py-3">
        <div className="flex flex-col">
          <div
            className="flex "
            data-color-mode={isDarkMode ? 'dark' : 'light'}>
            {/* strategy text */}
            <MDEditor
              id="StrategyText"
              value={strategyText}
              onChange={(event) => changeStrategyText(event)}
              className="w-full"
              textareaProps={{
                placeholder: 'Strategy text',
                maxLength: maxStrategyLength,
                required: true,
                autoCorrect: 'on'
              }}
              preview="edit"
              style={{
                backgroundColor: isDarkMode
                  ? theme.palette.background.paper
                  : '#fff',
                color: isDarkMode ? theme.palette.text.primary : '#000'
              }}
              defaultTabEnable={true}
            />
            <div className="ml-1">
              <InfoTooltip text="A description of a course of action a decision maker could take to potentially influence their Outcome." />
            </div>
          </div>
          <Typography
            color="text.secondary"
            sx={{
              fontSize: '0.75rem'
            }}>{`${strategyText.length}/${maxStrategyLength}`}</Typography>
          <div className="flex flex-row flex-wrap py-2">
            <div className="pt-6 pr-1">
              This strategy is expected to be executed
            </div>
            {/* strategy date preposition */}
            <div className="p-1 pr-3">
              <TextField
                variant="standard"
                id="strategy-date-preposition"
                label="Date Preposition"
                select
                required
                defaultValue=""
                className="w-48"
                value={strategyDatePreposition}
                onChange={(event) => changeStrategyDatePreposition(event)}>
                {datePreposition.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            {/* strategy date */}
            <div
              className="relative z-10"
              id={
                theme.palette.mode === 'dark' ? 'dark-picker' : 'light-picker'
              }>
              {strategyDatePreposition === 'between' ? (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ukLocale}>
                  <DatePicker
                    id="date-picker"
                    dateFormat="yyyy/MM/dd"
                    selected={strategyDateRange[0]}
                    onChange={(newValue) => {
                      changeStrategyDateRange(newValue);
                    }}
                    startDate={strategyDateRange[0]}
                    endDate={strategyDateRange[1]}
                    selectsRange
                    placeholderText="Select date range *"
                    minDate={parseISO(outcome.forecasting_start_date)}
                    showYearDropdown
                  />
                </LocalizationProvider>
              ) : strategyDatePreposition === 'in' ? (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ukLocale}>
                  <DatePicker
                    id="date-picker"
                    dateFormat="yyyy/MM"
                    selected={strategyDateRange[0]}
                    onChange={(newValue) => {
                      var endDate = moment(newValue).endOf('month');
                      changeStrategyDateRange([newValue, endDate.toDate()]);
                    }}
                    startDate={strategyDateRange[0]}
                    endDate={strategyDateRange[1]}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    placeholderText="Select month *"
                    minDate={parseISO(outcome.forecasting_start_date)}
                    showYearDropdown
                  />
                </LocalizationProvider>
              ) : (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ukLocale}>
                  <DatePicker
                    id="date-picker"
                    dateFormat="yyyy/MM/dd"
                    selected={strategyDate}
                    onChange={(newValue) => {
                      changeStrategyDate(newValue);
                    }}
                    placeholderText="Select date *"
                    minDate={parseISO(outcome.forecasting_start_date)}
                    showYearDropdown
                  />
                </LocalizationProvider>
              )}
            </div>
          </div>
          <div className="flex flex-row flex-wrap py-2">
            <div className="pt-6 pr-1">As reported by </div>
            {/* strategy source */}
            <div id="autocomplete-source" className="p-1 pr-1">
              <AutocompleteSource
                sourceValues={sourceValues}
                value={resolutionSource}
                setValue={changeResolutionSource}
              />
            </div>

            {/* strategy link authority */}
            <div className="p-1 pr-1">
              <TextField
                type="text"
                variant="standard"
                label="Link Authority"
                value={linkAuthority}
                placeholder="e.g. https://www.bbc.co.uk/news"
                onChange={(event) => changeLinkAuthority(event)}
                id="link-authority"
                className="w-48"
              />
            </div>

            {/* source date preposition */}
            <div className="p-1 pr-1">
              <TextField
                id="strategy-resolution-date-preposition"
                select
                required
                className="w-48"
                label="Date Preposition"
                variant="standard"
                defaultValue=""
                value={sourceDatePreposition}
                onChange={(event) => changeSourceDatePreposition(event)}>
                {datePreposition.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            {/* resolution date */}
            <div
              className="relative z-10"
              id={
                theme.palette.mode === 'dark' ? 'dark-picker' : 'light-picker'
              }>
              {sourceDatePreposition === 'between' ? (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ukLocale}>
                  <DatePicker
                    id="date-picker"
                    dateFormat="yyyy/MM/dd"
                    selected={resolutionDateRange[0]}
                    onChange={(newValue) => {
                      changeResolutionDateRange(newValue);
                    }}
                    startDate={resolutionDateRange[0]}
                    endDate={resolutionDateRange[1]}
                    selectsRange
                    placeholderText="Select date range *"
                    minDate={parseISO(outcome.forecasting_start_date)}
                    showYearDropdown
                  />
                </LocalizationProvider>
              ) : sourceDatePreposition === 'in' ? (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ukLocale}>
                  <DatePicker
                    id="date-picker"
                    dateFormat="yyyy/MM"
                    selected={resolutionDateRange[0]}
                    onChange={(newValue) => {
                      var endDate = moment(newValue).endOf('month');
                      changeResolutionDateRange([newValue, endDate.toDate()]);
                    }}
                    startDate={resolutionDateRange[0]}
                    endDate={resolutionDateRange[1]}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    placeholderText="Select month *"
                    minDate={parseISO(outcome.forecasting_start_date)}
                    showYearDropdown
                  />
                </LocalizationProvider>
              ) : (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ukLocale}>
                  <DatePicker
                    id="date-picker"
                    dateFormat="yyyy/MM/dd"
                    selected={resolutionDate}
                    onChange={(newValue) => {
                      changeResolutionDate(newValue);
                    }}
                    placeholderText="Select date *"
                    minDate={parseISO(outcome.forecasting_start_date)}
                    showYearDropdown
                  />
                </LocalizationProvider>
              )}
            </div>
          </div>
          <div className="pt-4">
            {/* extra info */}
            <TextField
              type="text"
              variant="outlined"
              label="Extra info"
              value={extraInfo}
              multiline
              minRows={2}
              maxRows={10}
              inputProps={{ style: { fontSize: '0.875rem' } }}
              onChange={(event) => changeExtraInfo(event)}
              className="w-full"
              helperText={`${extraInfo.length}/${maxStrategyLength}`}
              error={extraInfo.length > maxStrategyLength}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </div>

          {outcome.created_by.username === userData.username && (
            <div className="py-4">
              <div className="pr-3 ">
                Please enter how likely you think it is that this strategy will
                be carried out (you can change this prediction later):
              </div>
              <div className="flex flex-row items-center gap-10">
                <Slider
                  valueLabelDisplay="auto"
                  value={
                    typeof likelihoodPrediction === 'number'
                      ? likelihoodPrediction
                      : 0
                  }
                  onChange={handleSliderLikelihoodPrediction}
                  marks={sliderMarks()}
                />
                <TextField
                  type="number"
                  value={likelihoodPrediction}
                  onChange={handleInputLikelihoodPrediction}
                  InputProps={{ pattern: '[0-9]+[.]?[0-9]?' }}
                  inputProps={{
                    min: 0.0,
                    max: 100.0,
                    step: 0.1
                  }}
                  sx={{ minWidth: 150 }}
                  variant="outlined"
                  label="Prediction (%)"
                  onBlur={handleLikelihoodPredictionBlur}
                />
              </div>
            </div>
          )}

          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}

          {!strategy && (
            <div className="flex justify-end">
              <Button
                id="submit-strategy-button"
                variant="contained"
                sx={{ my: 0.6 }}
                disabled={isMounted}
                onClick={() => submitStrategy()}
                className="font-bold rounded">
                {isMounted ? (
                  <ClipLoader color="#ffffff" loading={true} size={50} />
                ) : (
                  'Post'
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    content = null;
  }
  return <section>{content}</section>;
}
