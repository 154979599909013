import { Dangerous, Help, Verified } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Collapse,
  TextField,
  Typography,
  useTheme,
  Backdrop
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { formatISO, parseISO } from 'date-fns';
import ukLocale from 'date-fns/locale/en-GB';
import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  fetchQuestionById,
  setQuestionResolution,
  setStrategyResolution
} from '../../store/slices/outcomeSlice';
import SmallQuestionCard from '../cards/SmallQuestionCard';
import StrategyCard from '../cards/StrategyCard';

export default function SetResolutionModal({ shown, close, parentEntity }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  const [responseMessage, setResponseMessage] = useState('');
  const [resolutionRequestStatus, setResolutionRequestStatus] =
    useState('idle');

  const [selectedResolution, setSelectedResolution] = useState(
    parentEntity.current_resolution?.name
  );
  var resolution_date = new Date(parentEntity.current_resolution?.resolved_at);
  var utc_resolution_date = new Date(Date.UTC(resolution_date.getFullYear(), resolution_date.getMonth(),
    resolution_date.getDate(), resolution_date.getHours(),
    resolution_date.getMinutes(), resolution_date.getSeconds()))
  const [resolutionDate, setResolutionDate] = useState(utc_resolution_date);
  const resolvedByRole = parentEntity.current_resolution?.user?.role?.name;
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const closeModal = () => {
    close();
  };

  const isOutRanked =
    (resolvedByRole === 'Admin' && userData.role !== 'Admin') ||
    (resolvedByRole === 'Moderator' && userData.role === 'OutcomeOwner');

  // if a strategy has a resolution of "implemented" or "not implemented", it must be unset (Not Set) before it can be changed again
  const mustUnsetStrategy = parentEntity.question_text
    ? false
    : ['Implemented', 'Not Implemented'].includes(
        parentEntity.current_resolution?.name
      )
    ? true
    : false;

  const canSetResolution =
    [parentEntity, checkTokenStatus()].every(Boolean) &&
    selectedResolution !== '' &&
    resolutionDate !== null &&
    resolutionRequestStatus === 'idle' &&
    !isOutRanked;

  const setResolutionConfirm = async () => {
    if (canSetResolution) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setResolutionRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: parentEntity.id,
          resolution: selectedResolution,
          resolutionDate: formatISO(resolutionDate),
          auth_token: token,
          outcome_id: parentEntity?.outcome_id
        };
        if (parentEntity.question_text) {
          await dispatch(setQuestionResolution(payload))
            .unwrap()
            .then((response) => {
              if (isMounted) {
                setSelectedResolution('');
                setResolutionDate(null);
              }
              if (response.status === 'success') {
                setErrorMessage('');
                setResponseMessage(
                  `The Question's Resolution has been successfully set`
                );
                dispatch(
                  fetchQuestionById({
                    questionId: parentEntity.id,
                    auth_token: token
                  })
                );
              }
            });
        } else if (parentEntity.strategy) {
          await dispatch(setStrategyResolution(payload))
            .unwrap()
            .then((response) => {
              if (isMounted) {
                setSelectedResolution('');
                setResolutionDate(null);
              }
              if (response.status === 'success') {
                setErrorMessage('');
                setResponseMessage(
                  `The Strategy's Resolution has been successfully set`
                );
              }
            });
        }
      } catch (err) {
        setErrorMessage(`Failed to set Resolution: ${err.message}`);
      } finally {
        if (isMounted) {
          setResolutionRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (selectedResolution === '') {
      setErrorMessage('Please select a Resolution before confirming.');
    } else if (resolutionDate === null) {
      setErrorMessage('Please select a Resolution Date before confirming.');
    } else {
      setErrorMessage('Failed to set Resolution');
    }
  };

  useEffect(() => {
    if (shown) {
      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          close();
        }
      };
      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [shown, close]);
  
  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Backdrop
        sx={{
          zIndex: 999,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={true}
        onClick={() => close()}>   
        <Card
          className="modal-content w-full sm:w-6/7 md:w-5/6 lg:w-3/5 xl:w-3/5 2xl:w-3/5"
          sx={{ overflowY: 'auto !important', maxHeight: '90vh' }}
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}>
          <div>
            <Typography
              sx={{ fontWeight: 700, fontSize: '1.1rem' }}
              className="text-center">
              {parentEntity.question_text ? 'Question' : 'Strategy'} Resolution
            </Typography>
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
            <div className="flex justify-center">
              <div className="break-words text-xs font-medium m-1 my-2">
                <Typography sx={{ fontSize: '0.8rem' }}>
                  Record the resolution as either True, False or Ambiguous as well
                  as the date that this resolution was confirmed.
                </Typography>
              </div>
            </div>
            {!responseMessage && (
              <div>
                {parentEntity.question_text ? (
                  <div className="break-words font-medium">
                    <Typography
                      sx={{ fontWeight: 400, py: 0.6 }}
                      color="primary.main">
                      Question:
                    </Typography>
                    <div className="border-2 rounded">
                      <SmallQuestionCard questionId={parentEntity.id} />
                    </div>
                  </div>
                ) : (
                  <div className="break-words font-medium">
                    <Typography
                      sx={{ fontWeight: 400, py: 0.6 }}
                      color="primary.main">
                      Strategy:
                    </Typography>
                    <div className="border-2 rounded">
                      <StrategyCard strategy={parentEntity} hideButtons />
                    </div>
                  </div>
                )}

                {isOutRanked && (
                  <div className="flex justify-center mt-2">
                    <Typography sx={{ fontWeight: 400, py: 0.6 }} color="error">
                      You are not allowed to set the resolution of this{' '}
                      {parentEntity.question_text ? 'question' : 'strategy'}
                    </Typography>
                  </div>
                )}

                {mustUnsetStrategy && (
                  <div className="flex justify-center mt-2">
                    <Typography sx={{ fontWeight: 'bold', py: 0.6 }}>
                      Info: This strategy must be set to "Not Set" before it can
                      be changed again
                    </Typography>
                  </div>
                )}

                <div className="flex justify-center w-full mx-1 my-4">
                  <Button
                    sx={{
                      borderRadius: '0.5rem',
                      p: '0.125rem',
                      mx: 2,
                      overflow: 'hidden',
                      position: 'relative',
                      '&:hover': {
                        color: theme.palette.secondary.main
                      }
                    }}
                    disabled={isOutRanked || mustUnsetStrategy}
                    onClick={() =>
                      parentEntity.question_text
                        ? setSelectedResolution('True')
                        : setSelectedResolution('Implemented')
                    }
                    className={`relative group bg-gradient-to-br from-lightGreen-500 to-green-500 group-hover:from-lightGreen-500 group-hover:to-green-500 ${
                      selectedResolution === 'True'
                        ? 'ring-4 ring-lightGreen-300'
                        : ''
                    }`}>
                    <div
                      style={{
                        transitionTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
                        transitionDuration: '75ms',
                        borderRadius: '0.375rem',
                        transitionProperty: 'all',
                        backgroundImage:
                          selectedResolution === 'True' ||
                          selectedResolution == 'Implemented'
                            ? 'linear-gradient(to bottom right, from-lightGreen-500 to-green-500)'
                            : '',
                        backgroundColor:
                          selectedResolution === 'True' ||
                          selectedResolution == 'Implemented'
                            ? ''
                            : theme.palette.background.paper
                      }}
                      className={`relative px-2 py-2 flex items-center rounded-md group-hover:bg-opacity-0`}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          color: 'text.primary',
                          '&:hover': {
                            color:
                              selectedResolution === 'True' ||
                              selectedResolution === 'Implemented'
                                ? ''
                                : 'primary.main'
                          }
                        }}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            mr: 1
                          }}
                          className="font-sans uppercase">
                          {parentEntity.question_text ? 'True' : 'Implemented'}
                        </Typography>
                        <Verified sx={{}} />
                      </Box>
                    </div>
                  </Button>

                  <Button
                    sx={{
                      borderRadius: '0.5rem',
                      p: '0.125rem',
                      mx: 2,
                      overflow: 'hidden',
                      position: 'relative',
                      '&:hover': {
                        color: theme.palette.secondary.main
                      }
                    }}
                    disabled={isOutRanked || mustUnsetStrategy}
                    onClick={() =>
                      parentEntity.question_text
                        ? setSelectedResolution('False')
                        : setSelectedResolution('Not Implemented')
                    }
                    className={`relative group bg-gradient-to-br from-rose-500 to-red-500 group-hover:from-rose-500 group-hover:to-red-500 ${
                      selectedResolution === 'False' ? 'ring-4 ring-rose-400' : ''
                    }`}>
                    <div
                      style={{
                        transitionTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
                        transitionDuration: '75ms',
                        borderRadius: '0.375rem',
                        transitionProperty: 'all',
                        backgroundImage:
                          selectedResolution === 'False' ||
                          selectedResolution == 'Not Implemented'
                            ? 'linear-gradient(to bottom right, from-rose-500 to-red-500)'
                            : '',
                        backgroundColor:
                          selectedResolution === 'False' ||
                          selectedResolution == 'Not Implemented'
                            ? ''
                            : theme.palette.background.paper
                      }}
                      className={`relative px-2 py-2 flex items-center rounded-md group-hover:bg-opacity-0`}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          color: 'text.primary',
                          '&:hover': {
                            color:
                              selectedResolution === 'False' ||
                              selectedResolution === 'Not Implemented'
                                ? ''
                                : 'primary.main'
                          }
                        }}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            mr: 1
                          }}
                          className="font-sans uppercase">
                          {parentEntity.question_text
                            ? 'False'
                            : 'Not Implemented'}
                        </Typography>
                        <Dangerous sx={{}} />
                      </Box>
                    </div>
                  </Button>

                  {(parentEntity.question_text ||
                    selectedResolution === 'Ambiguous') && (
                    <Button
                      sx={{
                        borderRadius: '0.5rem',
                        p: '0.125rem',
                        mx: 2,
                        overflow: 'hidden',
                        position: 'relative',
                        '&:hover': {
                          color: theme.palette.secondary.main
                        }
                      }}
                      disabled={isOutRanked}
                      onClick={() => setSelectedResolution('Ambiguous')}
                      className={`relative group bg-gradient-to-br from-yellow-500 to-orange-500 group-hover:from-yellow-500 group-hover:to-orange-500 ${
                        selectedResolution === 'Ambiguous'
                          ? 'ring-4 ring-yellow-400'
                          : ''
                      }`}>
                      <div
                        style={{
                          transitionTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
                          transitionDuration: '75ms',
                          borderRadius: '0.375rem',
                          transitionProperty: 'all',
                          backgroundImage:
                            selectedResolution === 'Ambiguous'
                              ? 'linear-gradient(to bottom right, from-yellow-500 to-orange-500)'
                              : '',
                          backgroundColor:
                            selectedResolution === 'Ambiguous'
                              ? ''
                              : theme.palette.background.paper
                        }}
                        className={`relative px-2 py-2 flex items-center rounded-md group-hover:bg-opacity-0`}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: 'text.primary',
                            '&:hover': {
                              color:
                                selectedResolution === 'Ambiguous'
                                  ? ''
                                  : 'primary.main'
                            }
                          }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              mr: 1
                            }}
                            className="font-sans uppercase">
                            Ambiguous
                          </Typography>
                          <Help sx={{}} />
                        </Box>
                      </div>
                    </Button>
                  )}

                  {[
                    'True',
                    'False',
                    'Ambiguous',
                    'Implemented',
                    'Not Implemented'
                  ].includes(parentEntity.current_resolution?.name) && (
                    <div>
                      <Button
                        sx={{
                          borderRadius: '0.5rem',
                          p: '0.125rem',
                          mx: 2,
                          overflow: 'hidden',
                          position: 'relative',
                          '&:hover': {
                            color: theme.palette.secondary.main
                          }
                        }}
                        disabled={isOutRanked}
                        onClick={() => setSelectedResolution('Not Set')}
                        className={`relative group bg-gradient-to-br from-gray-500 to-slate-500 group-hover:from-gray-500 group-hover:to-red-500 ${
                          selectedResolution === 'Not Set'
                            ? 'ring-4 ring-gray-400'
                            : ''
                        }`}>
                        <div
                          style={{
                            transitionTimingFunction:
                              'cubic-bezier(0.4, 0, 1, 1)',
                            transitionDuration: '75ms',
                            borderRadius: '0.375rem',
                            transitionProperty: 'all',
                            backgroundImage:
                              selectedResolution === 'Not Set'
                                ? 'linear-gradient(to bottom right, from-gray-500 to-black-500)'
                                : '',
                            backgroundColor:
                              selectedResolution === 'Not Set'
                                ? ''
                                : theme.palette.background.paper
                          }}
                          className={`relative px-2 py-2 flex items-center rounded-md group-hover:bg-opacity-0`}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              color: 'text.primary',
                              '&:hover': {
                                color:
                                  selectedResolution === 'Not Set'
                                    ? ''
                                    : 'primary.main'
                              }
                            }}>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                mr: 1
                              }}
                              className="font-sans uppercase">
                              Not Set
                            </Typography>
                            <Dangerous sx={{}} />
                          </Box>
                        </div>
                      </Button>
                    </div>
                  )}
                </div>

                <Collapse
                  in={selectedResolution !== ''}
                  sx={{ mb: 1.8, mt: 3, textAlign: 'center' }}>
                  {selectedResolution && (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ukLocale}>
                      <DateTimePicker
                        clearable
                        disabled={isOutRanked}
                        label="Resolution Date"
                        value={resolutionDate}
                        onChange={(newValue) => {
                          setResolutionDate(newValue);
                        }}
                        minDate={parseISO(parentEntity.created_at)}
                        disableFuture
                        textField={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                </Collapse>

                <div className="flex justify-end mt-2">
                  <div className="mx-2">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'gray',
                        ':hover': { backgroundColor: '#757575' }
                      }}
                      onClick={closeModal}>
                      Cancel
                    </Button>
                  </div>
                  <div className="mx-2">
                    <Button
                      disabled={isOutRanked}
                      variant="contained"
                      onClick={setResolutionConfirm}>
                      Confirm
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {responseMessage && (
              <div className="my-2">
                {responseMessage && (
                  <Typography color="success.main" className="text-center">
                    {responseMessage}
                  </Typography>
                )}

                <div className="flex mt-4 justify-center">
                  <Button
                    onClick={closeModal}
                    variant="contained"
                    className="w-4/12">
                    Close
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Backdrop>
    </div>
  ) : null;
}
