import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Fullscreen, Groups } from '@mui/icons-material';
import {
  Button,
  Card,
  Collapse,
  Link,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { FiCalendar, FiHash } from 'react-icons/fi';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  selectLatestOutcomeBelief,
  selectOutcomeBeliefChange
} from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';
import RoleBadgeIcon from '../icons/RoleBadgeIcon';
import AIForecastMultipleQuestionsModal from '../modals/AIForecastMultipleQuestionsModal';
import ForecastSubmissionModal from '../modals/ForecastSubmissionModal';
import GenerateQuestionsModal from '../modals/GenerateQuestionsModal';
import ForecastDashboard from '../other/charts/ForecastDashboard';
import Markdown from '../other/Markdown';
import OutcomeStatusText from '../other/OutcomeStatusText';

export default function OutcomeCardQuestionView({ outcome }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [showForecastSubmissionModal, setShowForecastSubmissionModal] =
    useState(false);

  const forecastOverruleSettings = useSelector((state) =>
    selectSettingByName(state, 'Forecast Override Active')
  );
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );

  const autoQuestionsSetting = useSelector((state) =>
    selectSettingByName(state, 'AutoQuestions Provider')
  );

  const aiForecastSetting = useSelector((state) =>
    selectSettingByName(state, 'AI Forecast Toggle')
  );

  const latestBelief = useSelector((state) =>
    selectLatestOutcomeBelief(state, outcome.id)
  );

  const beliefChange = useSelector((state) =>
    selectOutcomeBeliefChange(state, outcome.id)
  );

  const [dashboardOpen, setDashboardOpen] = useState(true);
  const { userData } = useContext(AuthContext);
  const usersData = useSelector((state) => state.users.entities);
  const [showGenerateQuestionsModal, setShowGenerateQuestionsModal] =
    useState(false);

  const [showModal, setShowModal] = useState(false);
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    // check if auth token is valid
    if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <navigate to={'/login'} />;
    } else {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn]);

  const createAIForecast = () => {
    setShowModal(true);
    setIsAIForecastButtonDisabled(true);
  };

  const [isAIForecastButtonDisabled, setIsAIForecastButtonDisabled] =
    useState(false);
  useEffect(() => {
    checkButtonStatus();
  }, [outcome]);

  const minutesSinceOutcomeAIForecastButtonPressed = () => {
    if (outcome?.ai_forecast_button_pressed) {
      return moment().diff(
        moment(outcome?.ai_forecast_button_pressed, 'YYYY-MM-DDThh:mm:ss'),
        'minutes'
      );
    } else {
      return 999999999;
    }
  };
  const checkButtonStatus = async () => {
    try {
      if (minutesSinceOutcomeAIForecastButtonPressed() >= 10) {
        setIsAIForecastButtonDisabled(false);
      } else {
        setIsAIForecastButtonDisabled(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBorderColour = () => {
    if (outcome.statuses.includes('Forecasting')) {
      return theme.palette.statuses.mid3;
    } else if (outcome.statuses.includes('Evaluation')) {
      return theme.palette.statuses.mid2;
    } else if (outcome.statuses.includes('Generation')) {
      return theme.palette.statuses.mid1;
    } else if (outcome.statuses.includes('Closed')) {
      return theme.palette.statuses.mid4;
    } else {
      return theme.palette.statuses.mid5;
    }
  };

  const getOutcomeStatusColour = (status) => {
    switch (status) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };

  const getBeliefDiffText = () => {
    let diff = Math.abs(outcome.outcome_forecast - latestBelief.belief);
    if (diff < 0.05) {
      return (
        <Typography sx={{ mx: 0.6, fontSize: '0.95rem' }}>
          The gap between these implies the portfolio of questions has strong
          relevance to the Outcome.
        </Typography>
      );
    }
    if (diff < 0.2) {
      return (
        <Typography sx={{ mx: 0.6, fontSize: '0.95rem' }}>
          The gap between these implies either (1) that not all the relevant
          factors are accounted for in the questions, or (2) the logic of the
          argument in questions may need adjusting.
        </Typography>
      );
    }
    return (
      <Typography sx={{ mx: 0.6, fontSize: '0.95rem' }}>
        The gap between these is significant implying either (1) important
        relevant factors are missing from the question set, or (2) the logic of
        the argument in questions may need adjusting.
      </Typography>
    );
  };

  if (usernameSettings === undefined) {
    return null;
  } else {
    return (
      <div className="OutcomeCardQuestionView">
        <Card
          sx={{
            boxShadow: '0 7px 20px -9px rgba(0,0,0,0.3)',
            borderLeftWidth: '5px',
            borderLeftColor: getBorderColour()
          }}
          className={`m-5 px-5 py-4 break-words`}>
          <div className="OutcomeText font-medium max-h-36 overflow-y-auto">
            <div className="flex justify-between">
              <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                <span className="whitespace-pre-line">{outcome.title}</span>
              </Typography>
              <div className="flex flex-row">
                {outcome.statuses.map((status, index) => (
                  <Typography
                    key={index}
                    sx={{
                      borderColor: getOutcomeStatusColour(status),
                      backgroundColor: getOutcomeStatusColour(status),
                      py: 0,
                      px: 0.5,
                      fontSize: '0.95rem',
                      mx: 0.6
                    }}
                    className={`flex justify-center items-center border-1 rounded text-white font-normal text-xs px-1 ml-1`}>
                    {status}
                  </Typography>
                ))}
              </div>
            </div>
          </div>
          <div className="OutcomeInfo flex flex-wrap items-center justify-start">
            {outcome.statuses.map((status, index) => (
              <OutcomeStatusText
                status={status}
                outcome_id={outcome.id}
                key={index}
              />
            ))}
            {(userData.role === 'Admin' ||
              userData.role === 'Moderator' ||
              usernameSettings.active) && (
              <div className="flex items-center">
                <Typography
                  sx={{
                    ml: 0.6,
                    fontSize: '0.95rem',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  color="text.secondary">
                  Created by
                </Typography>
                <Typography
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/profile/${outcome.created_by.username}`);
                  }}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === ' ' || e.key === 'Enter') {
                      e.stopPropagation();
                      navigate(`/profile/${outcome.created_by.username}`);
                    }
                  }}
                  sx={{ mx: 0.6, fontSize: '0.95rem' }}
                  className="hover:underline hover:cursor-pointer"
                  color="primary.main">
                  {outcome.created_by.username}
                </Typography>
                <div className="pb-1">
                  <RoleBadgeIcon
                    role={usersData[outcome.created_by.id]?.role}
                  />
                </div>
              </div>
            )}
            {outcome.groups?.length > 0 && (
              <div className="sm:flex hidden items-center p-1">
                <Groups
                  fontSize="inherit"
                  sx={{ mr: 0.6, color: 'text.secondary' }}
                />
                <Typography
                  sx={{
                    mr: 0.6,
                    fontSize: '0.875rem'
                  }}
                  color="text.secondary">
                  <span className="font-bold"></span>
                  {outcome.groups.map((group, index) => (
                    <Link
                      key={group.id}
                      sx={{
                        color: 'text.secondary',
                        textDecoration: 'None',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        navigate(`/user-groups/${group.id}`);
                      }}>
                      {index > 0 ? ', ' : ''}
                      {group.name}
                    </Link>
                  ))}
                </Typography>
              </div>
            )}
          </div>
          <div className="markdown max-h-80 overflow-y-auto">
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: '1rem',
                p: 1
              }}
              component="div">
              <Markdown description={outcome.description} />
            </Typography>
          </div>
          <div className="sm:flex hidden items-center p-1">
            {(outcome.statuses.includes('Generation') ||
              outcome.statuses.includes('Evaluation')) && (
              <div className="flex items-center mr-2">
                <FiCalendar className="mr-1 mb-0.5" />
                <Typography
                  sx={{
                    mr: 0.6,
                    fontSize: '0.875rem'
                  }}>{`Outcome Ends: ${moment
                  .utc(outcome.end_at)
                  .local()
                  .format('dddd, MMMM Do YYYY')}`}</Typography>
              </div>
            )}
            {(outcome.statuses.includes('Generation') ||
              outcome.statuses.includes('Evaluation')) &&
              outcome.question_submission_limit && (
                <div className="sm:flex items-center mx-4 mr-2 hidden">
                  <FiHash size="20" className="mr-1 mb-0.5" />
                  <Typography
                    sx={{
                      fontSize: '0.875rem'
                    }}>{`The top ${outcome.question_submission_limit} rated Questions will be submitted to the Forecasting Tournament`}</Typography>
                </div>
              )}
          </div>
          {['Admin', 'Moderator', 'OutcomeOwner'].includes(userData.role) &&
            outcome.outcome_forecast && (
              <div className="my-1 mx-1">
                <Button
                  size="small"
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/dashboard/${outcome.id}`);
                  }}>
                  Outcome Dashboard
                </Button>
              </div>
            )}
          <div className="my-2">
            {latestBelief && (
              <div>
                <div className="flex flex-wrap flex-row">
                  {outcome.outcome_forecast && (
                    <>
                      <Typography sx={{ mx: 0.6, fontSize: '0.95rem' }}>
                        Direct Forecasts on the outcome estimate its likelihood
                        as{' '}
                        {parseFloat(
                          (outcome.outcome_forecast * 100).toFixed(2)
                        )}
                        %
                      </Typography>
                      <Typography sx={{ fontSize: '0.95rem' }}>
                        suggesting it{' '}
                      </Typography>
                      {outcome.outcome_forecast > 0.5 ? (
                        <Typography
                          sx={{ mx: 0.6, fontSize: '0.95rem' }}
                          color={
                            theme.palette.mode === 'dark'
                              ? outcome.outcome_forecast > 0.6
                                ? 'limegreen'
                                : 'orange'
                              : outcome.outcome_forecast > 0.6
                              ? 'green'
                              : 'orange'
                          }>
                          will happen.
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ mx: 0.6, fontSize: '0.95rem' }}
                          color={
                            theme.palette.mode === 'dark'
                              ? outcome.outcome_forecast > 0.4
                                ? 'orange'
                                : 'tomato'
                              : outcome.outcome_forecast > 0.4
                              ? 'orange'
                              : 'red'
                          }>
                          won't happen.
                        </Typography>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {latestBelief.belief && (
                    <Typography sx={{ mx: 0.6, fontSize: '0.95rem' }}>
                      The aggregated probabilities across the portfolio of
                      questions implies a{' '}
                      {(latestBelief.belief * 100).toFixed(1)}% likelihood.
                    </Typography>
                  )}
                  {outcome.outcome_forecast && latestBelief.belief && (
                    <>{getBeliefDiffText()}</>
                  )}
                </div>
              </div>
            )}
          </div>
          {outcome.id && (
            <div className="flex justify-start w-full">
              <Collapse
                in={outcome.statuses.includes('Forecasting') && dashboardOpen}
                sx={{ width: '100%' }}
                className="w-auto">
                <div className="flex items-start w-full">
                  <ForecastDashboard
                    outcomeId={outcome.id}
                    width={'large'}
                    forecastingStartDate={outcome.forecasting_start_date}
                    setDashboardOpen={setDashboardOpen}
                  />
                </div>
              </Collapse>
            </div>
          )}
          {!dashboardOpen && (
            <div className="flex justify-start">
              <Button onClick={() => setDashboardOpen(true)} variant="outlined">
                <Fullscreen />
                Expand Dashboard
              </Button>
            </div>
          )}

          <div className="flex items-center">
            {outcome.statuses.includes('Evaluation') &&
              outcome.question_submission_limit &&
              ((outcome.created_by.username === userData.username &&
                forecastOverruleSettings?.options.find(
                  (setting) => setting.name === 'outcome owner'
                ).selected === true) ||
                userData.role === 'Admin') && (
                <div>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowForecastSubmissionModal(true);
                    }}>
                    Forecast Override
                  </Button>
                </div>
              )}

            {showForecastSubmissionModal && (
              <ForecastSubmissionModal
                close={() => {
                  setShowForecastSubmissionModal(false);
                }}
                outcomeId={outcome.id}
              />
            )}
            {(outcome.created_by.id === userData.id ||
              userData.role === 'Admin') &&
              outcome.statuses.includes('Generation') &&
              autoQuestionsSetting?.options?.some(
                (option) => option.name === 'None' && option.selected === false
              ) && (
                <div>
                  <Button
                    size="small"
                    className={'items-center'}
                    onClick={() => setShowGenerateQuestionsModal(true)}>
                    Create AI Questions
                  </Button>
                </div>
              )}
            {showGenerateQuestionsModal && (
              <GenerateQuestionsModal
                shown={showGenerateQuestionsModal}
                close={() => {
                  setShowGenerateQuestionsModal(false);
                }}
                outcomeId={outcome.id}
              />
            )}
            <div className="UserSettingsView m-1">
              {showModal && isLoggedIn && userData.role === 'Admin' && (
                <AIForecastMultipleQuestionsModal
                  shown={showModal}
                  close={() => {
                    setShowModal(false);
                  }}
                  cancel={() => {
                    setIsAIForecastButtonDisabled(false);
                    setShowModal(false);
                  }}
                  outcomeId={outcome.id}
                />
              )}
              {outcome.enable_AI_forecasting === 'True' &&
                (outcome.created_by.username === userData.username ||
                  userData.role === 'Moderator' ||
                  userData.role === 'Admin') &&
                outcome.statuses.includes('Forecasting') &&
                outcome.forecast_mechanism === 'derived' &&
                aiForecastSetting?.active && (
                  <div className="flex items-center mr-2">
                    <Button
                      size="small"
                      className={'items-center'}
                      onClick={createAIForecast}
                      disabled={isAIForecastButtonDisabled}>
                      <span>Add AI forecasts for questions</span>
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
