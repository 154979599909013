import {
  AccountCircle,
  Checklist,
  Comment,
  Create,
  Dashboard,
  Description,
  DynamicForm,
  Folder,
  Gavel,
  Groups,
  HelpCenter,
  Home,
  Leaderboard,
  Person,
  Poll,
  Psychology,
  Settings,
  Timeline
} from '@mui/icons-material';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../App';

export default function HierarchicalBreadcrumbs({
  outcomes,
  outcome,
  outcomeId,
  question,
  moderate,
  action,
  questionId,
  comment,
  commentId,
  submit,
  users,
  leaderboard,
  forecasting,
  profile,
  settings,
  questionnaire,
  personality,
  demographics,
  openMinded,
  userGroups,
  quickActions,
  study,
  dashboard
}) {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  const openOutcomesView = () => {
    navigate(`/outcomes`);
  };

  const openOutcomeView = () => {
    navigate(`/outcome/${outcomeId}`);
  };

  const openQuestionView = () => {
    navigate(`/questions/${questionId}`);
  };

  const openCommentView = () => {
    navigate(`/questions/${questionId}/comment/${commentId}`);
  };

  const openUsersView = () => {
    navigate(`/users`);
  };

  const openLeaderboardView = () => {
    navigate(`/leaderboard`);
  };

  const openForecastingView = () => {
    navigate(`/forecasting`);
  };

  const openSettingsView = () => {
    navigate(`/admin-settings`);
  };

  const openModerateView = () => {
    navigate(`/moderate`);
  };

  const openActionView = () => {
    navigate(`/action`);
  };

  const openStudyView = () => {
    navigate(`/study`);
  };

  // const openUseGroupsView = () => {
  //   navigate(`/user-groups`);
  // };

  const openQuickActionsView = () => {
    if (userData.role === 'OutcomeOwner') {
      navigate(`/outcome-owner-navigation`);
    } else if (userData.role === 'Questioner') {
      if (userData?.is_prolific === 'True') {
        navigate(`/prolific-navigation`);
      } else {
        navigate(`/questioner-navigation`);
      }
    } else if (userData.role === 'Moderator' || userData.role === 'Admin') {
      navigate(`/moderator-navigation`);
    }
  };

  const isLastCrumb = (crumb) => {
    if (crumb === 'moderate') {
      if (moderate) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'comment') {
      if (comment) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'question') {
      if (!comment) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'outcome') {
      if (!question && !dashboard) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'submit') {
      if (submit) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'users') {
      if (users) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'leaderboard') {
      if (leaderboard) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'forecasting') {
      if (forecasting) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'outcomes') {
      if (
        !outcome &&
        !question &&
        !submit &&
        !users &&
        !leaderboard &&
        !forecasting &&
        !profile &&
        !settings &&
        !questionnaire
      ) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'questionnaire') {
      if (!personality && !demographics && !openMinded) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'personality') {
      if (personality) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'demographics') {
      if (demographics) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'openMinded') {
      if (openMinded) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'settings') {
      if (settings) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'userGroups') {
      if (userGroups) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'quickActions') {
      if (quickActions) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'study') {
      if (study) {
        return true;
      } else {
        return false;
      }
    }
  };

  function handleNavigationPage(role) {
    if (
      role === 'OutcomeOwner' ||
      role === 'Questioner' ||
      role === 'Moderator' ||
      role === 'Admin'
    ) {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openQuickActionsView}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') openQuickActionsView();
          }}
          key="1">
          <Home sx={{ mr: 0.5 }} fontSize="inherit" />
          Quick Actions
        </Link>
      );

      if (outcome) {
        breadcrumbs.push(
          <Link
            underline="hover"
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            color="inherit"
            onClick={openOutcomesView}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === ' ' || e.key === 'Enter') openOutcomesView();
            }}
            key="1">
            <Folder sx={{ mr: 0.5 }} fontSize="inherit" />
            Outcomes
          </Link>
        );
      }
      return true;
    }
    return false;
  }

  const breadcrumbs = [];
  if (outcomes) {
    if (isLastCrumb('outcomes')) {
      handleNavigationPage(userData.role);
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="1">
          <Home sx={{ mr: 0.5 }} fontSize="inherit" />
          Outcomes
        </Typography>
      );
    } else {
      if (!handleNavigationPage(userData.role)) {
        breadcrumbs.push(
          <Link
            underline="hover"
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            color="inherit"
            onClick={openOutcomesView}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === ' ' || e.key === 'Enter') openOutcomesView();
            }}
            key="1">
            <Home sx={{ mr: 0.5 }} fontSize="inherit" />
            Outcomes
          </Link>
        );
      }
    }
  }
  if (outcome) {
    if (isLastCrumb('outcome') || outcomeId === undefined) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="2">
          <Description sx={{ mr: 0.5 }} fontSize="inherit" />
          {outcome}
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openOutcomeView}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') openOutcomeView();
          }}
          key="2">
          <Description sx={{ mr: 0.5 }} fontSize="inherit" />
          {outcome}
        </Link>
      );
    }
  }
  if (submit) {
    if (isLastCrumb('submit')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="2">
          <Create sx={{ mr: 0.5 }} fontSize="inherit" />
          Submit an Outcome
        </Typography>
      );
    }
  }
  if (question) {
    if (isLastCrumb('question') || questionId === undefined) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="3">
          <HelpCenter sx={{ mr: 0.5 }} fontSize="inherit" />
          Question
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openQuestionView}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') openQuestionView();
          }}
          key="3">
          <HelpCenter sx={{ mr: 0.5 }} fontSize="inherit" />
          Question
        </Link>
      );
    }
  }
  if (comment) {
    if (isLastCrumb('comment') || commentId === undefined) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="4">
          <Comment sx={{ mr: 0.5 }} fontSize="inherit" />
          Comment
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openCommentView}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') openCommentView();
          }}
          key="4">
          <Comment sx={{ mr: 0.5 }} fontSize="inherit" />
          Comment
        </Link>
      );
    }
  }
  if (users) {
    if (isLastCrumb('users')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="5">
          <Person sx={{ mr: 0.5 }} fontSize="inherit" />
          Users
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openUsersView}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') openUsersView();
          }}
          key="5">
          <Person sx={{ mr: 0.5 }} fontSize="inherit" />
          Users
        </Link>
      );
    }
  }
  if (leaderboard) {
    if (isLastCrumb('leaderboard')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="6">
          <Leaderboard sx={{ mr: 0.5 }} fontSize="inherit" />
          Leaderboard
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openLeaderboardView}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') openLeaderboardView();
          }}
          key="6">
          <Leaderboard sx={{ mr: 0.5 }} fontSize="inherit" />
          Leaderboard
        </Link>
      );
    }
  }
  if (forecasting) {
    if (isLastCrumb('forecasting')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="7">
          <Timeline sx={{ mr: 0.5 }} fontSize="inherit" />
          Forecasting
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openForecastingView}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') openForecastingView();
          }}
          key="7">
          <Timeline sx={{ mr: 0.5 }} fontSize="inherit" />
          Forecasting
        </Link>
      );
    }
  }
  if (profile) {
    breadcrumbs.push(
      <Typography
        sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
        color="text.primary"
        key="7">
        <AccountCircle sx={{ mr: 0.5 }} fontSize="inherit" />
        User Profile
      </Typography>
    );
  }
  if (settings) {
    if (isLastCrumb('settings')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="7">
          <Settings sx={{ mr: 0.5 }} fontSize="inherit" />
          Settings
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openSettingsView}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') openSettingsView();
          }}
          key="7">
          <Settings sx={{ mr: 0.5 }} fontSize="inherit" />
          Settings
        </Link>
      );
    }
  }
  if (questionnaire) {
    if (isLastCrumb('questionnaire')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="8">
          <DynamicForm sx={{ mr: 0.5 }} fontSize="inherit" />
          Questionnaire
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
          color="text.primary"
          key="8">
          <DynamicForm sx={{ mr: 0.5 }} fontSize="inherit" />
          Questionnaires
        </Typography>
      );
    }
  }
  if (moderate) {
    handleNavigationPage(userData.role);
    if (isLastCrumb('moderate')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="9">
          <Gavel sx={{ mr: 0.5 }} fontSize="inherit" />
          Moderate
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openModerateView}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') openModerateView();
          }}
          key="9">
          <Gavel sx={{ mr: 0.5 }} fontSize="inherit" />
          Moderate
        </Link>
      );
    }
  }
  if (action) {
    handleNavigationPage(userData.role);
    if (isLastCrumb('action')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="9">
          <Gavel sx={{ mr: 0.5 }} fontSize="inherit" />
          Action
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openActionView}
          key="9">
          <Gavel sx={{ mr: 0.5 }} fontSize="inherit" />
          Action
        </Link>
      );
    }
  }
  if (personality) {
    breadcrumbs.push(
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
        color="text.primary"
        key="10">
        <Psychology sx={{ mr: 0.5 }} fontSize="inherit" />
        Personality
      </Typography>
    );
  }
  if (demographics) {
    breadcrumbs.push(
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
        color="text.primary"
        key="9">
        <Poll sx={{ mr: 0.5 }} fontSize="inherit" />
        Demographics
      </Typography>
    );
  }
  if (openMinded) {
    breadcrumbs.push(
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
        color="text.primary"
        key="11">
        <Psychology sx={{ mr: 0.5 }} fontSize="inherit" />
        Open Minded
      </Typography>
    );
  }
  if (userGroups) {
    breadcrumbs.push(
      <Typography
        sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
        color="text.primary"
        key="12">
        <Groups sx={{ mr: 0.5 }} fontSize="inherit" />
        User Groups
      </Typography>
    );
  }
  if (dashboard) {
    breadcrumbs.push(
      <Typography
        sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
        color="text.primary"
        key="3">
        <Dashboard sx={{ mr: 0.5 }} fontSize="inherit" />
        Dashboard
      </Typography>
    );
  }

  if (quickActions) {
    breadcrumbs.push(
      <Typography
        sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
        color="text.primary"
        key="1">
        <Home sx={{ mr: 0.5 }} fontSize="inherit" />
        Quick Actions
      </Typography>
    );
  }
  if (study) {
    handleNavigationPage(userData.role);
    if (isLastCrumb('study')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="13">
          <Checklist sx={{ mr: 0.5 }} fontSize="inherit" />
          Study
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openStudyView}
          key="13">
          <Checklist sx={{ mr: 0.5 }} fontSize="inherit" />
          Study
        </Link>
      );
    }
  }

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">{breadcrumbs}</Breadcrumbs>
    </div>
  );
}
