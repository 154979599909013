import { useTheme } from '@mui/material/styles';
import { LineChart } from '@mui/x-charts';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectForecastsForTimeline } from '../../../store/slices/outcomeSlice';

export default function ForecastTimeline({ width, question_id, falseData }) {
  const theme = useTheme();

  const forecastData = useSelector((state) =>
    selectForecastsForTimeline(state, question_id)
  );

  const dimensions = (() => {
    switch (width) {
      case 'large':
        return { width: 875, height: 280 };
      case 'small':
        return { width: 475, height: 135 };
      case 'card':
        return { width: 800, height: 180 };
      default:
        return { width: 350, height: 140 };
    }
  })();

  // Group forecasts by date and take the latest forecast for each date
  const groupedForecasts = falseData
    ? falseData
    : forecastData.reduce((acc, forecast) => {
        const existingForecast = acc.find((f) => f.x === forecast.x);
        if (!existingForecast) {
          acc.push(forecast);
        } else {
          // If there's already a forecast for this date, keep the latest one
          const existingIndex = acc.findIndex((f) => f.x === forecast.x);
          acc[existingIndex] = forecast;
        }
        return acc;
      }, []);

  // Sort by date
  const sortedForecasts = groupedForecasts.sort((a, b) => {
    return new Date(a.x) - new Date(b.x);
  });

  // Add today's point
  const today = new Date().toLocaleDateString('en-GB', {
    month: 'short',
    day: 'numeric'
  });

  const completeData =
    sortedForecasts.length > 0
      ? [
          ...sortedForecasts,
          {
            x: today,
            y: sortedForecasts[sortedForecasts.length - 1]?.y
          }
        ]
      : [];

  const xAxisData = completeData.map((point) => point.x);
  const yData = completeData.map((point) => point.y);

  const sx = {
    [`& .${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translateX(-25px)'
    },
    [`& .${axisClasses.bottom} .${axisClasses.label}`]: {
      transform: 'translateY(15px)'
    },
    overflow: 'visible',
    paddingLeft: 2,
    paddingRight: 2
  };

  return (
    <div>
      <LineChart
        xAxis={[
          {
            scaleType: 'point',
            data: xAxisData,
            tickInterval: (index, i) =>
              (i + 1) % Math.max(1, Math.floor(xAxisData.length / 5)) === 0
          }
        ]}
        series={[
          {
            data: yData,
            color: theme.palette.primary.main,
            valueFormatter: (value) => `${parseFloat(value.toFixed(1))}%`,
            curve: 'monotoneX',
            showMark: false,
            lineWidth: 2
          }
        ]}
        yAxis={[
          {
            label: 'Probability',
            min: 0,
            max: 100,
            tickMaxStep: 20
          }
        ]}
        margin={{
          top: 20,
          bottom: 20,
          left: 50,
          right: 10
        }}
        width={dimensions.width}
        height={dimensions.height}
        grid={{
          horizontal: true,
          vertical: false
        }}
        sx={sx}
      />
    </div>
  );
}
