import { MenuItem, Pagination, Select, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { AuthContext } from '../../App';
import OutcomeCard from '../../components/cards/OutcomeCard';
import CreateOutcomeSmall from '../../components/other/CreateOutcomeSmall';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import OutcomeFilterSection from '../../components/other/OutcomeFilterSection';
import {
  fetchOutcomes,
  selectOrderedFilteredOutcomes,
  setOutcomeFilters
} from '../../store/slices/outcomeSlice';
import { selectUserPreferences } from '../../store/slices/userSlice';

export default function OutcomesView() {
  const dispatch = useDispatch();
  const [filter] = useState(
    new URLSearchParams(useLocation().search).get('filter')
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [maxPages, setMaxPages] = useState(1);
  
  if (filter) {
    let payload = {
      generation: filter === 'generation' || filter === 'study',
      evaluation: filter === 'evaluation' || filter === 'study',
      forecasting: filter === 'forecasting' || filter === 'study',
      closed: filter === 'closed' || filter === 'study',
      study: filter === 'study'
    };
    window.sessionStorage.setItem('status-selections', JSON.stringify(payload));
  }
  
  const { isLoggedIn, userData } = useContext(AuthContext);
  const orderedOutcomes = useSelector((state) =>
    selectOrderedFilteredOutcomes(state, userData.id)
  );
  const outcomeStatus = useSelector((state) => state.outcomes.outcomes.status);
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  useEffect(() => {
    if (outcomeStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchOutcomes({ auth_token: token }));
    }
  }, [outcomeStatus, dispatch]);

  useEffect(() => {
    if (filter === 'own') {
      dispatch(
        setOutcomeFilters({
          user: {
            enabled: true,
            username: userData.username
          },
          generation: true,
          moderation: false,
          evaluation: true,
          forecasting: true,
          closed: true,
          study: false
        })
      );
    } else if (filter === 'study') {
      dispatch(
        setOutcomeFilters({
          generation: true,
          moderation: false,
          evaluation: true,
          forecasting: true,
          closed: true,
          study: true
        })
      );
    } else {
      if (window.sessionStorage.getItem('status-selections')) {
        dispatch(
          setOutcomeFilters(
            JSON.parse(window.sessionStorage.getItem('status-selections'))
          )
        );
      } 
    }
  }, [filter, dispatch, userData.username]);

  const [content, setContent] = useState();

  useEffect(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    if (outcomeStatus === 'loading') {
      setContent(
        <div className="text-center">
          <ClipLoader color="#f87171" loading={true} size={100} />
        </div>
      );
    } else if (outcomeStatus === 'succeeded') {
      const calculatedMaxPages = Math.ceil(orderedOutcomes.length / pageSize);
      setMaxPages(calculatedMaxPages);

      const outcomeContent = orderedOutcomes
        .slice(startIndex, endIndex)
        .map((outcome) => <OutcomeCard key={outcome.id} outcome={outcome} />);
      setContent(outcomeContent);
    } else if (outcomeStatus === 'failed') {
      setContent(
        <Typography sx={{ mx: 4 }}>No outcomes could be found.</Typography>
      );
    }
  }, [outcomeStatus, orderedOutcomes, page, pageSize, maxPages]);

  useEffect(() => {
    const storedPage = localStorage.getItem('outcomes_page');
    const storedPageSize = localStorage.getItem('outcomes_page_size');

    if (storedPage !== null) {
      setPage(Number(storedPage));
    }

    if (storedPageSize !== null) {
      setPageSize(Number(storedPageSize));
    }
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
    localStorage.setItem('outcomes_page', value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    localStorage.setItem('outcomes_page_size', event.target.value);
  };

  return (
    <div className="OutcomeView z-0">
      <div className="m-5">
        <div className="flex flex-col md:flex-row justify-center md:justify-start items-center">
          <div className="flex items-center ml-5">
            <HierarchicalBreadcrumbs outcomes={true} />
          </div>
        </div>
        <div className="flex justify-center">
          <div
            className={`xs:w-full ${
              userPreferences.outcome_layout === 'card' ||
              userPreferences.outcome_layout === undefined
                ? 'md:w-1/2'
                : 'md:w-full'
            }`}>
            {(userData?.role === 'Admin' ||
              userData?.role === 'Moderator' ||
              userData?.role === 'OutcomeOwner') && (
              <div>
                <CreateOutcomeSmall />
              </div>
            )}
            <OutcomeFilterSection />
            <section className="outcome-list">{content}</section>

            {maxPages > 0 && (
              <div className="flex justify-center items-center">
                <Pagination
                  count={maxPages}
                  page={page}
                  onChange={handlePageChange}
                />
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  displayEmpty
                  style={{ fontSize: '14px' }}>
                  {Array.from({ length: 10 }, (_, index) => index + 1).map(
                    (size) => (
                      <MenuItem key={size} value={size}>
                        {size}
                      </MenuItem>
                    )
                  )}
                </Select>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
