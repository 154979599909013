import React, { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { adminRegister, fetchUsers } from '../../store/slices/userSlice';

import {
  Button,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Backdrop
} from '@mui/material';
import { AuthContext, checkTokenStatus } from '../../App';

export default function RegisterUserModal({
  shown,
  close,
  setRegisterDisabled
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmedEmail, setConfirmedEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [role, setRole] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [registerRequestStatus, setRegisterRequestStatus] = useState('idle');
  const [emailMatchError, setEmailMatchError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const maxUsernameLength = 50;

  const roles = [
    'questioner',
    'outcomeowner',
    'moderator',
    'administrator',
    'observer'
  ];

  const changeUsername = (event) => setUsername(event.target.value);
  const changePassword = (event) => setPassword(event.target.value);
  const changeConfirmedPassword = (event) =>
    setConfirmedPassword(event.target.value);
  const changeEmail = (event) => setEmail(event.target.value);
  const changeConfirmedEmail = (event) => setConfirmedEmail(event.target.value);
  const changeRole = (event) => setRole(event.target.value);

  const checkPasswordsMatch = () => {
    return password === confirmedPassword;
  };
  const checkEmailsMatch = () => {
    return email === confirmedEmail;
  };

  const canRegister =
    [username, password, role].every((val) => val !== '') &&
    checkTokenStatus() &&
    checkEmailsMatch() &&
    checkPasswordsMatch() &&
    roles.includes(role) &&
    registerRequestStatus === 'idle';
  const handleRegister = async () => {
    setEmailMatchError(false);
    setPasswordMatchError(false);
    if (canRegister) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setRegisterRequestStatus('pending');
        setIsLoggedIn(true);
        const token = localStorage.getItem('auth_token');
        let payload = {
          username: username,
          password: password,
          role: role,
          auth_token: token
        };
        if (email !== '') {
          payload.email = email;
        }
        await dispatch(adminRegister(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setPassword('');
            }
            if (response.status === 'success') {
              setUsername('');
              setRole('');
              setResponseMessage(`User ${username} Successfully Registered`);
              dispatch(fetchUsers(token));
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to  register: ${err.message}`);
      } finally {
        if (isMounted) {
          setRegisterRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      localStorage.removeItem('auth_token');
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (username === '') {
      setErrorMessage('Please set the username before registering a new user');
    } else if (password === '') {
      setErrorMessage('Please set a password before registering a new user');
    } else if (!roles.includes(role)) {
      setErrorMessage('Please set a user role before registering a new user');
    } else if (!checkPasswordsMatch()) {
      setPasswordMatchError(true);
    } else if (!checkEmailsMatch()) {
      setEmailMatchError(true);
    } else {
      setErrorMessage('User could not be registered.');
    }
  };

  const closeModal = () => {
    close();
    setRegisterDisabled(false);
  };

  useEffect(() => {
    if (shown) {
      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          close();
          setRegisterDisabled(false);
        }
      };
      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [shown, close]);

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Backdrop
        sx={{
          zIndex: 999,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={true}
        onClick={() => close()}>   
        <Card
          className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 z-10"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div>
            <Typography
              sx={{ mb: 0.6, fontWeight: 'bold', fontSize: '1.1rem' }}
              className="text-center">
              Register New User
            </Typography>
            {!responseMessage && (
              <div>
                {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
                <div className="mx-2">
                  <Typography className="">Username</Typography>
                  <TextField
                    type="text"
                    variant="outlined"
                    autoComplete="username"
                    value={username}
                    onChange={(event) => changeUsername(event)}
                    placeholder="Enter username"
                    sx={{ my: 1.2 }}
                    className={`w-full`}
                    helperText={`${username.length}/${maxUsernameLength}`}
                    error={username.length > maxUsernameLength}
                  />
                </div>

                <div className="flex w-full">
                  <div className="w-1/2 mx-2">
                    <Typography className="">Email</Typography>
                    <TextField
                      type="text"
                      variant="outlined"
                      autoComplete="email"
                      value={email}
                      onChange={(event) => changeEmail(event)}
                      placeholder="Enter email"
                      sx={{ my: 1.2 }}
                      className="w-full"
                    />
                  </div>
                  <div className="w-1/2 mx-2">
                    <Typography className="">Confirm Email</Typography>
                    <TextField
                      type="text"
                      variant="outlined"
                      autoComplete="email"
                      value={confirmedEmail}
                      onChange={(event) => changeConfirmedEmail(event)}
                      placeholder="Confirm email"
                      sx={{ my: 1.2 }}
                      error={emailMatchError}
                      helperText={
                        emailMatchError ? 'Please check emails match' : ''
                      }
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="flex w-full">
                  <div className="w-1/2 mx-2">
                    <Typography className="">Password</Typography>
                    <TextField
                      name="blank"
                      type="password"
                      variant="outlined"
                      autoComplete="new-password"
                      value={password}
                      onChange={(event) => changePassword(event)}
                      placeholder="Enter password"
                      sx={{ my: 1.2 }}
                      className="w-full"
                    />
                  </div>
                  <div className="w-1/2 mx-2">
                    <Typography className="">Confirm Password</Typography>
                    <TextField
                      name="blank"
                      type="password"
                      variant="outlined"
                      autoComplete="new-password"
                      value={confirmedPassword}
                      onChange={(event) => changeConfirmedPassword(event)}
                      placeholder="Confirm Password"
                      sx={{ my: 1.2 }}
                      error={passwordMatchError}
                      helperText={
                        passwordMatchError ? 'Please check passwords match' : ''
                      }
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="mx-2">
                  <Typography className="">Role</Typography>
                  <div className="flex justify-center my-1 flex-wrap">
                    <RadioGroup
                      aria-labelledby="role-radio-buttons-group"
                      name="role-radio-buttons-group"
                      value={role}
                      onChange={changeRole}
                      row={true}
                      sx={{ display: 'flex', justifyContent: 'center' }}>
                      <FormControlLabel
                        value="questioner"
                        control={<Radio />}
                        label="Questioner"
                      />
                      <FormControlLabel
                        value="outcomeowner"
                        control={<Radio />}
                        label="Outcome Owner"
                      />
                      <FormControlLabel
                        value="moderator"
                        control={<Radio />}
                        label="Moderator"
                      />
                      <FormControlLabel
                        value="administrator"
                        control={<Radio />}
                        label="Administrator"
                      />
                      <FormControlLabel
                        value="observer"
                        control={<Radio />}
                        label="Observer"
                      />
                    </RadioGroup>
                  </div>
                </div>

                <div className="flex mt-4">
                  <div className="w-full m-2">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'gray',
                        ':hover': { backgroundColor: '#757575' }
                      }}
                      onClick={closeModal}
                      className="w-full">
                      Cancel
                    </Button>
                  </div>

                  <div className="w-full m-2">
                    <Button
                      variant="contained"
                      onClick={handleRegister}
                      className="w-full">
                      Register User
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {responseMessage && (
              <div>
                {responseMessage && (
                  <Typography color="success.main" className="text-center">
                    {responseMessage}
                  </Typography>
                )}

                <div className="flex mt-4 justify-center">
                  <Button
                    onClick={closeModal}
                    variant="contained"
                    className="w-6/12">
                    Close
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Backdrop>
    </div>
  ) : null;
}
