import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import { grey } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { areaElementClasses } from '@mui/x-charts/LineChart';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectQuestionById } from '../../../store/slices/outcomeSlice';

function AreaGradient({ color, id }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.3} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

export default function QuestionTimelineCard({
  question_id,
  rationale,
  index
}) {
  const theme = useTheme();

  const question = useSelector((state) =>
    selectQuestionById(state, question_id)
  );

  const trendColors = {
    up:
      theme.palette.mode === 'light'
        ? theme.palette.success.main
        : theme.palette.success.dark,
    down:
      theme.palette.mode === 'light'
        ? theme.palette.error.main
        : theme.palette.error.dark,
    neutral:
      theme.palette.mode === 'light'
        ? theme.palette.grey[400]
        : theme.palette.grey[700]
  };

  const labelColors = {
    up: 'success',
    down: 'error',
    neutral: 'default'
  };

  const trendValue =
    question?.crowd_forecasts[question?.crowd_forecasts.length - 1]
      ?.probability -
    question?.crowd_forecasts[question?.crowd_forecasts.length - 2]
      ?.probability;
  const trendRanges = { up: 0.05, down: -0.05 };
  const trend =
    trendValue > trendRanges.up
      ? 'up'
      : trendValue < trendRanges.down
      ? 'down'
      : 'neutral';
  const color = labelColors[trend];
  const chartColor = trendColors[trend];

  const forecasts = [
    ...question.crowd_forecasts,
    {
      created_at: new Date().toISOString(),
      probability: question.current_crowd_forecast
    }
  ];

  return (
    <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
      <CardContent>
        <Tooltip
          title={question.question_text}
          arrow
          slotProps={{
            tooltip: {
              sx: {
                color: 'text.primary',
                border: `1px solid ${(theme.vars || theme).palette.divider}`,
                borderRadius: 1,
                background: 'hsl(0, 0%, 100%)',
                ...theme.applyStyles('dark', {
                  background: grey[900]
                })
              }
            }
          }}>
          <div
            className="flex items-center gap-1 text-xs cursor-pointer"
            onClick={() => window.open(`/questions/${question.id}`, '_blank')}>
            <Typography component="h2" variant="subtitle2" gutterBottom>
              {question.question_text.length > 89
                ? question.question_text.substring(0, 90) + '...'
                : question.question_text}
            </Typography>
          </div>
        </Tooltip>
        <Stack
          direction="column"
          sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}>
          <Stack sx={{ justifyContent: 'space-between' }}>
            <Stack
              direction="row"
              sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h4" component="p">
                {parseFloat(
                  (question?.current_crowd_forecast * 100).toFixed(1)
                )}
                %
              </Typography>
              {!isNaN(trendValue) && (
                <Chip
                  size="small"
                  color={color}
                  label={
                    (trendValue > 0 ? '+' : '') +
                    parseFloat((trendValue * 100).toFixed(1)) +
                    '%'
                  }
                />
              )}
            </Stack>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Impact: {question?.current_impact.toFixed(3)}
            </Typography>
            <Typography variant="caption">{rationale}</Typography>
          </Stack>
          {question.crowd_forecasts && question.crowd_forecasts.length > 1 && (
            <Box sx={{ width: '100%', height: 50 }}>
              <SparkLineChart
                valueFormatter={(value) => `${parseFloat(value.toFixed(1))}%`}
                colors={[chartColor]}
                data={forecasts.map((forecast) => forecast.probability * 100)}
                area
                showHighlight
                showTooltip
                xAxis={{
                  data: forecasts.map((forecast) =>
                    new Date(forecast.created_at).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric'
                    })
                  )
                }}
                sx={{
                  [`& .${areaElementClasses.root}`]: {
                    fill: `url(#area-gradient-${question.id})`
                  }
                }}>
                <AreaGradient
                  color={chartColor}
                  id={`area-gradient-${question.id}`}
                />
              </SparkLineChart>
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
