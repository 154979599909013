import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { axisClasses } from '@mui/x-charts';
import { LineChart } from '@mui/x-charts/LineChart';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  selectLatestOutcomeBelief,
  selectOutcomeBeliefChange,
  selectOutcomeById,
  selectOutcomeEndDate,
  selectProcessedCrowdForecasts,
  toggleDashboardOtherQuestions
} from '../../../store/slices/outcomeSlice';
import { graphColors } from '../GraphColors';

const DynamicAreaGradients = ({ series, index }) => {
  return (
    <defs key={`defs-${series.id}`}>
      <linearGradient
        id={`gradient-${series.id}`}
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        gradientUnits="objectBoundingBox">
        <stop
          offset="0%"
          stopColor={graphColors[index].mid}
          stopOpacity="0.5"
        />
        <stop
          offset="100%"
          stopColor={graphColors[index].mid}
          stopOpacity="0.15"
        />
      </linearGradient>
    </defs>
  );
};

export default function StackedProbabilitiesWidget({ outcomeId, focussed }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const questionForecasts = useSelector((state) =>
    selectProcessedCrowdForecasts(state, outcomeId)
  );
  const outcome = useSelector((state) => selectOutcomeById(state, outcomeId));
  const beliefChange = useSelector((state) =>
    selectOutcomeBeliefChange(state, outcomeId)
  );
  const outcomeEndDate = useSelector((state) =>
    selectOutcomeEndDate(state, outcomeId)
  );
  const dashboardOtherQuestions = useSelector(
    (state) => state.outcomes.outcomes.showDashboardOthers
  );
  const [useOutcomeEndDate, setUseOutcomeEndDate] = React.useState(true);

  const toggleOther = () => {
    dispatch(toggleDashboardOtherQuestions());
  };

  const navigateQuestionFromSeries = (params) => {
    let idText = params.seriesId;
    if (idText.includes('question-')) {
      idText = idText.replace('question-', '');
      window.open(`/questions/${idText}`, '_blank');
      // navigate(`/questions/${idText}`);
    }
  };

  // Generate x-axis labels from all series
  let xAxisData =
    questionForecasts.length > 0
      ? useOutcomeEndDate && outcomeEndDate
        ? questionForecasts[0].data
            .map((point) => point.x)
            .concat(
              new Date(outcomeEndDate).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric'
              })
            )
        : questionForecasts[0].data.map((point) => point.x)
      : [];

  const changeUseOutcomeEndDate = () => {
    setUseOutcomeEndDate(!useOutcomeEndDate);
  };

  return (
    <div className="w-full">
      <Typography component="h2" variant="subtitle2" gutterBottom>
        Question Forecasts
      </Typography>
      <Stack sx={{ justifyContent: 'space-between' }}>
        <Stack
          direction="row"
          sx={{
            alignContent: { xs: 'center', sm: 'flex-start' },
            alignItems: 'center',
            gap: 1
          }}>
          {outcome?.outcome_forecast && (
            <>
              <Typography variant="h6" component="p">
                Outcome Forecast
              </Typography>
              <Typography variant="h5" component="p">
                {parseFloat((outcome.outcome_forecast * 100).toFixed(1))}%
              </Typography>
              <Chip
                size="small"
                color={
                  beliefChange > 0
                    ? 'success'
                    : beliefChange < 0
                    ? 'error'
                    : 'default'
                }
                label={
                  beliefChange > 0
                    ? '+' + (beliefChange * 100).toFixed(1) + '%'
                    : '' + (beliefChange * 100).toFixed(1) + '%'
                }
              />
            </>
          )}
        </Stack>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          Forecasts Over Time
        </Typography>
      </Stack>
      {questionForecasts.length > 0 ? (
        <>
          <LineChart
            xAxis={[
              {
                scaleType: 'point',
                data: xAxisData,
                tickInterval: (index, i) =>
                  (i + 1) % Math.max(1, Math.floor(xAxisData.length / 5)) === 0
              }
            ]}
            series={questionForecasts.map((s, index) => ({
              data: s.data.map((point) => point.y),
              id: `${s.id}`,
              label: s.label,
              area: true,
              curve: 'linear',
              stack: 'total',
              stackOrder: 'descending',
              showMark: false,
              color: graphColors[index].darker,
              valueFormatter: (value) =>
                `${value ? value.toFixed(2) + '%' : 'No forecast'}`
            }))}
            onAreaClick={(event, params) => navigateQuestionFromSeries(params)}
            yAxis={[{ label: 'Probability (%)' }]}
            height={focussed ? 500 : 300}
            margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
            grid={{ horizontal: true }}
            slotProps={{
              legend: {
                hidden: true
              }
            }}
            sx={{
              ...questionForecasts.reduce((acc, s, index) => {
                acc[`& .MuiAreaElement-series-${s.id}`] = {
                  fill: `url('#gradient-${s.id}')`
                };
                return acc;
              }, {}),
              [`& .${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translateX(-10px)'
              }
            }}>
            {questionForecasts.map((s, index) => (
              <DynamicAreaGradients key={index} series={s} index={index} />
            ))}
          </LineChart>
          <div className="w-full m-1 flex justify-end gap-2">
            <Button variant="outlined" onClick={toggleOther}>
              {dashboardOtherQuestions ? 'Hide Others' : 'Show Others'}
            </Button>
            <Button variant="outlined" onClick={changeUseOutcomeEndDate}>
              {useOutcomeEndDate ? 'Focus Today' : 'Focus Outcome End'}
            </Button>
          </div>
        </>
      ) : (
        <Typography variant="body2" color="text.secondary">
          No forecast data available
        </Typography>
      )}
    </div>
  );
}
