import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { Button, Typography } from '@mui/material';
import { InputLabel } from '@mui/material/';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import { fetchRecommendedQuestions } from '../../store/slices/autoSlice';
import InfoTooltip from './InfoTooltip';

export default function RecommendQuestions({ questionText }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [getRecommendedQuestionsStatus, setGetRecommendedQuestionsStatus] =
    useState('idle');
  const [recommendedQuestions, setRecommendedQuestions] = useState([{}]);
  const [gotRecommendedQuestions, setGotRecommendedQuestions] = useState(false);
  const [similarQuestionsScoredHighly, setSimilarQuestionsScoredHighly] =
    useState(false);

  const canGetRecommendedQuestions =
    [questionText.length > 0].every(Boolean) &&
    questionText !== 'Will ' &&
    getRecommendedQuestionsStatus === 'idle';

  const getRecommendedQuestions = async () => {
    let isMounted = true;
    setErrorMessage('');
    setGotRecommendedQuestions(false);
    setRecommendedQuestions([{}]);
    if (canGetRecommendedQuestions) {
      try {
        setGetRecommendedQuestionsStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          question_text: questionText,
          auth_token: token
        };
        await dispatch(fetchRecommendedQuestions(payload))
          .unwrap()
          .then((response) => {
            if (response.status === 'success') {
              if (isMounted) {
                const questions = JSON.parse(response.data);
                if (Array.isArray(questions)) {
                  setRecommendedQuestions(questions);
                  setSimilarQuestionsScoredHighly(
                    questions[questions.length - 1].question_score >= 0.04
                  );
                  setGotRecommendedQuestions(true);
                  setErrorMessage('');
                } else {
                  setErrorMessage('Question data is not valid');
                }
              }
            } else if (response.data === 'Backend call failure') {
              setErrorMessage(
                `Service start-up complete, please retry the request.`
              );
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to get recommended questions: ${err.message}`);
      } finally {
        if (isMounted) setGetRecommendedQuestionsStatus('idle');
        isMounted = false;
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (questionText === 'Will ') {
      setErrorMessage('Please provide the question text.');
    }
  };

  const determineCategory = (questionScore) => {
    if (questionScore >= 0.06) {
      return { name: 'Very High', value: 5 };
    } else if (questionScore >= 0.04) {
      return { name: 'High', value: 4 };
    } else if (questionScore >= 0.03) {
      return { name: 'Medium', value: 3 };
    } else if (questionScore >= 0.02) {
      return { name: 'Low', value: 2 };
    } else {
      return { name: 'Very Low', value: 1 };
    }
  };

  const categoryTextColor = (category) => {
    switch (category) {
      case 'Very Low':
        return 'text-red-500';
      case 'Low':
        return 'text-orange-500';
      case 'Medium':
        return 'text-blue-500';
      case 'High':
        return 'text-teal-500';
      case 'Very High':
        return 'text-green-400';
      default:
        return 'bg-blue-500';
    }
  };

  return (
    <div className="w-full p-1">
      <div className="flex">
        <Button
          disabled={getRecommendedQuestionsStatus === 'pending'}
          onClick={(e) => {
            e.stopPropagation();
            getRecommendedQuestions();
          }}>
          {getRecommendedQuestionsStatus === 'pending' ? (
            <HourglassBottomIcon></HourglassBottomIcon>
          ) : gotRecommendedQuestions ? (
            'Refresh Recommended Questions'
          ) : (
            'Recommend Similar Questions'
          )}
        </Button>
        <div className="ml-1">
          <InfoTooltip text="This will recommend similar high scoring questions based on the question you have written" />
        </div>
      </div>
      <Typography color="error">{errorMessage}</Typography>
      {gotRecommendedQuestions && (
        <div>
          <InputLabel htmlFor="resolution-criteria">
            {similarQuestionsScoredHighly
              ? 'These similar questions scored highly:'
              : 'These are some similar questions:'}
          </InputLabel>
          {recommendedQuestions === 'No similar questions' ? (
            <Typography>No similar questions found</Typography>
          ) : (
            <div className="flex flex-col">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Title</b>
                      </TableCell>
                      <TableCell>
                        <b>Category</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recommendedQuestions.map((question, index) => {
                      const category = determineCategory(
                        question.question_score
                      );

                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography
                              onClick={() => {
                                // Handle click as needed
                              }}
                              sx={{ mx: 0.6, fontSize: '0.95rem' }}
                              className="hover:underline hover:cursor-pointer"
                              color="primary.main">
                              {question.title}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{ fontWeight: 500 }}
                              className={`${categoryTextColor(category.name)}`}>
                              {category.name +
                                ' (' +
                                (question.question_score * 100).toFixed(2) +
                                '%)'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
