import { Card, Typography, useTheme } from '@mui/material';
import React from 'react';

import ForecastTimeline from '../../components/other/charts/ForecastTimeline';

export default function GraphOutcomeCard({
  falseTitle,
  falseStatus,
  falseData
}) {
  const theme = useTheme();
  const getOutcomeStatusColour = (falseStatus) => {
    switch (falseStatus) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };
  var outcomeStatusColour = getOutcomeStatusColour(falseStatus);

  const data = falseData?.map((forecast) => {
    return {
      x: new Date(forecast.created_at).toLocaleDateString('en-GB', {
        month: 'short',
        day: 'numeric'
      }),
      y: forecast.probability * 100
    };
  });

  return (
    <>
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '2%',
          margin: '1%',
          flex: '1 0 40%',
          scrollSnapAlign: 'start'
        }}>
        <Typography
          variant="h4"
          style={{ fontSize: 'calc(4px + 1.1vw)', fontWeight: 'bold' }}>
          {falseTitle}
        </Typography>
        <div
          style={{
            fontSize: 'calc(2px + 0.9vw)',
            marginTop: '3%',
            marginBottom: '3%',
            backgroundColor: outcomeStatusColour,
            borderRadius: '30px',
            width: 'fit-content',
            paddingLeft: '10px',
            paddingRight: '10px'
          }}>
          {falseStatus}
        </div>
        <div style={{ flexGrow: '1' }} />
        <div>
          <ForecastTimeline
            questionId={''}
            axisLabels={false}
            width={'card'}
            // title={'Forecast Timeline'}
            forecastingStartDate={'2023-10-10'}
            falseData={data}
          />
        </div>
      </Card>
    </>
  );
}
