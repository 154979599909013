import { Button, Card, Typography, useTheme, Backdrop } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  addEvaluation,
  fetchQuestionById,
  selectOutcomeById
} from '../../store/slices/outcomeSlice';
import SmallQuestionCard from '../cards/SmallQuestionCard';
import InfoTooltip from '../other/InfoTooltip';

export default function AddEvaluationModal({
  shown,
  close,
  questionId,
  outcomeId
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  const [selectedCategory, setSelectedCategory] = useState({
    value: 0,
    name: '',
    range: {}
  });
  const outcome = useSelector((state) => selectOutcomeById(state, outcomeId));
  const [evaluationRequestStatus, setEvaluationRequestStatus] =
    useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const userId = userData.id;
  const theme = useTheme();

  // const evaluationCategories = [
  //     { value: 1, name: 'Very Low', range: '0-2', primary: "red", secondary: "pink" },
  //     { value: 2, name: 'Low', range: '2-3', primary: "orange", secondary: "amber" },
  //     { value: 3, name: 'Medium', range: '3-4', primary: "blue", secondary: "cyan" },
  //     { value: 4, name: 'High', range: '4-6', primary: "teal", secondary: "emerald" },
  //     { value: 5, name: 'Very High', range: '6+', primary: "green", secondary: "lightGreen" }
  // ]

  const closeModal = () => {
    close();
  };

  const canAddEvaluation =
    [questionId, userId, checkTokenStatus()].every(Boolean) &&
    selectedCategory.id !== 0 &&
    evaluationRequestStatus === 'idle';

  const addEvaluationClick = async () => {
    if (canAddEvaluation) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setEvaluationRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          questionId: questionId,
          evaluationCategory: selectedCategory.value,
          auth_token: token
        };
        await dispatch(addEvaluation(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setSelectedCategory({ value: 0, name: '', range: '' });
              dispatch(
                fetchQuestionById({ questionId: questionId, auth_token: token })
              );
              if (isMounted) {
                setEvaluationRequestStatus('idle');
                isMounted = false;
              }
              close();
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to add evaluation: ${err.message}`);
        if (isMounted) {
          setEvaluationRequestStatus('idle');
          isMounted = false;
        }
      } 
    } else if (selectedCategory.value === 0) {
      setErrorMessage(
        'An Evaluation Category must be selected before confirming the Evaluation.'
      );
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Evaluation could not be made.');
    }
  };

  const getCategoryButtonBgColour = (evaluationCategory) => {
    switch (evaluationCategory.value) {
      case 1:
        return 'from-rose-500 to-red-500 group-hover:from-rose-500 group-hover:to-red-500';
      case 2:
        return 'from-amber-600 to-orange-500 group-hover:from-amber-600 group-hover:to-orange-500';
      case 3:
        return 'from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500';
      case 4:
        return 'from-emerald-500 to-teal-500 group-hover:from-emerald-500 group-hover:to-teal-500';
      case 5:
        return 'from-lightGreen-500 to-green-500 group-hover:from-lightGreen-500 group-hover:to-green-500';
      default:
        return 'from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500';
    }
  };

  const getCategoryTextBgColour = (evaluationCategory) => {
    switch (evaluationCategory.value) {
      case 1:
        return 'from-rose-500 to-red-500';
      case 2:
        return 'from-amber-600 to-orange-500';
      case 3:
        return 'from-cyan-500 to-blue-500';
      case 4:
        return 'from-emerald-500 to-teal-500';
      case 5:
        return 'from-lightGreen-500 to-green-500';
      default:
        return 'from-cyan-500 to-blue-500';
    }
  };

  const getCategoryButtonRingColour = (evaluationCategory) => {
    switch (evaluationCategory.value) {
      case 1:
        return 'ring-rose-300';
      case 2:
        return 'ring-amber-300';
      case 3:
        return 'ring-cyan-300';
      case 4:
        return 'ring-emerald-300';
      case 5:
        return 'ring-lightGreen-300';
      default:
        return 'ring-cyan-300';
    }
  };

  const isCategorySelected = (category) => {
    return selectedCategory.value === category.value;
  };

  let content;
  let evaluationCategoryContent = [];
  if (outcome.pre_multiplied_categories) {
    for (const evaluationCategory of outcome.pre_multiplied_categories) {
      content = (
        <div key={evaluationCategory.value} className="flex w-1/5 mx-1">
          <Button
            sx={{
              borderRadius: '0.5rem',
              p: '0.125rem',
              overflow: 'hidden',
              position: 'relative',
              '&:hover': {
                color: theme.palette.secondary.main
              }
            }}
            onClick={() => setSelectedCategory(evaluationCategory)}
            className={`relative flex w-full items-center justify-center group bg-gradient-to-br ${getCategoryButtonBgColour(
              evaluationCategory
            )} ${
              isCategorySelected(evaluationCategory)
                ? `ring-4 ${getCategoryButtonRingColour(evaluationCategory)}`
                : ''
            }`}>
            <div
              style={{
                transitionTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
                transitionDuration: '75ms',
                borderRadius: '0.375rem',
                transitionProperty: 'all',
                backgroundImage: isCategorySelected(evaluationCategory)
                  ? `linear-gradient(to bottom right, ${getCategoryTextBgColour(
                      evaluationCategory
                    )}`
                  : '',
                backgroundColor: isCategorySelected(evaluationCategory)
                  ? ''
                  : theme.palette.background.paper
              }}
              className={`relative px-2 py-2 w-full h-full grid grid-cols-3 rounded-md group-hover:bg-opacity-0`}>
              <div></div>
              <Typography
                color="text.primary"
                sx={{
                  fontWeight: 500,
                  '&:hover': {
                    color: 'primary.main'
                  }
                }}
                className="self-center justify-self-center font-sans uppercase">
                {evaluationCategory.name}
              </Typography>
              <div className="ml-1 self-start justify-self-end">
                <InfoTooltip
                  text={`The ${
                    evaluationCategory.name
                  } category represents an average variation prediction of: ${(
                    evaluationCategory.range.lower_bound * 100
                  ).toFixed(2)}% - ${(
                    evaluationCategory.range.upper_bound * 100
                  ).toFixed(2)}% in the questions probability.`}
                />
              </div>
              {/* <div style={{ backgroundColor: theme.palette.secondary.main }}>
                test
              </div> */}
            </div>
          </Button>
        </div>
      );
      evaluationCategoryContent.push(content);
    }
  } else {
    content = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  }

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        close();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [close]);

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Backdrop
        sx={{
          zIndex: 999,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={true}
        onClick={() => closeModal()}>
        <Card
          className="modal-content w-full md:w-5/7 lg:w-2/3 xl:w-1/2 2xl:w-2/5"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div>
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '1rem', my: 1 }}
              className="text-center">
              Evaluate this Question
            </Typography>
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
            <div className="m-1 my-2">
              <ul className="list-disc">
                <li>
                  <Typography sx={{ fontSize: '0.8rem' }}>
                    Please provide a prediction for the amount of information
                    which will arrive before the resolution date that will
                    influence the prediction of forecasters in a forecasting
                    tournament.
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ fontSize: '0.8rem' }}>
                    Specifically, you are predicting the average weekly variation
                    of the Question's forecasted probability.
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ fontSize: '0.8rem' }}>
                    Very Low is the lowest prediction of variance, whereas, Very
                    High is the highest prediction of variation.
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ fontSize: '0.8rem' }}>
                    Should you want to see the exact volatility ranges for each
                    category, hover over the information tooltips.
                  </Typography>
                </li>
              </ul>
            </div>

            <div className="break-words text-black font-medium mt-1">
              <Typography sx={{ fontWeight: 400 }} color="primary.main">
                Question
              </Typography>
              <div className="border-2 rounded">
                <SmallQuestionCard questionId={questionId} />
              </div>
            </div>
            <div className="flex mt-2 w-full">
              <div className="flex flex-col items-start w-full">
                <div>
                  <Typography sx={{ fontWeight: 400 }} color="primary.main">
                    Evaluation
                  </Typography>
                </div>
                <div className="flex justify-evenly my-3 px-7 w-full">
                  {evaluationCategoryContent}
                </div>
              </div>
            </div>
            <div className="px-2 my-3 text-center">
              <Typography color="error" sx={{ fontSize: '0.8rem' }}>
                You will NOT be able to change this evaluation once submitted.
              </Typography>
            </div>
            <div className="flex">
              <div className="w-full m-2">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'gray',
                    ':hover': { backgroundColor: '#757575' }
                  }}
                  onClick={closeModal}
                  className="w-full">
                  Cancel
                </Button>
              </div>
              <div className="w-full m-2">
                <Button
                  variant="contained"
                  onClick={addEvaluationClick}
                  className="w-full">
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </Backdrop>
    </div>
  ) : null;
}
