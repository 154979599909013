import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Backdrop,
  Box,
  Button,
  Card,
  InputAdornment,
  OutlinedInput,
  Slider,
  Tab,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  fetchQuestionById,
  fetchStrategyById,
  fetchUserForecastsByQuestion,
  makeRelevanceEvaluation,
  makeRelevanceEvaluationOnStrategy
} from '../../store/slices/outcomeSlice';
import SmallOutcomeCard from '../cards/SmallOutcomeCard';
import SmallQuestionCard from '../cards/SmallQuestionCard';
import SmallStrategyCard from '../cards/SmallStrategyCard';
import InfoTooltip from '../other/InfoTooltip';
import RelevanceGrid from '../other/RelevanceGrid';
import RelevanceLabel from '../other/RelevanceLabel';

export default function SetRelevanceEvaluationModal({
  shown,
  close,
  questionId,
  outcomeId,
  outcomeQuestionId,
  strategyId,
  outcomeForecast,
  questionForecast,
  existingEvaluation
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const [responseMessage, setResponseMessage] = useState('');
  const [questionForecastValue, setQuestionForecastValue] =
    useState(questionForecast);
  const [outcomeForecastValue, setOutcomeForecastValue] =
    useState(outcomeForecast);
  const [relevanceImpactRequestStatus, setRelevanceImpactRequestStatus] =
    useState('idle');
  const [isMounted, setIsMounted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputTab, setInputTab] = useState('1');
  const [allowForecastUpdates, setAllowForecastUpdates] = useState(false);

  const closeModal = () => {
    close();
  };

  const minimumJointOccurrence = () =>
    Math.max((outcomeForecastValue - (1 - questionForecastValue)) * 100, 0);

  const maximumJointOccurrence = () =>
    Math.min(outcomeForecastValue, questionForecastValue) * 100;

  const irrelevantJointOccurrence = () =>
    outcomeForecastValue * questionForecastValue * 100;

  const justQuestionProbability = () =>
    (questionForecastValue - jointOccurrence / 100) * 100;

  const justOutcomeProbability = () => {
    return (outcomeForecastValue - jointOccurrence / 100) * 100;
  };

  const neitherOutcomeQuestionProbability = () =>
    100 -
    (justQuestionProbability() + justOutcomeProbability() + jointOccurrence);

  const [jointOccurrence, setJointOccurrence] = useState(
    existingEvaluation
      ? parseFloat(existingEvaluation.toPrecision(4))
      : parseFloat(irrelevantJointOccurrence().toPrecision(3))
  );

  const [outcomeGivenQuestion, setOutcomeGivenQuestion] = useState(
    outcomeForecastValue * 100
  );

  const maximumOutcomeGivenQuestion = () =>
    parseFloat(((outcomeForecast / questionForecast) * 100).toFixed(3));

  const minimumOutcomeGivenQuestion = () =>
    parseFloat(
      (
        ((outcomeForecast + questionForecast - 1) / questionForecast) *
        100
      ).toFixed(3)
    );

  const getSliderMax = () => {
    if (outcomeForecast / questionForecast < 1 && !allowForecastUpdates) {
      return maximumOutcomeGivenQuestion();
    }
    return 100;
  };

  const getSliderMin = () => {
    if (
      (outcomeForecast + questionForecast - 1) / questionForecast > 0 &&
      !allowForecastUpdates
    ) {
      return minimumOutcomeGivenQuestion();
    }
    return 0;
  };

  const updateProbabilities = (probabilities) => {
    setJointOccurrence(probabilities.jointOccurrencePercent);
    setOutcomeForecastValue(
      (probabilities.justOutcomePercent +
        probabilities.jointOccurrencePercent) /
        100
    );
    setQuestionForecastValue(
      (probabilities.justQuestionPercent +
        probabilities.jointOccurrencePercent) /
        100
    );
  };

  const relevanceLevel = () => {
    return (
      jointOccurrence / 100 / questionForecastValue -
      (outcomeForecastValue - jointOccurrence / 100) /
        (1 - questionForecastValue)
    );
  };

  const relevanceTransformation = () => {
    return outcomeGivenQuestion - outcomeForecastValue * 100;
  };

  const canSetRelevanceImpact =
    [questionId || strategyId, checkTokenStatus()].every(Boolean) &&
    jointOccurrence !== '' &&
    relevanceImpactRequestStatus === 'idle';

  const setRelevanceEvaluationConfirm = async () => {
    if (canSetRelevanceImpact) {
      setIsMounted(true);
      setErrorMessage('');
      try {
        setRelevanceImpactRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: questionId ? questionId : strategyId,
          jointOccurrence: parseFloat(jointOccurrence) / 100,
          auth_token: token
        };
        if (questionForecastValue !== questionForecast) {
          payload.questionForecast = questionForecastValue.toFixed(3);
        }
        if (outcomeForecastValue !== outcomeForecast) {
          payload.outcomeForecast = outcomeForecastValue.toFixed(3);
        }
        if (questionId) {
          await dispatch(makeRelevanceEvaluation(payload))
            .unwrap()
            .then((response) => {
              if (isMounted) {
                setJointOccurrence('');
              }
              if (response.status === 'success') {
                setErrorMessage('');
                setResponseMessage(
                  `Your relevance evaluation has been submitted`
                );
                dispatch(
                  fetchQuestionById({
                    questionId: questionId,
                    auth_token: token
                  })
                );
                if (questionForecastValue !== questionForecast) {
                  dispatch(fetchUserForecastsByQuestion(questionId));
                }
                if (outcomeForecastValue !== outcomeForecast) {
                  dispatch(fetchUserForecastsByQuestion(outcomeQuestionId));
                }
              }
            });
        } else {
          await dispatch(makeRelevanceEvaluationOnStrategy(payload))
            .unwrap()
            .then((response) => {
              if (isMounted) {
                setJointOccurrence('');
              }
              if (response.status === 'success') {
                setErrorMessage('');
                setResponseMessage(
                  `Your relevance evaluation has been submitted`
                );
                dispatch(
                  fetchStrategyById({
                    strategyId: strategyId,
                    auth_token: token
                  })
                );
              }
            });
        }
      } catch (err) {
        setErrorMessage(
          `Failed to submit relevance evaluation: ${err.message}`
        );
        setIsMounted(false);
      } finally {
        if (isMounted) {
          setRelevanceImpactRequestStatus('idle');
          setIsMounted(false);
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (jointOccurrence === null) {
      setErrorMessage(
        'Please provide a Relevance Evaluation before confirming.'
      );
    } else {
      setErrorMessage("Failed to submit Question's Relevance Evaluation");
    }
  };

  const changeOutcomeGivenQuestion = (value) => {
    setOutcomeGivenQuestion(value);
    if (value > maximumOutcomeGivenQuestion()) {
      let newQuestionForecast = outcomeForecastValue / (value / 100);
      setQuestionForecastValue(newQuestionForecast);
      setJointOccurrence(parseFloat((value * newQuestionForecast).toFixed(1)));
    } else if (value < minimumOutcomeGivenQuestion()) {
      let newQuestionForecast = (outcomeForecastValue - 1) / (value / 100 - 1);
      setQuestionForecastValue(newQuestionForecast);
      setJointOccurrence(parseFloat((value * newQuestionForecast).toFixed(1)));
    } else {
      setJointOccurrence(
        parseFloat((value * questionForecastValue).toFixed(1))
      );
    }
  };

  const handleSlider1Change = (event, newValue) => {
    changeOutcomeGivenQuestion(newValue);
  };

  const handleSlider2Change = (event, newValue) => {
    setJointOccurrence(newValue);
    setOutcomeGivenQuestion(parseFloat(newValue) / questionForecastValue);
  };

  const valuetext = (value) => {
    return `${value}%`;
  };

  useEffect(() => {
    if (shown) {
      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          close();
        }
      };
      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [shown, close]);

  const sliderMarks1 = () => {
    let marks = [];
    let min = getSliderMin();
    let max = getSliderMax();
    let increment = (max - min) / 5;
    marks.push({
      value: min,
      label: `${parseFloat(min.toFixed(2))}%`
    });
    for (let i = 1; i < 5; i++) {
      let value = min + increment * i;
      marks.push({ value: value, label: `${value.toFixed(2)}%` });
    }
    marks.push({
      value: max,
      label: `${parseFloat(max.toFixed(2))}%`
    });
    return marks;
  };

  const sliderMarks2 = () => {
    let marks = [];
    let increment = (maximumJointOccurrence() - minimumJointOccurrence()) / 6;
    marks.push({
      value: minimumJointOccurrence(),
      label: `Exclusive: ${minimumJointOccurrence().toFixed(2)}%`
    });
    marks.push({
      value: irrelevantJointOccurrence(),
      label: `Independent: ${irrelevantJointOccurrence().toFixed(2)}%`
    });
    marks.push({
      value: maximumJointOccurrence(),
      label: `Dependant: ${maximumJointOccurrence().toFixed(2)}%`
    });
    marks.push({
      value: minimumJointOccurrence(),
      label: `${minimumJointOccurrence().toFixed(2)}%`
    });
    for (let i = 1; i < 6; i++) {
      let value = minimumJointOccurrence() + increment * i;
      marks.push({ value: value, label: `${value.toFixed(2)}%` });
    }
    marks.push({
      value: maximumJointOccurrence(),
      label: `${maximumJointOccurrence().toFixed(2)}%`
    });
    return marks;
  };

  const handleTabChange = (event, newValue) => {
    setInputTab(newValue);
  };

  return shown ? (
    <div className="modal-backdrop cursor-default">
      <Backdrop
        sx={{
          zIndex: 999,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={true}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          close();
        }}>
        <Card
          className="modal-content w-full md:w-6/7 lg:w-4/5 xl:w-4/5 2xl:w-3/5"
          sx={{ overflowY: 'auto !important', maxHeight: '90vh' }}
          onMouseDown={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
          raised>
          <div>
            <Typography
              sx={{ fontWeight: 700, fontSize: '1.1rem' }}
              className="text-center">
              Relevance Evaluation
            </Typography>
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
            <div className="flex justify-center">
              <div className="break-words text-xs font-medium m-1 my-2">
                <Typography sx={{ fontSize: '0.8rem' }}>
                  Please use the one of the methods below to provide a relevance
                  evaluation.
                </Typography>
              </div>
            </div>
            {!responseMessage && (
              <div>
                {outcomeId && (
                  <div className="break-words font-medium">
                    <Typography
                      sx={{ fontWeight: 400, py: 0.6 }}
                      color="primary.main">
                      Outcome:
                    </Typography>
                    <div className="border-2 rounded">
                      <SmallOutcomeCard outcomeId={outcomeId} />
                    </div>
                    <div className="my-1">
                      <Typography>
                        {`Your Outcome Forecast: ${(
                          outcomeForecastValue * 100
                        ).toFixed(1)}%`}
                      </Typography>
                    </div>
                  </div>
                )}

                {questionId && (
                  <div className="break-words font-medium">
                    <Typography
                      sx={{ fontWeight: 400, py: 0.6 }}
                      color="primary.main">
                      Question:
                    </Typography>
                    <div className="border-2 rounded">
                      <SmallQuestionCard questionId={questionId} />
                    </div>
                    <div className="my-1">
                      <Typography>
                        {`Your Question Forecast: ${(
                          questionForecastValue * 100
                        ).toFixed(1)}%`}
                      </Typography>
                    </div>
                  </div>
                )}
                {strategyId && (
                  <div className="break-words font-medium">
                    <Typography
                      sx={{ fontWeight: 400, py: 0.6 }}
                      color="primary.main">
                      Strategy:
                    </Typography>
                    <div className="border-2 rounded">
                      <SmallStrategyCard strategyId={strategyId} />
                    </div>
                    <div className="my-1">
                      <Typography>
                        {`Current Strategy Execution Likelihood: ${(
                          questionForecastValue * 100
                        ).toFixed(1)}%`}
                      </Typography>
                    </div>
                  </div>
                )}
                <Box
                  sx={{
                    width: '100%',
                    borderBottom: 1,
                    borderColor: 'divider'
                  }}>
                  <TabContext value={inputTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleTabChange}>
                        <Tab label="Conditional Probability" value="1" />
                        <Tab label="Joint Probability" value="2" />

                        {!strategyId && <Tab label="Grid" value="3" />}
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div className="w-full mx-1">
                        <div className="flex">
                          <Typography id="input-slider" gutterBottom>
                            Outcome Likelihood if{' '}
                            {strategyId ? 'Strategy' : 'Question'} Resolves True
                            (%)
                          </Typography>
                          <div className="ml-1">
                            <InfoTooltip
                              text={`What the likelihood of the Outcome would update to if the ${
                                strategyId
                                  ? 'Strategy is implemented'
                                  : 'Question resolves True'
                              }. ${
                                !strategyId &&
                                outcomeForecast / questionForecast < 1
                                  ? 'If it exceeds ' +
                                    maximumOutcomeGivenQuestion() +
                                    "% you will need to update your Question forecast to ensure you don't violate the probability space."
                                  : ''
                              }`}
                            />
                          </div>
                        </div>
                        <Box
                          sx={{
                            minWidth: 400,
                            width: '100%',
                            my: 3,
                            display: 'flex'
                          }}>
                          <Box
                            sx={{
                              width: '100%',
                              px: 8
                            }}>
                            <Slider
                              value={outcomeGivenQuestion}
                              onChange={handleSlider1Change}
                              aria-labelledby="input-slider1"
                              getAriaValueText={valuetext}
                              step={0.1}
                              min={getSliderMin()}
                              max={getSliderMax()}
                              marks={sliderMarks1()}
                              track={'normal'}
                              sx={{
                                mx: 2
                              }}
                            />

                            {!strategyId &&
                              outcomeForecast / questionForecast < 1 && (
                                <div className="flex justify-end">
                                  <Tooltip
                                    title={`Providing a conditional probability outside the default range (${minimumOutcomeGivenQuestion().toFixed(
                                      2
                                    )}% - ${maximumOutcomeGivenQuestion().toFixed(
                                      2
                                    )}% suggests your forecasts are incorrect. Going outside this range will submit a new Question Forecast.`}
                                    arrow>
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      onClick={() =>
                                        setAllowForecastUpdates(
                                          !allowForecastUpdates
                                        )
                                      }
                                      sx={{ ml: 2 }}>
                                      {allowForecastUpdates
                                        ? 'Default Range'
                                        : 'Expand Range'}
                                    </Button>
                                  </Tooltip>
                                </div>
                              )}
                          </Box>
                          <OutlinedInput
                            type="number"
                            value={outcomeGivenQuestion}
                            endAdornment={
                              <InputAdornment position="end">%</InputAdornment>
                            }
                            onChange={(e) =>
                              changeOutcomeGivenQuestion(
                                parseFloat(e.target.value)
                              )
                            }
                            inputProps={{
                              min: 0,
                              max: 100,
                              step: 0.1,
                              pattern: '[0-9]+[.]?[0-9]?'
                            }}
                            variant="outlined"
                            sx={{ width: '15%', mx: 2, ml: 6 }}
                          />
                        </Box>

                        <div className="flex-col">
                          <div className="flex">
                            <Typography sx={{ fontSize: '1rem', mr: 0.6 }}>
                              {`Relevance Level: ${relevanceLevel().toFixed(
                                2
                              )}`}
                            </Typography>
                            <RelevanceLabel
                              relevanceLevel={relevanceLevel().toFixed(2)}
                            />
                            <div className="ml-1">
                              <InfoTooltip
                                text={`Using your conditional and baseline forecasts, we can infer a relevance level between the ${
                                  strategyId ? 'Strategy' : 'Question'
                                } and the
                      Outcome.`}
                              />
                            </div>
                          </div>
                          <div className="flex">
                            <Typography sx={{ fontSize: '1rem' }}>
                              {`Relevance Transformation: ${
                                relevanceTransformation() > 0 ? '+' : ''
                              }${relevanceTransformation().toFixed(1)}%`}
                            </Typography>
                            <div className="ml-1">
                              <InfoTooltip
                                text={`How much the Outcomes likelihood would change if the ${
                                  strategyId
                                    ? 'Strategy is implemented'
                                    : 'Question resolves True'
                                }.`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="2">
                      <div className="w-full mx-1">
                        <div className="flex">
                          <Typography id="input-slider" gutterBottom>
                            Joint Occurrence Prediction (%)
                          </Typography>
                          <div className="ml-1">
                            <InfoTooltip
                              text={`A Joint Occurrence prediction is the likelihood (in %) that both the Outcome and the ${
                                strategyId ? 'Strategy' : 'Question'
                              } resolve True. It must be less than the lowest of the forecasts you have placed on the Outcome and ${
                                strategyId ? 'Strategy' : 'Question'
                              }.`}
                            />
                          </div>
                        </div>
                        <Box
                          sx={{
                            minWidth: 400,
                            width: '100%',
                            my: 3,
                            display: 'flex'
                          }}>
                          <Slider
                            value={jointOccurrence}
                            onChange={handleSlider2Change}
                            aria-labelledby="input-slider"
                            getAriaValueText={valuetext}
                            step={0.1}
                            min={minimumJointOccurrence()}
                            max={maximumJointOccurrence()}
                            marks={sliderMarks2()}
                            track={'normal'}
                            sx={{
                              mx: 2,
                              '& .MuiSlider-markLabel[data-index="0"]': {
                                transform: 'translate(-50%, -200%)'
                              },
                              '& .MuiSlider-markLabel[data-index="1"]': {
                                transform: 'translate(-50%, -200%)'
                              },
                              '& .MuiSlider-markLabel[data-index="2"]': {
                                transform: 'translate(-50%, -200%)'
                              }
                            }}
                          />
                          <OutlinedInput
                            type="number"
                            value={jointOccurrence}
                            endAdornment={
                              <InputAdornment position="end">%</InputAdornment>
                            }
                            onChange={(e) => {
                              setJointOccurrence(parseFloat(e.target.value));
                              setOutcomeGivenQuestion(
                                (parseFloat(e.target.value) * 100) /
                                  questionForecastValue
                              );
                            }}
                            inputProps={{
                              min: minimumJointOccurrence(),
                              max: maximumJointOccurrence(),
                              step: 0.1,
                              pattern: '[0-9]+[.]?[0-9]?'
                            }}
                            variant="outlined"
                            sx={{ width: '15%', mx: 2, ml: 6 }}
                          />
                        </Box>
                        <div className="flex-col">
                          <div className="flex">
                            <Typography sx={{ fontSize: '1rem', mr: 0.6 }}>
                              {`Relevance Level: ${relevanceLevel().toFixed(
                                2
                              )}`}
                            </Typography>
                            <RelevanceLabel
                              relevanceLevel={relevanceLevel().toFixed(2)}
                            />
                            <div className="ml-1">
                              <InfoTooltip
                                text={`By providing a Joint Occurrence Prediction you are
                      implying a relevance level between the ${
                        strategyId ? 'Strategy' : 'Question'
                      } and the
                      Outcome.`}
                              />
                            </div>
                          </div>
                          <div className="flex">
                            <Typography sx={{ fontSize: '1rem' }}>
                              {`Relevance Transformation: ${
                                relevanceTransformation() > 0 ? '+' : ''
                              }${relevanceTransformation().toFixed(1)}%`}
                            </Typography>
                            <div className="ml-1">
                              <InfoTooltip
                                text={`How much the Outcomes likelihood would change if the Question resolves True.`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="3">
                      <div className="flex">
                        <Typography>Probability Mass Grid </Typography>
                        <div className="ml-1">
                          <InfoTooltip text="The Probability Mass Grid shows the possible scenarios that can occur between the Outcome and Question. It has 100 squares each representing 1% and is divided between the 4 possible scenarios: 1. The Outcome and the Question both resolve True, 2. Just the Question resolves True, 3. Just the Outcome resolves True, 4. Neither the Outcome or Question resolve True." />
                        </div>
                        <RelevanceGrid
                          jointOccuranceProbability={jointOccurrence}
                          justQuestionProbability={justQuestionProbability().toFixed(
                            1
                          )}
                          justOutcomeProbability={justOutcomeProbability().toFixed(
                            1
                          )}
                          neitherOutcomeQuestionProbability={neitherOutcomeQuestionProbability().toFixed(
                            1
                          )}
                          updateProbabilities={updateProbabilities}
                          relevanceLevel={relevanceLevel().toFixed(2)}
                        />
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>

                <div className="flex justify-end mt-2">
                  <div className="mx-2">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'gray',
                        ':hover': { backgroundColor: '#757575' }
                      }}
                      onClick={closeModal}>
                      Cancel
                    </Button>
                  </div>
                  <div className="mx-2">
                    <Button
                      variant="contained"
                      disabled={isMounted}
                      onClick={setRelevanceEvaluationConfirm}>
                      {isMounted ? (
                        <ClipLoader color="#ffffff" loading={true} size={50} />
                      ) : (
                        'Confirm'
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {responseMessage && (
              <div className="my-2">
                {responseMessage && (
                  <Typography color="success.main" className="text-center">
                    {responseMessage}
                  </Typography>
                )}

                <div className="flex mt-4 justify-center">
                  <Button
                    onClick={closeModal}
                    variant="contained"
                    className="w-4/12">
                    Close
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Backdrop>
    </div>
  ) : null;
}
