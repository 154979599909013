import { Button, Card, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ConfirmationModal from "./ConfirmationModal";
import { parse } from "date-fns";

export default function BucketForm ({ bucket, onSubmit, errorMessage, setShowBucketForm, bucketConfigurations, selectedConfiguration, handleDelete}) {

    const [showDeleteBucketConfirm, setShowDeleteBucketConfirm] = useState(false);

    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                setShowBucketForm(false);
            }
        };

        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [setShowBucketForm]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({
            ...newBucket,
            upper_limit: parseFloat(newBucket.upper_limit/100).toFixed(4),
            lower_limit: parseFloat(newBucket.lower_limit/100).toFixed(4)
        });
    };

    const handleCancel = () => {
        setShowBucketForm(false);
    };

    const [newBucket, setNewBucket] = useState({
        id: bucket.id,
        name: bucket.name,
        upper_limit: (bucket.upper_limit*100).toFixed(2),
        lower_limit: (bucket.lower_limit*100).toFixed(2),
        configuration_id: selectedConfiguration ? selectedConfiguration.id : bucket.configuration_id || null
    });
    const isEdit = bucket?.name !== "" ? true : false;

    return (
    <div
    className="modal-backdrop"
    onClick={() => setShowBucketForm(false)}>
        {showDeleteBucketConfirm && (
                <ConfirmationModal
                    shown={showDeleteBucketConfirm}
                    close={() => setShowDeleteBucketConfirm(false)}
                    confirmationMessage="Are you sure you want to delete this bucket? This action cannot be undone."
                    confirm={() => {setShowBucketForm(false); handleDelete(newBucket);}}
                />
            )}
        <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => {
            e.stopPropagation();
        }}>
            <div className="flex flex-col">
                <Typography variant="h6">{isEdit ? "Edit Bucket" : "Add Bucket"}</Typography>
                <Typography color="error" gutterBottom>{errorMessage}</Typography>
                <TextField
                    label={isEdit ? "New Bucket Name" : "Bucket Name"}
                    value={newBucket.name}
                    onChange={(e) => setNewBucket({ ...newBucket, name: e.target.value })}
                    style={{ marginTop: "15px" }}
                />
                <TextField
                    type="number"
                    label={isEdit ? "New Lower Limit" : "Lower Limit"}
                    value={newBucket.lower_limit}
                    onChange={(e) => setNewBucket({ ...newBucket, lower_limit: parseFloat(e.target.value)})}
                    style={{ marginTop: "15px" }}
                    InputProps={{startAdornment: <InputAdornment position='start'>%</InputAdornment>}}
                />
                <TextField
                    type="number"
                    label={isEdit ? "New Upper Limit" : "Upper Limit"}
                    value={newBucket.upper_limit}
                    onChange={(e) => setNewBucket({ ...newBucket, upper_limit: parseFloat(e.target.value)})}
                    style={{ marginTop: "15px" }}
                    InputProps={{startAdornment: <InputAdornment position='start'>%</InputAdornment>}}
                />
                {
                    bucketConfigurations.length > 0 && (
                        <div>
                        <InputLabel>Configuration</InputLabel>
                        <Select
                            label="Configuration"
                            value={selectedConfiguration ? selectedConfiguration.id : newBucket.configuration_id || 1}
                            disabled={selectedConfiguration ? true : false}
                            onChange={(e) => setNewBucket({ ...newBucket, configuration_id: e.target.value })}
                        >
                            {bucketConfigurations.map((configuration) => (
                                <MenuItem key={configuration.id} value={configuration.id}>{configuration.name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    )
                }
                <div className="flex justify-between mt-4">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {isEdit ? "Update" : "Create"}
                        </Button>
                        {isEdit && (
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={() => setShowDeleteBucketConfirm(true)}
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </div>
            </div>
        </Card>
    </div>
  );
}