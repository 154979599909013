import { CalendarMonthOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
  Typography
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StudyModal from '../../components/modals/StudyModal';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import {
  fetchStudies,
  fetchStudyProgress,
  selectAllStudies
} from '../../store/slices/studySlice';

export default function AdminStudyView() {
  const dispatch = useDispatch();
  const [expandedStudy, setExpandedStudy] = useState(null);
  const [expandedUser, setExpandedUser] = useState(null);
  const [openStudyModal, setOpenStudyModal] = useState(false);
  const studies = useSelector(selectAllStudies);
  const studyProgress = useSelector((state) => state.studies.studyProgress);
  const studyStatus = useSelector((state) => state.studies.status);
  const token = localStorage.getItem('auth_token');

  useEffect(() => {
    if (studyStatus === 'idle') {
      dispatch(fetchStudies(token));
    }
  }, [studyStatus, dispatch, token]);

  useEffect(() => {
    studies.forEach((study) => {
      if (study.id) {
        dispatch(fetchStudyProgress({ auth_token: token, study_id: study.id }));
      }
    });
  }, [studies, dispatch, token]);

  const handleStudyAccordionChange = (studyId) => (event, isExpanded) => {
    setExpandedStudy(isExpanded ? studyId : null);
  };

  const handleUserAccordionChange = (userId) => (event, isExpanded) => {
    setExpandedUser(isExpanded ? userId : null);
  };

  const timeSince = (time) => {
    return moment.utc(time, 'YYYY-MM-DDThh:mm:ss').local().fromNow();
  };

  const renderProgress = (title, progress) => {
    if (!progress) return null;

    const { complete, completed_amount, threshold } = progress;
    const percentage =
      completed_amount >= threshold
        ? 100
        : (completed_amount / threshold) * 100;
    return (
      <Box mb={2}>
        <Typography variant="subtitle1" gutterBottom>
          {title}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={percentage}
              color={complete === 'True' ? 'success' : 'primary'}
            />
          </Box>
          <Typography variant="body2" color="textSecondary">
            {`${completed_amount}/${threshold}`}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <div className="Admin-Study-View">
      <StudyModal
        shown={openStudyModal}
        close={() => {
          setOpenStudyModal(false);
        }}
      />
      <div className="m-5">
        <div className="flex justify-between ml-5 mt-7 items-center">
          <HierarchicalBreadcrumbs study={true} />
        </div>
        <Box className="study-progress" p={3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 1
            }}>
            <Typography variant="h4" gutterBottom>
              Admin Study View
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setOpenStudyModal(true);
              }}
              size="medium">
              Manage Studies
            </Button>
          </div>
          {(!studies || studies.length === 0) && (
            <Typography>No studies found!</Typography>
          )}
          {studies?.map((study) => (
            <Accordion
              key={study.id}
              expanded={expandedStudy === study.id}
              onChange={handleStudyAccordionChange(study.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${study.id}-content`}
                id={`panel-${study.id}-header`}>
                <Box>
                  <Typography variant="h6">{study.name} ({`ID: ${study.id}`})</Typography>
                  <div>
                    <Typography variant="h7">Outcomes:</Typography>
                    <div>
                      {study?.outcomes?.map((outcome) => {
                        return (
                        <Typography key={"outcome"+outcome.id} variant="h7">- {outcome.title}</Typography>
                        )
                      })}
                    </div>
                  </div>
                  <Typography
                    sx={{
                      mr: 1.2,
                      fontSize: '0.875rem',
                      p: 0,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    color="text.secondary">
                    <CalendarMonthOutlined
                      fontSize="inherit"
                      sx={{ mr: 0.3, mb: 0.3 }}
                    />
                    Created {timeSince(study.created_at)}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {studyProgress[study.id]?.map((userProgress) => (
                    <Accordion
                      key={userProgress.user.id}
                      expanded={expandedUser === userProgress.user.id}
                      onChange={handleUserAccordionChange(
                        userProgress.user.id
                      )}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`user-panel-${userProgress.user.id}-content`}
                        id={`user-panel-${userProgress.user.id}-header`}>
                        <Box>
                          <Typography>{userProgress.user.username} (Prolific ID: {userProgress.user?.prolific_id})</Typography>
                          <Typography
                            color={
                              userProgress.completed_study === 'True'
                                ? 'success'
                                : 'error'
                            }>
                            {userProgress.completed_study === 'True'
                              ? 'Completed'
                              : 'Incomplete'}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          {renderProgress(
                            'Forecast Criteria Progress',
                            userProgress.forecast_criteria_progress
                          )}
                          {renderProgress(
                            'Commented Forecast Progress',
                            userProgress.commented_forecast_criteria_progress
                          )}
                          {renderProgress(
                            'Question Criteria Progress',
                            userProgress.question_criteria_progress
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </div>
    </div>
  );
}
