import React from "react";
import { useSelector } from "react-redux";

import { selectSettingById } from "../../store/slices/settingsSlice";

import SettingCheckbox from "../../components/other/SettingsCheckbox";
import SettingCheckboxes from "./SettingCheckboxes";
import SettingValue from "./SettingsValue";
import SettingText from "./SettingsText";
import SettingRadioButtons from "./SettingRadioButtons";

export default function Setting({ id }) {
    const setting = useSelector(state => selectSettingById(state, id));

    let content;
    if (setting.active !== undefined) {
        content = <SettingCheckbox id={id}/>
    } else if (setting.value !== undefined) {
        content = <SettingValue id={id}/>
    } else if (setting.text !== undefined) {
        content = <SettingText id={id}/>
    } else if (setting.options !== undefined && setting.name === 'AutoQuestions Provider') {
        content = <SettingRadioButtons id={id} />
    } else if (setting.options !== undefined) {
        content = <SettingCheckboxes id={id}/>
    } else {
        content = null
    }

    return (
        <div className="Setting">
            {content}
        </div>
    )
}
